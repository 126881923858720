<div
  [ngClass]="mapComponent.openedTabs[2] ? 'sidetab sidetabSelected' : 'sidetab '"
  (click)="openTab()"
>
  Calculation Modules
</div>
<div
  *ngIf="cmsOpen"
  id="layertab"
  [ngClass]="mapComponent.openedTabs[2] ? 'layertabWrapper tabopen' : 'layertabWrapper'"
>
  <div class="header">
    <h3>Calculation Modules</h3>
    <p (click)="closeTab()">x</p>
  </div>
  <htm-cms
    [expandedState]="expandedState"
    [expanded]="expanded"
    [layersSelected]="layersSelected"
    [scaleLevel]="scaleLevel"
    [selectionSurface]="selectionSurface"
  ></htm-cms>
</div>
