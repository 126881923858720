import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DataInteractionService } from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.service'
import { ChartComponent } from '@components/chart/chart.component'
import { InteractionService } from '@core/services/interaction.service'
import { Logger } from '@core/services/logger.service'
import { ToasterService } from '@core/services/toaster.service'
import { MapService } from '@pages/map/services/map.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import {
  calculation_module_category,
  default_drop_down_button,
  duration_curve_graph_category,
  duration_curve_graph_options,
  duration_curve_graph_title,
  energy_mix_graph_category,
  energy_mix_options,
  energy_mix_title,
  heat_load_graph_options,
  heatloadprofile,
  raster_type_name,
  summay_drop_down_buttons,
  tab1_datapanel,
  tab2_datapanel,
  vector_type_name,
} from '@services/data.service'
import { ElectricityMixComponent } from './electricity-mix/component/electricity-mix.component'
import { ExportDataComponent } from './export-data/export-data.component'
import { ResultManagerPayload, ScenarioResults } from './result-manager'
import { SummaryResultComponent } from './summary-result/summary-result.component'

@Component({
  standalone: true,
  selector: 'htm-result-manager',
  templateUrl: 'result-manager.component.html',
  styleUrls: ['result-manager.component.css'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Components
    ChartComponent,
    ExportDataComponent,
    SummaryResultComponent,
    ElectricityMixComponent,
  ],
})
export class ResultManagerComponent implements OnInit, OnChanges {
  @Input() cmPayload
  @Input() summaryPayload: any
  @Input() energyMixPayload
  @Input() heatLoadPayload
  @Input() durationCurvePayload
  @Input() personnalLayerPayload

  @Input() scenarioExplorerData: ScenarioResults

  @Input() scaleLevel
  graphicsExportButtonState = false
  indicatorExportButtonState = false

  indicatorLoading = false
  indicatorPersoLoading = false
  private _animationTimeout
  private _status_id
  private _progressCmAnimation = 0
  private _updateExportButton = false
  noIndicator = true
  tab1 = tab1_datapanel
  tab2 = tab2_datapanel
  cm_catedory = calculation_module_category
  tabSelected = this.tab1
  private _exportbuttonDisplay
  dropdown_btns = summay_drop_down_buttons
  selectedButton = this.dropdown_btns[0]
  categoryDisplayed = 0
  private _heatloadGraph
  private _isCMRunning
  result: ResultManagerPayload = {
    indicators: {
      summaryResult: null,
      personnalLayerResult: null,
      cmResult: null,
    },
    graphics: null,
    raster_layers: null,
    vector_layers: null,
  }

  private _heatLoadData
  constructor(
    private _interactionService: InteractionService,
    private _logger: Logger,
    private _dataInteractionService: DataInteractionService,
    private _mapService: MapService,
    private _toaserService: ToasterService,
  ) {}

  ngOnInit() {
    this._interactionService.getHeatLoadData().subscribe((heatload) => {
      if (!isNullOrUndefinedString(heatload)) {
        this._heatLoadData = heatload
        this._heatloadGraph.data = this._heatLoadData.dataset
        this._heatloadGraph.labels = this._heatLoadData.labels
      }
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    this.resetResult()
    if (!isNullOrUndefinedString(this.cmPayload) && !isNullOrUndefinedString(changes.cmPayload)) {
      this.updateCMResult()
    }
    if (!isNullOrUndefinedString(this.summaryPayload)) {
      this.updateSummaryResult()
    }
    if (!isNullOrUndefinedString(this.heatLoadPayload)) {
      this.updateHeatLoadResult()
    }
    if (
      !isNullOrUndefinedString(this.energyMixPayload) &&
      this.summaryPayload.layers.includes('yearly_co2_emission')
      /*&&
      (JSON.stringify(changes.energyMixPayload.currentValue) !== JSON.stringify(changes.energyMixPayload.previousValue))*/
    ) {
      this.updateEnergyMixResult()
    }
    if (!isNullOrUndefinedString(this.durationCurvePayload)) {
      this.updateDurationCurveResult()
    }
    if (!isNullOrUndefinedString(this.personnalLayerPayload)) {
      this.updatePersonnalLayersResult()
    }
    if (!isNullOrUndefinedString(this.scenarioExplorerData)) {
      this.updateScenarioResults()
    }
  }
  updateCMResult() {
    const self = this
    if (!isNullOrUndefinedString(this._status_id)) {
      self._interactionService.deleteCM(this._status_id)
    }
    self._interactionService
      .getCMInformations(this.cmPayload)
      .then((data) => {
        //this.interactionService.setCmRunning(true)
        self._logger.log('result-manager.component/updateCMResult data.status_id ', data.status_id)
        self._status_id = data.status_id
        self._interactionService.setCurrentIdCM(self._status_id)
        self.getStatusOfCM()
      })
      .catch((err) => {
        this.stopAnimation()
        self._logger.log('There is an error', err, 'error')
      })
  }
  updatePersonnalLayersResult() {
    if (isNullOrUndefinedString(this.personnalLayerPayload)) {
      return
    }
    const self = this
    self.indicatorPersoLoading = true
    self._interactionService
      .getSummaryPersonnalLayers(self.personnalLayerPayload)
      .then((result) => {
        self.setSummaryResult(result, 'personnalLayerResult')
        self.getIndicatorsCategories()
        self.indicatorPersoLoading = false
      })
      .catch((e) => {
        const result = {
          layers: [],
          no_data_layers: [],
        }

        for (const layer of self.personnalLayerPayload.layers) {
          result.no_data_layers.push(layer.layer_name)
        }

        // self.indicatorExportButtonState = false
        //self._logger.log(JSON.stringify(e));

        self.setSummaryResult(result, 'personnalLayerResult')
        self.getIndicatorsCategories()
        self.indicatorPersoLoading = false

        this._toaserService.showDangerToaster(
          `No data found for \n${result.no_data_layers.map((item) => item + '\n')}`,
        )
      })
  }
  killAnimation() {
    this._progressCmAnimation = 0
    this._interactionService.setCMAnimationStatus(this._progressCmAnimation)
    this._interactionService.setCmRunningProgess(this._progressCmAnimation)
  }
  updateSummaryResult() {
    const self = this
    self.indicatorLoading = true
    if (this.scaleLevel === '-1') {
      self._interactionService
        .getSummaryResultWithMultiAreas(self.summaryPayload)
        .then((result) => {
          // this.resetIndicators();
          self.setSummaryResult(result, 'summaryResult')
          self.getIndicatorsCategories()
          self.indicatorLoading = false
        })
        .catch((e) => {
          // this.resetIndicators();
          self.indicatorExportButtonState = false
          self.indicatorLoading = false
          self._logger.log('updateSummaryResult error', e, 'error')
        })
      return
    }
    self._interactionService
      .getSummaryResultWithIds(self.summaryPayload)
      .then((result) => {
        //this.resetIndicators();
        self.setSummaryResult(result, 'summaryResult')
        self.getIndicatorsCategories()
        self.indicatorLoading = false
      })
      .catch((e) => {
        this.resetIndicators()
        self.indicatorExportButtonState = false
        self.indicatorLoading = false

        self._logger.log('updateSummaryResult error', e, 'error')
      })
  }

  setSummaryResult(result, type) {
    this.result.indicators[type] = result
    this.indicatorExportButtonState = true
  }

  updateEnergyMixResult() {
    const self = this
    const graphic = self.addGraphic(
      energy_mix_title,
      'pie',
      [],
      [],
      energy_mix_options,
      energy_mix_graph_category,
      true,
    )

    self._interactionService
      .getElectricityMix(self.energyMixPayload)
      .then((result) => {
        graphic.isLoading = false
        graphic.data = result.datasets
        graphic.labels = result.labels
        self.graphicsExportButtonState = true
      })
      .catch((e) => {
        self.graphicsExportButtonState = false
        graphic.isLoading = false
        this._toaserService.showToaster('Please, choose at least one zone to display the chart!')
        self._logger.log('updateEnergyMixResult error', e, 'error')
      })
  }
  updateHeatLoadResult() {
    this._heatloadGraph = this.addGraphic(
      heatloadprofile,
      'line',
      [],
      [],
      heat_load_graph_options,
      'heatload',
      true,
    )
  }
  updateDurationCurveResult() {
    const self = this
    const graphic = self.addGraphic(
      duration_curve_graph_title,
      'line',
      [],
      [],
      duration_curve_graph_options,
      duration_curve_graph_category,
      true,
    )

    let isHectare
    if (this.scaleLevel === '-1') {
      isHectare = true
    } else {
      isHectare = false
    }
    self._interactionService
      .getDurationCurveWithPayload(this.durationCurvePayload, isHectare)
      .then((result) => {
        const dataset = this._interactionService.getDefaultDatasetDurationCurve()
        dataset.data = []
        const labels = []
        result.points.map((point) => {
          dataset.data.push(point.Y)
          labels.push('')
        })
        graphic.isLoading = false
        graphic.data = [dataset]
        graphic.labels = labels
        self.graphicsExportButtonState = true
      })
      .catch((e) => {
        self.graphicsExportButtonState = false
        self._logger.log('updateDurationCurveResult error', e, 'error')
      })
  }

  /**
   * Updates the scenario results
   */
  updateScenarioResults() {
    // Populate values in results
    let valuesLayers = []
    let totalPop = 0
    this.scenarioExplorerData.layers.forEach((e) => {
      totalPop = totalPop + e.properties.value
      valuesLayers.push({
        // e.properties.country also contains the region value
        // e.properties.region is not always defined (when selecting "country" as region scale)
        name: `${e.properties.variable} (${e.properties.country})`,
        value: e.properties.value,
        unit: e.properties.unit,
      })
    })
    if (this.scenarioExplorerData.layers.length > 1) {
      let sum = {
        name: `TOTAL ` + this.scenarioExplorerData.scenarioParameters.variable,
        value: totalPop,
        unit: this.scenarioExplorerData.layers[0].properties.unit,
      }
      valuesLayers.unshift(sum)
    }

    let results = {
      layers: [
        {
          name: `${this.scenarioExplorerData.scenarioParameters.model} - ${this.scenarioExplorerData.scenarioParameters.year}`,
          values: valuesLayers,
          category: [default_drop_down_button],
        },
      ],
      no_data_layers: this.scenarioExplorerData.no_data_layers,
      scenarioParameters: this.scenarioExplorerData.scenarioParameters,
    }

    // Set results
    this.setSummaryResult(results, 'scenarioResult')
  }

  processAndShowCmResult(data) {
    const response = data
    if (response['state'] === 'SUCCESS') {
      this.stopAnimation()
      this._logger.log('processAndShowCmResult', response['status'])
      let name_of_result = ''
      if (!isNullOrUndefinedString(this.cmPayload)) {
        name_of_result = this.cmPayload.cm_name
      }
      let prefix_name = ''
      if (this.cmPayload.cm_prefix != '') {
        prefix_name = this.cmPayload.cm_prefix + ' - '
      }
      if (!isNullOrUndefinedString(response.status.result.raster_layers)) {
        response.status.result.raster_layers.map((raster) => {
          let symb
          if (raster.type === 'custom') {
            symb = raster.symbology
          }

          this._dataInteractionService.addNewCMLayer(
            prefix_name + raster.name,
            raster.layer,
            raster.path,
            raster.type,
            raster_type_name,
            symb,
          )
          this._mapService.displayCustomLayerFromCM(raster.path, raster_type_name)
        })
      }

      if (!isNullOrUndefinedString(response.status.result.vector_layers)) {
        response.status.result.vector_layers.map((vector) => {
          let symb
          if (vector.type == 'custom') {
            symb = vector.symbology
          }
          this._dataInteractionService.addNewCMLayer(
            prefix_name + vector.name,
            vector.layer,
            vector.path,
            vector.type,
            vector_type_name,
            symb,
          )

          this._mapService.displayCustomLayerFromCM(vector.path, vector_type_name)
        })
      }
      if (
        !isNullOrUndefinedString(response.status.result.indicator) &&
        response.status.result.indicator.length >= 1
      ) {
        this.result.indicators.cmResult = {
          layers: [
            {
              name: name_of_result,
              values: response.status.result.indicator,
              category: [default_drop_down_button, calculation_module_category],
            },
          ],
        }
        this.indicatorExportButtonState = true
      }
      this.indicatorLoading = false
      if (
        !isNullOrUndefinedString(response.status.result.graphics) &&
        response.status.result.graphics.length >= 1
      ) {
        response.status.result.graphics.map((graphic) => {
          const option_calculation_module = {
            pointStyle: false,
            scales: {
              y: {
                title: { display: true, text: graphic.yLabel },
                min: 0,
              },

              x: {
                title: { display: true, text: graphic.xLabel },
                ticks: { autoSkip: false },
                min: 0,
              },
            },
          }
          if (graphic.title) {
            name_of_result = graphic.title + ' (' + name_of_result + ')'
          }
          const graph = this.addGraphic(
            name_of_result,
            graphic.type,
            graphic.data.datasets,
            graphic.data.labels,
            option_calculation_module,
            calculation_module_category,
            false,
          )
        })
        this.graphicsExportButtonState = true
      }
      this.getIndicatorsCategories()
    } else {
      this._logger.log('animationTimeout')
      this._animationTimeout = setTimeout(() => {
        this.getStatusOfCM()
        this.runAnimation()
      }, 1000)
    }
  }

  getStatusOfCM() {
    const self = this
    this.indicatorLoading = true

    this._logger.log(
      'result-manager.component/getStatusOfCM id',
      this._interactionService.getCurrentIdCM(),
    )
    if (this._interactionService.getCurrentIdCM() != null) {
      this._interactionService
        .getStatusAndCMResult(this._interactionService.getCurrentIdCM())
        .then((data) => {
          this.processAndShowCmResult(data)
        })
        .catch((err) => {
          this._animationTimeout = setTimeout(() => {
            this.stopAnimation()
          }, 1000)
          this.indicatorLoading = false

          this._interactionService.setCMAnimationStatus(null)
          self._logger.log('getStatusOfCM error', err, 'error')
        })
    } else {
      this._logger.log('getStatusOfCM', 'everything should have stop')
      this._animationTimeout = setTimeout(() => {
        this.stopAnimation()
      }, 1000)
      this.indicatorLoading = false
      this._interactionService.setCMAnimationStatus(null)
      // this.interactionService.setStatusCMPanel(false)
    }
  }

  stopAllAnimation() {
    this._interactionService.setCMAnimationStatus(0)
    if (!isNullOrUndefinedString(this._animationTimeout)) {
      clearTimeout(this._animationTimeout)
    }
    if (!isNullOrUndefinedString(this._status_id)) {
      this._interactionService.deleteCM(this._status_id)
    }
    this.killAnimation()
  }

  // useless ?
  runAnimation() {
    if (this._progressCmAnimation === 100) {
      this._progressCmAnimation = 10
    } else {
      this._progressCmAnimation += 10
    }
    this._interactionService.setCMAnimationStatus(this._progressCmAnimation)
    this._interactionService.setCmRunningProgess(this._progressCmAnimation)
  }

  stopAnimation() {
    if (!isNullOrUndefinedString(this._animationTimeout)) {
      clearTimeout(this._animationTimeout)
    }
    if (!isNullOrUndefinedString(this._status_id)) {
      this._interactionService.deleteCM(this._status_id)
    }
    this.killAnimation()
    this._interactionService.setCmRunning(false)
  }

  getIndicatorsCategories() {
    this.resetButtonsDiplay()
    for (let key of Object.keys(this.result.indicators)) {
      if (isNullOrUndefinedString(this.result.indicators[key])) {
        continue
      }
      const lay = this.result.indicators[key].layers
      if (lay == null || lay.length === 0) {
        this.noIndicator = true
      } else {
        lay.map((layer) => {
          if (!isNullOrUndefinedString(layer.name)) {
            let refToDisplay = []
            if (isNullOrUndefinedString(layer.category)) {
              refToDisplay = this._dataInteractionService.getRefFromLayerName(layer.name)
              layer.category = refToDisplay
            } else {
              refToDisplay = layer.category
            }
            this._logger.log('getIndicatorsCategories refToDisplay', refToDisplay)

            refToDisplay.map((ref) => {
              this.dropdown_btns.filter((x) => x.ref === ref)[0].display = true
            })
          }
        })
        this.noIndicator = false
      }
    }
    this.selectedButton = this.dropdown_btns[0]
    this.selectedButton.selected = true
    this.categoryDisplayed = this.dropdown_btns.filter((x) => x.display === true).length
  }

  addGraphic(name, type, data, labels, options, category, isLoading) {
    const graphic = {
      name: name,
      type: type,
      data: data,
      labels: labels,
      options: options,
      category: category,
      isLoading: isLoading,
    }
    this.result.graphics.push(graphic)
    return graphic
  }
  changeResultsDisplay(button) {
    this.selectedButton = button
  }
  resetButtonsDiplay() {
    this.dropdown_btns.map((btn) => {
      btn.display = false
    })
  }
  resetResult() {
    this.graphicsExportButtonState = false
    this.indicatorExportButtonState = false

    this.resetIndicators()
    this.result.graphics = []
    this.result.raster_layers = []
    this.result.vector_layers = []
  }
  resetIndicators() {
    // This function unsets the CM/personal layers results which are in the right-side-panel
    this.indicatorExportButtonState = false
    this.result.indicators = {
      personnalLayerResult: null,
      summaryResult: null,
      cmResult: null,
    }
  }
  tabSwitch(tabName) {
    this.tabSelected = tabName
  }
}
