<table class="uk-table uk-table-divider uk-table-small">
  <thead>
    <tr class="header-energy-mix">
      <th class="uk-table-shrink">Data on NUTS0</th>
    </tr>
  </thead>
</table>
<div class="uk-card uk-card-body htm-card">
  <div class="title">
    <h5 class="uk-card-title" style="float: left; margin-bottom: 0px">
      {{ subtitle }}
    </h5>
  </div>
  <div class="lineargraph" *ngIf="datasets.length != 0; else elseBlock">
    <htm-chart
      [datasets]="datasets"
      [labels]="labels"
      [options]="options"
      [type]="type"
      [isLoading]="isLoading"
    ></htm-chart>
  </div>
  <ng-template #elseBlock>
    <div class="graph-error-msg uk-text-center">
      <span uk-icon="icon: warning"></span> Please, choose at least one zone to display the chart!
    </div>
  </ng-template>
</div>
<!-- <div *ngIf="loadingData" class="waitingbox">
  <div class="spinner" uk-spinner></div>
</div> -->
