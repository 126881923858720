import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { APIService } from '@services/api.service'
import {
  apiUrl,
  getElectricityMixFromNuts0,
  postHeatLoadProfileHectares,
} from '@services/data.service'
import { LoaderService } from '@services/loader.service'
import { Logger } from '@services/logger.service'
import { ToasterService } from '@services/toaster.service'

@Injectable()
export class ElectricityMixService extends APIService {
  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService,
  ) {
    super(http, logger, loaderService, toasterService)
  }

  getElectricityMix(payload) {
    this.logger.log('getElectricityMix', apiUrl + ' ' + postHeatLoadProfileHectares)
    return super.POST(payload, apiUrl + getElectricityMixFromNuts0)
  }
}
