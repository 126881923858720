import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class SidePanelService {
  public leftPanelStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  // status for right panel
  public rightPanelStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public rightToggleExpandedStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  openLeftPanel() {
    this.leftPanelStatus.next(true)
  }
  closeLeftPanel() {
    this.leftPanelStatus.next(false)
  }
  openRightPanel() {
    this.rightPanelStatus.next(true)
  }
  closeRightPanel() {
    this.rightPanelStatus.next(false)
  }
}
