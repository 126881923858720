import { CommonModule } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { LayerDeleteConfirmPopupComponent } from '@components/_header-bar/file-management/upload/layer-detele-confirm-popup/layer-delete-confirm-popup.component'
import { DataInteractionClass } from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.class'
import { DataInteractionService } from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.service'
import { UploadService, UploadedFile } from '@core/services/upload.service'
import { FileManagementComponent } from '../file-management.component'

@Component({
  standalone: true,
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule, MatTooltipModule],
})
export class UploadComponent implements OnInit {
  file2Up: File
  isFileOk: boolean = false
  shared: boolean = false
  isUploading: boolean = false // Temporary until api do this async
  uploadedFiles: UploadedFile[] = []
  @ViewChild('inputFile2', { static: true }) inputFile2
  @ViewChild('inputFile', { static: true }) inputFile

  layers: DataInteractionClass[] = []
  //selectedLayer: string = null;
  selectedLayer = null

  constructor(
    private _dialog: MatDialog,
    private _uploadService: UploadService,
    private _layerService: DataInteractionService,
    private _fileManagementComponent: FileManagementComponent,
  ) {}

  ngOnInit() {
    if (this._layerService.getDataInteractionServices)
      // == isNUllorUndefined
      this._layerService.getDataInteractionServices().then((layers) => {
        this.layers = layers.map((layer) => layer) // seems to remove personnal and cm layer
      })
    // @todo: check if getFiles is usefull with getUploadedFiles just after
    this.getFiles()
    if (this._uploadService.getUploadedFiles)
      this._uploadService
        .getUploadedFiles()
        .subscribe(
          (files) =>
            (this.uploadedFiles = files.filter((file) =>
              this._uploadService.filterPersonalOrShareLayerWithTypeInProject(file),
            )),
        )
  }

  /**
   * Get the layer name from the layer workspacename
   * @param layer
   * @returns
   */
  getLayerName(layer: any): string {
    for (var i in this.layers)
      if (layer.layer_type == 'heat2')
        return 'Heat density total (future)' //TODO: Fix this hardcode workaround for heat2
      else if (this.layers[i].workspaceName == layer.layer) return this.layers[i].name
    return layer
  }

  // Useful ?
  getFiles() {
    if (this._uploadService.list) this._uploadService.list()

    if (this._uploadService.listShare) this._uploadService.listShare()
  }

  delete(id: number | UploadedFile) {
    this._uploadService.delete(id)
    this._fileManagementComponent.updateDiskSpace()
  }

  download(upFile: UploadedFile) {
    this._uploadService.download(upFile).then((url) => {
      if (url === '') return
      // window.open(url); //POPUP blocker
      const a = document.createElement('a')
      a.href = url
      a.download = upFile.name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    })
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.file2Up = event.target.files[0]
      this.isFileOk = true
    } else this.isFileOk = false
  }

  fileUpload() {
    if (!(this.isFileOk && this.selectedLayer)) return
    if (this.file2Up.size / 1000 >= 800) {
      //Ask user to confirm when uploading potential large files.
      if (
        !confirm(
          'Your file may cover a large area, and may take up to some minutes to generate. Would you like to continue anyway?',
        )
      )
        return
    }
    this.isUploading = true
    this._uploadService
      .add(this.file2Up, String(this.shared), this.selectedLayer)
      .then((success) => {
        if (success) {
          this._fileManagementComponent.updateDiskSpace()
          this.file2Up = null
          this.isFileOk = false
          this.selectedLayer = null
          this.inputFile2.nativeElement.value = ''
          this.inputFile.nativeElement.value = ''
        } else this.isFileOk = true
        this.isUploading = false
      })
  }

  openConfirmationDialog(id): void {
    const dialogRef = this._dialog.open(LayerDeleteConfirmPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: '400px',
    })

    dialogRef.afterClosed().subscribe((data) => {
      if (data) this.delete(id)
    })
  }
}
