import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { APIService } from '@core/services/api.service'
import { Helper } from '@core/services/helper'
import { LoaderService } from '@core/services/loader.service'
import { Logger } from '@core/services/logger.service'
import { ToasterService } from '@core/services/toaster.service'
import { business_name_population, unit_population } from '@services/data.service'
import * as Leaflet from 'leaflet'
declare let L

@Injectable()
export class PopulationService extends APIService {
  private _populationSelectedLayer: any
  logger: any
  loaderService: any
  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService,
    private _helper: Helper,
  ) {
    super(http, logger, loaderService, toasterService)
  }

  /**
   * Get the population with payloads
   */
  /*getPopulationWithPayloads(payload: Payload): Promise<Population> {
    return super.POST(payload, apiUrl + postPopulationDensityInArea);
  }*/

  /**
   * Show the population selected layer
   */
  showPopulationSelectedLayer(
    populationSelected: any,
    map: any,
    latlng: Leaflet.LatLng,
    popup: Leaflet.Popup,
  ) {
    this.logger.log('PopulationService/showPopulationSelectedLayer')
    const geometrie = populationSelected.features[0].geometry
    // remove the layer if there is one
    this.removePopulationSelectedlayer(map)
    // add the selected area to the map
    this._populationSelectedLayer = L.vectorGrid.slicer(geometrie)
    this._populationSelectedLayer.addTo(map)
    this.loaderService.display(false)
    // add the popup area to the map
    // this.addPopupHectare(populationSelected, map, latlng, popup);
  }

  /**
   * Add the Nuts popup
   */
  addPopupNuts(data: any, map: any, latlng: Leaflet.LatLng, popup: Leaflet.Popup) {
    const stat_levl_ = data.features[0].properties.stat_levl_
    const nuts_id = data.features[0].properties.nuts_id
    const name = data.features[0].properties.name
    const value = data.features[0].properties.value
    const date = data.features[0].properties.date.split('Z')[0]

    popup
      .setLatLng(latlng)
      .setContent(
        '<h5>' +
          business_name_population +
          '</h5> <ul class="uk-list uk-list-divider">' +
          '<li>nuts id: ' +
          nuts_id +
          '</li><li>nuts level: ' +
          stat_levl_ +
          '</li><li>name: ' +
          name +
          '</li>' +
          '<li>Population: ' +
          this._helper.round(value) +
          ' ' +
          unit_population +
          '</li><li>Reference date: ' +
          date +
          '</li></ul>',
      )
      .openOn(map)
    this.logger.log('PopulationService/addPopupNuts popup added')
  }

  /**
   * Remove the population selected layer
   */
  removePopulationSelectedlayer(map: any) {
    if (this._populationSelectedLayer) {
      this.logger.log('population.service/removePopulationSelectedlayer')
      map.removeLayer(this._populationSelectedLayer)
      delete this._populationSelectedLayer
    }
  }
}
