export const wwtp_data = {
  type: 'FeatureCollection',
  totalFeatures: 40,
  features: [
    {
      type: 'Feature',
      id: 'wwtp.26580',
      geometry: {
        type: 'Point',
        coordinates: [3565033.3517, 2943442.0108],
      },
      geometry_name: 'geom',
      properties: {
        gid: 26580,
        capacity: 8200,
        power: 528.4444444,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3565033.3517, 2943442.0108, 3565033.3517, 2943442.0108],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.27477',
      geometry: {
        type: 'Point',
        coordinates: [3581357.4351, 2942445.5501],
      },
      geometry_name: 'geom',
      properties: {
        gid: 27477,
        capacity: 20000,
        power: 1288.888889,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3581357.4351, 2942445.5501, 3581357.4351, 2942445.5501],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.27540',
      geometry: {
        type: 'Point',
        coordinates: [3648556.8435, 2927578.9586],
      },
      geometry_name: 'geom',
      properties: {
        gid: 27540,
        capacity: 4400,
        power: 283.5555556,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3648556.8435, 2927578.9586, 3648556.8435, 2927578.9586],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.27599',
      geometry: {
        type: 'Point',
        coordinates: [3582413.0166, 2953191.8309],
      },
      geometry_name: 'geom',
      properties: {
        gid: 27599,
        capacity: 4000,
        power: 257.7777778,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3582413.0166, 2953191.8309, 3582413.0166, 2953191.8309],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.27634',
      geometry: {
        type: 'Point',
        coordinates: [3646613.8906, 2941289.4053],
      },
      geometry_name: 'geom',
      properties: {
        gid: 27634,
        capacity: 5000,
        power: 322.2222222,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3646613.8906, 2941289.4053, 3646613.8906, 2941289.4053],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.27641',
      geometry: {
        type: 'Point',
        coordinates: [3577213.2335, 2944092.956],
      },
      geometry_name: 'geom',
      properties: {
        gid: 27641,
        capacity: 4600,
        power: 296.4444444,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3577213.2335, 2944092.956, 3577213.2335, 2944092.956],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.27684',
      geometry: {
        type: 'Point',
        coordinates: [3637222.3484, 2930279.5954],
      },
      geometry_name: 'geom',
      properties: {
        gid: 27684,
        capacity: 20000,
        power: 1288.888889,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3637222.3484, 2930279.5954, 3637222.3484, 2930279.5954],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.27753',
      geometry: {
        type: 'Point',
        coordinates: [3591618.6529, 2952432.7355],
      },
      geometry_name: 'geom',
      properties: {
        gid: 27753,
        capacity: 3000,
        power: 193.3333333,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3591618.6529, 2952432.7355, 3591618.6529, 2952432.7355],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.27779',
      geometry: {
        type: 'Point',
        coordinates: [3571286.4886, 2949639.2413],
      },
      geometry_name: 'geom',
      properties: {
        gid: 27779,
        capacity: 332000,
        power: 21395.55556,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3571286.4886, 2949639.2413, 3571286.4886, 2949639.2413],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.28028',
      geometry: {
        type: 'Point',
        coordinates: [3567073.8349, 2939586.5685],
      },
      geometry_name: 'geom',
      properties: {
        gid: 28028,
        capacity: 6500,
        power: 418.8888889,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3567073.8349, 2939586.5685, 3567073.8349, 2939586.5685],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.28248',
      geometry: {
        type: 'Point',
        coordinates: [3602552.4357, 2925885.3523],
      },
      geometry_name: 'geom',
      properties: {
        gid: 28248,
        capacity: 4000,
        power: 257.7777778,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3602552.4357, 2925885.3523, 3602552.4357, 2925885.3523],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.28370',
      geometry: {
        type: 'Point',
        coordinates: [3587853.4732, 2935699.7798],
      },
      geometry_name: 'geom',
      properties: {
        gid: 28370,
        capacity: 10000,
        power: 644.4444444,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3587853.4732, 2935699.7798, 3587853.4732, 2935699.7798],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.28386',
      geometry: {
        type: 'Point',
        coordinates: [3610438.4905, 2941921.9251],
      },
      geometry_name: 'geom',
      properties: {
        gid: 28386,
        capacity: 70000,
        power: 4511.111111,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3610438.4905, 2941921.9251, 3610438.4905, 2941921.9251],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.28677',
      geometry: {
        type: 'Point',
        coordinates: [3573993.7843, 2925026.9267],
      },
      geometry_name: 'geom',
      properties: {
        gid: 28677,
        capacity: 3000,
        power: 193.3333333,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3573993.7843, 2925026.9267, 3573993.7843, 2925026.9267],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.28902',
      geometry: {
        type: 'Point',
        coordinates: [3581521.2134, 2948223.5299],
      },
      geometry_name: 'geom',
      properties: {
        gid: 28902,
        capacity: 6000,
        power: 386.6666667,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3581521.2134, 2948223.5299, 3581521.2134, 2948223.5299],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.28922',
      geometry: {
        type: 'Point',
        coordinates: [3576103.4993, 2954885.5511],
      },
      geometry_name: 'geom',
      properties: {
        gid: 28922,
        capacity: 9500,
        power: 612.2222222,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3576103.4993, 2954885.5511, 3576103.4993, 2954885.5511],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.28941',
      geometry: {
        type: 'Point',
        coordinates: [3620848.9816, 2924751.7549],
      },
      geometry_name: 'geom',
      properties: {
        gid: 28941,
        capacity: 18000,
        power: 1160,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3620848.9816, 2924751.7549, 3620848.9816, 2924751.7549],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.29056',
      geometry: {
        type: 'Point',
        coordinates: [3589247.0947, 2929146.9037],
      },
      geometry_name: 'geom',
      properties: {
        gid: 29056,
        capacity: 18000,
        power: 1160,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3589247.0947, 2929146.9037, 3589247.0947, 2929146.9037],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.29260',
      geometry: {
        type: 'Point',
        coordinates: [3645070.7262, 2932014.9209],
      },
      geometry_name: 'geom',
      properties: {
        gid: 29260,
        capacity: 3000,
        power: 193.3333333,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3645070.7262, 2932014.9209, 3645070.7262, 2932014.9209],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.29489',
      geometry: {
        type: 'Point',
        coordinates: [3562358.211, 2947625.8346],
      },
      geometry_name: 'geom',
      properties: {
        gid: 29489,
        capacity: 20000,
        power: 1288.888889,
        unit: 'kW',
        date: '2015-01-01Z',
        bbox: [3562358.211, 2947625.8346, 3562358.211, 2947625.8346],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.50647',
      geometry: {
        type: 'Point',
        coordinates: [3565033.3517, 2943442.0108],
      },
      geometry_name: 'geom',
      properties: {
        gid: 50647,
        capacity: 8200,
        power: 528.4444444,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3565033.3517, 2943442.0108, 3565033.3517, 2943442.0108],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.51544',
      geometry: {
        type: 'Point',
        coordinates: [3581357.4351, 2942445.5501],
      },
      geometry_name: 'geom',
      properties: {
        gid: 51544,
        capacity: 20000,
        power: 1288.888889,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3581357.4351, 2942445.5501, 3581357.4351, 2942445.5501],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.51607',
      geometry: {
        type: 'Point',
        coordinates: [3648556.8435, 2927578.9586],
      },
      geometry_name: 'geom',
      properties: {
        gid: 51607,
        capacity: 4400,
        power: 283.5555556,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3648556.8435, 2927578.9586, 3648556.8435, 2927578.9586],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.51666',
      geometry: {
        type: 'Point',
        coordinates: [3582413.0166, 2953191.8309],
      },
      geometry_name: 'geom',
      properties: {
        gid: 51666,
        capacity: 4000,
        power: 257.7777778,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3582413.0166, 2953191.8309, 3582413.0166, 2953191.8309],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.51701',
      geometry: {
        type: 'Point',
        coordinates: [3646613.8906, 2941289.4053],
      },
      geometry_name: 'geom',
      properties: {
        gid: 51701,
        capacity: 5000,
        power: 322.2222222,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3646613.8906, 2941289.4053, 3646613.8906, 2941289.4053],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.51708',
      geometry: {
        type: 'Point',
        coordinates: [3577213.2335, 2944092.956],
      },
      geometry_name: 'geom',
      properties: {
        gid: 51708,
        capacity: 4600,
        power: 296.4444444,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3577213.2335, 2944092.956, 3577213.2335, 2944092.956],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.51751',
      geometry: {
        type: 'Point',
        coordinates: [3637222.3484, 2930279.5954],
      },
      geometry_name: 'geom',
      properties: {
        gid: 51751,
        capacity: 20000,
        power: 1288.888889,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3637222.3484, 2930279.5954, 3637222.3484, 2930279.5954],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.51820',
      geometry: {
        type: 'Point',
        coordinates: [3591618.6529, 2952432.7355],
      },
      geometry_name: 'geom',
      properties: {
        gid: 51820,
        capacity: 3000,
        power: 193.3333333,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3591618.6529, 2952432.7355, 3591618.6529, 2952432.7355],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.51846',
      geometry: {
        type: 'Point',
        coordinates: [3571286.4886, 2949639.2413],
      },
      geometry_name: 'geom',
      properties: {
        gid: 51846,
        capacity: 332000,
        power: 21395.55556,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3571286.4886, 2949639.2413, 3571286.4886, 2949639.2413],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.52095',
      geometry: {
        type: 'Point',
        coordinates: [3567073.8349, 2939586.5685],
      },
      geometry_name: 'geom',
      properties: {
        gid: 52095,
        capacity: 6500,
        power: 418.8888889,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3567073.8349, 2939586.5685, 3567073.8349, 2939586.5685],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.52315',
      geometry: {
        type: 'Point',
        coordinates: [3602552.4357, 2925885.3523],
      },
      geometry_name: 'geom',
      properties: {
        gid: 52315,
        capacity: 4000,
        power: 257.7777778,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3602552.4357, 2925885.3523, 3602552.4357, 2925885.3523],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.52437',
      geometry: {
        type: 'Point',
        coordinates: [3587853.4732, 2935699.7798],
      },
      geometry_name: 'geom',
      properties: {
        gid: 52437,
        capacity: 10000,
        power: 644.4444444,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3587853.4732, 2935699.7798, 3587853.4732, 2935699.7798],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.52453',
      geometry: {
        type: 'Point',
        coordinates: [3610438.4905, 2941921.9251],
      },
      geometry_name: 'geom',
      properties: {
        gid: 52453,
        capacity: 70000,
        power: 4511.111111,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3610438.4905, 2941921.9251, 3610438.4905, 2941921.9251],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.52744',
      geometry: {
        type: 'Point',
        coordinates: [3573993.7843, 2925026.9267],
      },
      geometry_name: 'geom',
      properties: {
        gid: 52744,
        capacity: 3000,
        power: 193.3333333,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3573993.7843, 2925026.9267, 3573993.7843, 2925026.9267],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.52969',
      geometry: {
        type: 'Point',
        coordinates: [3581521.2134, 2948223.5299],
      },
      geometry_name: 'geom',
      properties: {
        gid: 52969,
        capacity: 6000,
        power: 386.6666667,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3581521.2134, 2948223.5299, 3581521.2134, 2948223.5299],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.52989',
      geometry: {
        type: 'Point',
        coordinates: [3576103.4993, 2954885.5511],
      },
      geometry_name: 'geom',
      properties: {
        gid: 52989,
        capacity: 9500,
        power: 612.2222222,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3576103.4993, 2954885.5511, 3576103.4993, 2954885.5511],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.53008',
      geometry: {
        type: 'Point',
        coordinates: [3620848.9816, 2924751.7549],
      },
      geometry_name: 'geom',
      properties: {
        gid: 53008,
        capacity: 18000,
        power: 1160,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3620848.9816, 2924751.7549, 3620848.9816, 2924751.7549],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.53123',
      geometry: {
        type: 'Point',
        coordinates: [3589247.0947, 2929146.9037],
      },
      geometry_name: 'geom',
      properties: {
        gid: 53123,
        capacity: 18000,
        power: 1160,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3589247.0947, 2929146.9037, 3589247.0947, 2929146.9037],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.53327',
      geometry: {
        type: 'Point',
        coordinates: [3645070.7262, 2932014.9209],
      },
      geometry_name: 'geom',
      properties: {
        gid: 53327,
        capacity: 3000,
        power: 193.3333333,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3645070.7262, 2932014.9209, 3645070.7262, 2932014.9209],
      },
    },
    {
      type: 'Feature',
      id: 'wwtp.53556',
      geometry: {
        type: 'Point',
        coordinates: [3562358.211, 2947625.8346],
      },
      geometry_name: 'geom',
      properties: {
        gid: 53556,
        capacity: 20000,
        power: 1288.888889,
        unit: 'kW',
        date: '2012-01-01Z',
        bbox: [3562358.211, 2947625.8346, 3562358.211, 2947625.8346],
      },
    },
  ],
  crs: {
    type: 'name',
    properties: {
      name: 'urn:ogc:def:crs:EPSG::3035',
    },
  },
  bbox: [3562358.211, 2924751.7549, 3648556.8435, 2954885.5511],
}
