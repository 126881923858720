import {
  DataInteractionClass,
  HiddenDataInteractionClass,
} from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.class'
import { calculation_module_category, nuts2, nuts3 } from '@services/data.service'

const gfa_type = 'gross_floor_area'
const cp_type = 'construction_periods'
const population_type = 'pop_tot_curr_density'
const solar_potential_fields_type = 'solar_potential_fields'
const building_volume_type = 'building_volumes'
const gitlabUrl = 'https://gitlab.com/hotmaps'
const idDefaultLayer = 0 //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
const default_drop_down_button = 'overall'
const industry_layer_order = 6
const zoomLevelDetectChange = 10
const nuts0 = 'NUTS 0'

export const DataInteractionArray: DataInteractionClass[] = [
  // Buildings
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Heat density total',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'heat_tot_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'heat_tot_curr_density',
    layer_type: 'heat', // unity MWh/ha
    download_url:
      gitlabUrl + '/heat/heat_tot_curr_density/raw/master/data/heat_tot_curr_density.tif',
    description: gitlabUrl + '/heat/heat_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Heat density total (future)',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'heat_tot_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'heat_tot_curr_density',
    layer_type: 'heat2', // unity MWh/ha
    download_url:
      gitlabUrl + '/heat/heat_tot_curr_density/raw/master/data/heat_tot_curr_density.tif',
    description: gitlabUrl + '/heat/heat_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Heat density residential sector',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'heat_res_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'heat_tot_curr_density',
    layer_type: 'heat', // unity MWh/ha
    download_url:
      gitlabUrl + '/heat/heat_res_curr_density/raw/master/data/heat_res_curr_density.tif',
    description: gitlabUrl + '/heat/heat_res_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Heat density non-residential sector',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'heat_nonres_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'heat_tot_curr_density',
    layer_type: 'heat', // unity MWh/ha
    download_url:
      gitlabUrl + '/heat/heat_nonres_curr_density/raw/master/data/heat_nonres_curr_density.tif',
    description: gitlabUrl + '/heat/heat_nonres_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Cooling density total',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'cool_tot_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'cool_density',
    layer_type: 'cooling', // unity MWh/ha
    download_url:
      gitlabUrl + '/heat/cool_tot_curr_density/raw/master/data/cool_tot_curr_density.tif',
    description: gitlabUrl + '/heat/cool_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Gross floor area total',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'gfa_tot_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'gfa_tot_curr_density', // unity m2/ha
    layer_type: gfa_type,
    download_url: gitlabUrl + '/gfa_tot_curr_density/raw/master/data/gfa_tot_curr_density.tif',
    description: gitlabUrl + '/gfa_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Gross floor area residential',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'gfa_res_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'gfa_tot_curr_density', // unity m2/ha
    layer_type: gfa_type,
    download_url: gitlabUrl + '/gfa_res_curr_density/raw/master/data/gfa_res_curr_density.tif',
    description: gitlabUrl + '/gfa_res_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Gross floor area non-residential',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'gfa_nonres_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'gfa_tot_curr_density', // unity m2/ha
    layer_type: gfa_type,
    download_url:
      gitlabUrl + '/gfa_nonres_curr_density/raw/master/data/gfa_nonres_curr_density.tif',
    description: gitlabUrl + '/gfa_nonres_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },

  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Building volumes total',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'vol_tot_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'vol_tot_curr_density',
    layer_type: building_volume_type, // unity m3/ha
    download_url: gitlabUrl + '/vol_tot_curr_density/raw/master/data/vol_tot_curr_density.tif',
    description: gitlabUrl + '/vol_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Building volumes residential',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'vol_res_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'vol_tot_curr_density',
    layer_type: building_volume_type, // unity m3/ha
    download_url: gitlabUrl + '/vol_res_curr_density/raw/master/data/vol_res_curr_density.tif',
    description: gitlabUrl + '/vol_res_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Building volumes non-residential',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'vol_nonres_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: 'vol_tot_curr_density',
    layer_type: building_volume_type, // unity m3/ha
    download_url:
      gitlabUrl + '/vol_nonres_curr_density/raw/master/data/vol_nonres_curr_density.tif',
    description: gitlabUrl + '/vol_nonres_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },

  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Share of gross floor area - constructions before 1975',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'ghs_built_1975_100_share',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: cp_type,
    layer_type: cp_type, // unity %
    download_url:
      gitlabUrl +
      '/construction_periods/ghs_built_1975_100_share/raw/master/data/GHS_BUILT_1975_100_share.tif',
    description: gitlabUrl + '/construction_periods/ghs_built_1975_100_share/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },

  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Share of gross floor area - constructions between 1975 and 1990',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'ghs_built_1990_100_share',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: cp_type,
    layer_type: cp_type, // unity %
    download_url:
      gitlabUrl +
      '/construction_periods/ghs_built_1990_100_share/raw/master/data/GHS_BUILT_1990_100_share.tif',
    description: gitlabUrl + '/construction_periods/ghs_built_1990_100_share/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },

  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Share of gross floor area - constructions between 1990 and 2000',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'ghs_built_2000_100_share',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: cp_type,
    layer_type: cp_type, // unity %
    download_url:
      gitlabUrl +
      '/construction_periods/ghs_built_2000_100_share/raw/master/data/GHS_BUILT_2000_100_share.tif',
    description: gitlabUrl + '/construction_periods/ghs_built_2000_100_share/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },

  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Share of gross floor area - constructions between 2000 and 2014',
    category: 'Buildings',
    isSelected: false,
    workspaceName: 'ghs_built_2014_100_share',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'buildings'],
    styleName: cp_type,
    layer_type: cp_type, // unity %
    download_url:
      gitlabUrl +
      '/construction_periods/ghs_built_2014_100_share/raw/master/data/GHS_BUILT_2014_100_share.tif',
    description: gitlabUrl + '/construction_periods/ghs_built_2014_100_share/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },

  // Industry
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Industrial Sites Emissions',
    category: 'Industry',
    isSelected: false,
    workspaceName: 'industrial_database_emissions',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'industry'],
    styleName: 'industrial_emissions',
    layer_type: 'industrial_database_emissions', // unity tons/year
    order: industry_layer_order,
    download_url:
      gitlabUrl +
      '/industrial_sites/industrial_sites_Industrial_Database/raw/master/data/Industrial_Database.csv?ref_type=heads&inline=false',
    description:
      gitlabUrl + '/industrial_sites/industrial_sites_Industrial_Database/blob/master/README.md',
    isLoading: false,
    year: 2014,
    schema: 'public',
    dataType: 'csv',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Industrial Sites Excess Heat',
    category: 'Industry',
    isSelected: false,
    workspaceName: 'industrial_database_excess_heat',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'industry'],
    styleName: 'industrial_excess_heat',
    layer_type: 'industrial_database_excess_heat', // no unity
    order: industry_layer_order,
    download_url:
      gitlabUrl +
      '/industrial_sites/industrial_sites_Industrial_Database/raw/master/data/Industrial_Database.csv?ref_type=heads&inline=false',
    description:
      gitlabUrl + '/industrial_sites/industrial_sites_Industrial_Database/blob/master/README.md',
    isLoading: false,
    year: 2014,
    schema: 'public',
    dataType: 'csv',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Industrial Sites Company Name',
    category: 'Industry',
    isSelected: false,
    workspaceName: 'industrial_database_compagnyname',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'industry'],
    styleName: 'industrial_company_name',
    layer_type: 'industrial_database_companyname', // no unity
    order: industry_layer_order,
    download_url:
      gitlabUrl +
      '/industrial_sites/industrial_sites_Industrial_Database/raw/master/data/Industrial_Database.csv?ref_type=heads&inline=false',
    description:
      gitlabUrl + '/industrial_sites/industrial_sites_Industrial_Database/blob/master/README.md',
    isLoading: false,
    year: 2014,
    schema: 'public',
    dataType: 'csv',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Industrial Sites Subsector',
    category: 'Industry',
    isSelected: false,
    workspaceName: 'industrial_database_subsector',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'industry'],
    styleName: 'industrial_subsector',
    layer_type: 'industrial_database_subsector', // legend : cement, chemical industry,...
    order: industry_layer_order,
    download_url:
      gitlabUrl +
      '/industrial_sites/industrial_sites_Industrial_Database/raw/master/data/Industrial_Database.csv?ref_type=heads&inline=false',
    description:
      gitlabUrl + '/industrial_sites/industrial_sites_Industrial_Database/blob/master/README.md',
    isLoading: false,
    year: 2014,
    schema: 'public',
    dataType: 'csv',
    projects: ['citiwatts'],
  },

  // Population
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Population total',
    category: 'Population',
    isSelected: false,
    workspaceName: 'pop_tot_curr_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'population'],
    styleName: 'pop_tot_curr_density',
    layer_type: population_type, // unity: Person/ha
    download_url: gitlabUrl + '/pop_tot_curr_density/raw/master/data/pop_tot_curr_density.tif',
    description: gitlabUrl + '/pop_tot_curr_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts'],
  },

  // Mobility
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Motorization rate',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'motorization_rate_nuts2_view',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'agriculture',
    layer_type: 'motorization_rate_nuts2_view', // unity: car/1000 hab.
    download_url:
      gitlabUrl +
      '/motorization_rate_nuts2/raw/master/data/motorization_rate_nuts2.csv?ref_type=heads&inline=false',
    description: gitlabUrl + '/motorization_rate_nuts2/blob/master/README.md',
    isLoading: false,
    // added by m-e for test
    schema: 'geo',
    dataType: 'csv',
    scales: [nuts2],
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Electric Vehicle density',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'default_ev_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'pop_tot_curr_density',
    layer_type: 'default_ev_density', // unity: EV/ha
    download_url: gitlabUrl + '/default_ev_density/raw/master/data/default_ev_density.tif',
    description: gitlabUrl + '/default_ev_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Charging stations density',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'cs_density',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'pop_tot_curr_density',
    layer_type: 'cs_density', // unity: CS/ha
    download_url: gitlabUrl + '/cs_density/raw/master/data/cs_density.tif',
    description: gitlabUrl + '/cs_density/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Eurostat Cities Isodistances',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'rasterized_iso_urban',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'pop_tot_curr_density',
    layer_type: 'rasterized_iso_urban', // unity: km
    download_url: gitlabUrl + '/rasterized_iso_urban/raw/master/data/rasterized_iso_urban.tif',
    description: gitlabUrl + '/rasterized_iso_urban/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Additional Cities Isodistances',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'rasterized_iso_rural',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'pop_tot_curr_density',
    layer_type: 'rasterized_iso_rural', // unity: km
    download_url: gitlabUrl + '/rasterized_iso_rural/raw/master/data/rasterized_iso_rural.tif',
    description: gitlabUrl + '/rasterized_iso_rural/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Transborder commuter ratio',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'rasterized_border',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'pop_tot_curr_density',
    layer_type: 'rasterized_border', // unity: %
    download_url: gitlabUrl + '/rasterized_border/raw/master/data/rasterized_border.tif',
    description: gitlabUrl + '/rasterized_border/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Eurostat Cities ID',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'rasterized_urau',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'pop_tot_curr_density',
    layer_type: 'rasterized_urau', // unity: -
    download_url: gitlabUrl + '/rasterized_urau/raw/master/data/rasterized_urau.tif',
    description: gitlabUrl + '/rasterized_urau/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Additional  Cities ID',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'rasterized_urau_rural',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'pop_tot_curr_density',
    layer_type: 'rasterized_urau_rural', // unity: -
    download_url: gitlabUrl + '/rasterized_urau_rural/raw/master/data/rasterized_urau_rural.tif',
    description: gitlabUrl + '/rasterized_urau_rural/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Workplaces from OpenStreetMap',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'work_osm',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'pop_tot_curr_density',
    layer_type: 'work_osm', // unity: workplace/ha
    download_url: gitlabUrl + '/work_osm/raw/master/data/work_osm.tif',
    description: gitlabUrl + '/work_osm/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Working areas from Corine Land Cover',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'filtered_corine_land_cover',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'pop_tot_curr_density',
    layer_type: 'filtered_corine_land_cover', // unity: -
    download_url:
      gitlabUrl + '/filtered_corine_land_cover/raw/master/data/filtered_corine_land_cover.tif',
    description: gitlabUrl + '/filtered_corine_land_cover/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Points Of Interest',
    category: 'Mobility',
    isSelected: false,
    workspaceName: 'poi_osm',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'mobility'],
    styleName: 'pop_tot_curr_density',
    layer_type: 'poi_osm', // unity: POIs/ha
    download_url: gitlabUrl + '/poi_osm/raw/master/data/poi_osm.tif',
    description: gitlabUrl + '/poi_osm/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },

  // Potentials
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Waste Water Treatment Plants - Power',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'wwtp_power',
    zoomLevel: zoomLevelDetectChange,
    ref: [default_drop_down_button, 'potential'],
    styleName: 'default',
    layer_type: 'wwtp_power', // Unity: kW
    download_url:
      gitlabUrl + '/potential/WWTP/raw/master/data/2015/WWTP_2015.csv?ref_type=heads&inline=false',
    description: gitlabUrl + '/potential/WWTP/blob/master/README.md',
    isLoading: false,
    dataType: 'csv',
    schema: 'public',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Waste Water Treatment Plants - Capacity',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'wwtp_capacity',
    zoomLevel: zoomLevelDetectChange,
    ref: [default_drop_down_button, 'potential'],
    styleName: 'wwtp_capacity',
    layer_type: 'wwtp_capacity', // Unity: Person Equivalent
    download_url:
      gitlabUrl + '/potential/WWTP/raw/master/data/2015/WWTP_2015.csv?ref_type=heads&inline=false',
    description: gitlabUrl + '/potential/WWTP/blob/master/README.md',
    isLoading: false,
    dataType: 'csv',
    schema: 'public',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Agricultural residues',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'agricultural_residues_view',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'potential'],
    styleName: 'agriculture',
    layer_type: 'agricultural_residues_view', // Unity: PJ
    download_url:
      gitlabUrl +
      '/potential/potential_biomass/raw/master/data/agricultural_residues.csv?ref_type=heads&inline=false',
    description: gitlabUrl + '/potential/potential_biomass/blob/master/README.md',
    isLoading: false,
    year: 1970,
    schema: 'geo',
    dataType: 'csv',
    scales: [nuts3], // NUTS2 : result not available, LAU2 : No data in result
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Livestock effluents',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'livestock_effluents_view',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'potential'],
    styleName: 'livestock',
    layer_type: 'livestock_effluents_view', // Unity: PJ
    download_url:
      gitlabUrl +
      '/potential/potential_biomass/raw/master/data/livestock_effluents.csv?ref_type=heads&inline=false',
    description: gitlabUrl + '/potential/potential_biomass/blob/master/README.md',
    isLoading: false,
    year: 1970,
    schema: 'geo',
    dataType: 'csv',
    scales: [nuts3], // NUTS2 : result not available, LAU2 : No data in result
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Forest residues',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'potential_forest',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'potential'],
    styleName: 'forest',
    layer_type: 'potential_forest', // Unity: MWh/year
    download_url:
      gitlabUrl +
      '/potential/potential_biomass/raw/master/data/forest_residues.csv?ref_type=heads&inline=false',
    description: gitlabUrl + '/potential_biomass/blob/master/README.md',
    isLoading: false,
    // before: dataType: "csv",
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Municipal solid waste',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'solid_waste_view',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'potential'],
    styleName: 'municipal_waste',
    layer_type: 'solid_waste_view', // Unity: PJ
    download_url:
      gitlabUrl +
      '/potential/potential_municipal_solid_waste/raw/master/data/solid_waste.csv?ref_type=heads&inline=false',
    description: gitlabUrl + '/potential/potential_municipal_solid_waste/blob/master/README.md',
    isLoading: false,
    year: 2011,
    schema: 'geo',
    dataType: 'csv',
    scales: [nuts3],
    projects: ['citiwatts'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Wind potential at 50 meters',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'wind_50m',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'potential'],
    styleName: 'wind',
    layer_type: 'wind_50m', // Unity: [m/s]
    download_url: gitlabUrl + '/potential/potential_wind/raw/master/data/wind_50m.tif',
    description: gitlabUrl + '/potential/potential_wind/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts'],
  },

  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Solar radiation on building footprint',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'solar_optimal_total',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'potential'],
    styleName: 'solar_optimal_total',
    layer_type: 'solar_optimal_total', // Unity: kWh/m2
    download_url: gitlabUrl + '/potential/potential_solar/raw/master/data/solar_opt.tif',
    description: gitlabUrl + '/potential/potential_solar/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts'],
  },

  // shallow geothermal vector layer
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Geothermal potential Heat Conductivity ',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'shallow_geothermal_potential',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'potential'] /*['no_showed']*/,
    styleName: 'shallow_geothermal_potential',
    layer_type: 'shallow_geothermal_potential', // Unity: W/mK
    download_url:
      gitlabUrl +
      '/potential/potential_shallowgeothermal/-/archive/master/potential_shallowgeothermal-master.zip?path=data',
    description: gitlabUrl + '/potential/potential_shallowgeothermal/blob/master/README.md',
    isLoading: false,
    year: 1970,
    schema: 'geo',
    // before: dataType: "shp",
    dataType: 'csv',
    projects: ['citiwatts', 'CoolLIFE'],
  },

  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Potential solarthermal collectors - rooftop',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'potential_solarthermal_collectors_rooftop',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'potential'],
    styleName: 'solar_potential_fields',
    layer_type: solar_potential_fields_type, // Unity: MWh/ha
    download_url:
      gitlabUrl +
      '/potential/potential_solarthermal_collectors_rooftop/raw/master/data/potential_solarthermal_collectors_rooftop.tif',
    description:
      gitlabUrl + '/potential/potential_solarthermal_collectors_rooftop/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },

  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Potential solarthermal collectors - open field',
    category: 'R.E.S. Potential',
    isSelected: false,
    workspaceName: 'potential_solarthermal_collectors_open_field',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'potential'],
    styleName: 'solar_potential_fields',
    layer_type: solar_potential_fields_type, // Unity: MWh/ha
    download_url:
      gitlabUrl +
      '/potential/potential_solarthermal_collectors_open_field/raw/master/data/potential_solarthermal_collectors_open_field.tif',
    description:
      gitlabUrl + '/potential/potential_solarthermal_collectors_open_field/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    projects: ['citiwatts'],
  },

  // I would suggest to only show on of the geothermal layers - in DB integrated as vector and raster - which one do we need for the indicator?
  /*  {id: idDefaultLayer, name: 'Geothermal Potential Hc Class ', category: 'R.E.S. Potential', isSelected: false,
    workspaceName: 'potential_shallowgeothermal_hc_class', zoomLevel: 0, ref: ['overall', 'potential'], styleName: 'potential_shallowgeothermal_hc_class', layer_type: defaultLayerType,
description:   gitlabUrl + '/potential/potential_shallowgeothermal/blob/master/README.md'}, isLoading:false},*/

  // Climate

  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Average temperature',
    category: 'Climate',
    isSelected: false,
    workspaceName: 'land_surface_temperature',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'climate'],
    styleName: 'temp_v2',
    layer_type: 'land_surface_temperature', // Unity: °C
    download_url:
      gitlabUrl + '/climate/land_surface_temperature/raw/master/data/land_surface_temperature.tif',
    description: gitlabUrl + '/climate/land_surface_temperature/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Cooling degree days',
    category: 'Climate',
    isSelected: false,
    workspaceName: 'cdd_curr',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'climate'],
    styleName: 'cdd_curr_tif',
    layer_type: 'cdd_curr', // Unity: Degree days
    download_url: gitlabUrl + '/climate/CDD_ha_curr/raw/master/data/CDD_curr.tif',
    description: gitlabUrl + '/climate/CDD_ha_curr/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Heating degree days',
    category: 'Climate',
    isSelected: false,
    workspaceName: 'hdd_curr',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'climate'],
    styleName: 'hdd_curr_tif',
    layer_type: 'hdd_curr', // Unity: Degree days
    download_url: gitlabUrl + '/climate/HDD_ha_curr/raw/master/data/HDD_curr.tif',
    description: gitlabUrl + '/climate/HDD_ha_curr/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Solar radiation',
    category: 'Climate',
    isSelected: false,
    workspaceName: 'solar_radiation',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'climate'],
    styleName: 'solar_optimal_total',
    layer_type: 'climate_solar_radiation', // Unity: kWh/m2
    download_url:
      gitlabUrl + '/climate/climate_solar_radiation/raw/master/data/output_solar_radiation.tif',
    description: gitlabUrl + '/climate/climate_solar_radiation/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Wind speed',
    category: 'Climate',
    isSelected: false,
    workspaceName: 'output_wind_speed',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'climate'],
    styleName: 'wind',
    layer_type: 'output_wind_speed', // Unity: [m/s]
    download_url:
      gitlabUrl + '/climate/climate_wind_speed/raw/master/data/EU_wind_speed_average.tif',
    description: gitlabUrl + '/climate/climate_wind_speed/blob/master/README.md',
    isLoading: false,
    dataType: 'raster',
    schema: 'geo',
    projects: ['citiwatts', 'CoolLIFE'],
  },

  // Electricity
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    name: 'Electricity CO2 emissions - country average',
    category: 'Electricity',
    isSelected: false,
    workspaceName: 'yearly_co2_emission',
    zoomLevel: 0,
    ref: [default_drop_down_button, 'electricity'],
    styleName: 'yearly_co2_emission',
    layer_type: 'yearly_co2_emission', // Unity: kg/MWh
    download_url:
      gitlabUrl +
      '/load_electricity/electricity_emissions_hourly/raw/master/data/Emissions_hourly.csv?ref_type=heads&inline=false',
    description: gitlabUrl + '/load_electricity/electricity_emissions_hourly/blob/master/README.md',
    isLoading: false,
    layerName: 'yearly_co2_emission_factors_view',
    year: 2015,
    // before: schema: "geo",
    schema: 'public',
    dataType: 'csv',
    scales: [nuts0],
    projects: ['citiwatts'],
  },
]
export const HiddenDataInteractionArray: HiddenDataInteractionClass[] = [
  // For second input in CM - District Heating Potential : Economic Assessment
  // {
  //   id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
  //   name: "Heat density total 2", // Add for CM district heating potential economic assessment (need twice this layers)
  //   workspaceName: "heat_tot_curr_density",
  //   layer_type: "heat2",
  //   download_url:
  //     gitlabUrl +
  //     "/heat/heat_tot_curr_density/raw/master/data/heat_tot_curr_density.tif",
  // },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'nuts_id_number',
    name: 'nuts_id_number',
    layer_type: 'nuts_id_number',
    download_url: gitlabUrl + '/nuts_id_number/raw/master/data/nuts_id_number.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'lau2_id_number',
    name: 'lau2_id_number',
    layer_type: 'lau2_id_number',
    download_url: gitlabUrl + '/lau2_id_number/raw/master/data/lau2_id_number.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'gfa_res_curr_density',
    name: 'gfa_res_curr_density',
    layer_type: 'gfa_res_curr_density',
    download_url: gitlabUrl + '/gfa_res_curr_density/raw/master/data/gfa_res_curr_density.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'gfa_nonres_curr_density',
    name: 'gfa_nonres_curr_density',
    layer_type: 'gfa_nonres_curr_density',
    download_url:
      gitlabUrl + '/gfa_nonres_curr_density/raw/master/data/gfa_nonres_curr_density.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'heat_res_curr_density',
    name: 'heat_res_curr_density',
    layer_type: 'heat_res_curr_density',
    download_url:
      gitlabUrl + '/heat/heat_res_curr_density/raw/master/data/heat_res_curr_density.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'heat_nonres_curr_density',
    name: 'heat_nonres_curr_density',
    layer_type: 'heat_nonres_curr_density',
    download_url:
      gitlabUrl + '/heat/heat_nonres_curr_density/raw/master/data/heat_nonres_curr_density.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'ghs_built_1975_100_share',
    name: 'ghs_built_1975_100_share',
    layer_type: 'ghs_built_1975_100_share',
    download_url:
      gitlabUrl +
      '/construction_periods/ghs_built_1975_100_share/raw/master/data/GHS_BUILT_1975_100_share.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'ghs_built_1990_100_share',
    name: 'ghs_built_1990_100_share',
    layer_type: 'ghs_built_1990_100_share',
    download_url:
      gitlabUrl +
      '/construction_periods/ghs_built_1990_100_share/raw/master/data/GHS_BUILT_1990_100_share.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'ghs_built_2000_100_share',
    name: 'ghs_built_2000_100_share',
    layer_type: 'ghs_built_2000_100_share',
    download_url:
      gitlabUrl +
      '/construction_periods/ghs_built_2000_100_share/raw/master/data/GHS_BUILT_2000_100_share.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'ghs_built_2014_100_share',
    name: 'ghs_built_2014_100_share',
    layer_type: 'ghs_built_2014_100_share',
    download_url:
      gitlabUrl +
      '/construction_periods/ghs_built_2014_100_share/raw/master/data/GHS_BUILT_2014_100_share.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'building_footprint_tot_curr',
    name: 'building_footprint_tot_curr',
    layer_type: 'building_footprint_tot_curr',
    download_url:
      gitlabUrl + '/building_footprint_tot_curr/raw/master/data/building_footprint_tot_curr.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'ground_conductivity',
    name: 'ground_conductivity',
    layer_type: 'ground_conductivity',
    download_url:
      gitlabUrl + '/potential/ground_conductivity/raw/master/data/ground_conductivity.tif',
  },
  {
    id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
    workspaceName: 'population_density',
    name: 'population_density',
    layer_type: 'population',
    download_url: gitlabUrl + '/population_density/raw/main/data/raster_for_test.tif',
  },
]

export const cm_default_layer = {
  id: idDefaultLayer, //Do not change! In the backend, if id=0 this indicates that it is not a personal layer
  name: '',
  category: calculation_module_category,
  isSelected: true,
  workspaceName: '',
  zoomLevel: 0,
  ref: [default_drop_down_button, calculation_module_category],
  styleName: '',
  layer_type: calculation_module_category,
  cm_id: '',
  isLoading: false,
}
