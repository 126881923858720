<div
  [ngClass]="mapComponent.openedTabs[1] ? 'sidetab sidetabSelected' : 'sidetab'"
  (click)="openTab()"
>
  Layers
</div>
<div
  *ngIf="layersOpened"
  id="layertab"
  [ngClass]="mapComponent.openedTabs[1] ? 'layertabWrapper tabopen' : 'layertabWrapper'"
>
  <div class="header">
    <h3>Layers</h3>
    <p (click)="closeTab()">x</p>
  </div>

  <div class="layer-list-container">
    <input
      type="text"
      class="uk-input search"
      placeholder="Search Layers..."
      [ngModel]="layerSearch"
      (ngModelChange)="searchLayers($event)"
    />
    <button
      class="uk-button uk-button-primary uk-button-small unselectButton"
      (click)="unselectAllLayers()"
    >
      Unselect all layers
    </button>
    <ul class="layer-list">
      <li>
        <span>
          <htm-personnal-layers *ngIf="isConnected"></htm-personnal-layers>

          <htm-share-layers *ngIf="isConnected"></htm-share-layers>

          <!-- Use a unique ID for each accordion -->
          <!-- Fixes accordion not closing and opening -->
          <ng-container *ngFor="let cat of category; let index = index">
            <div *ngIf="cat.name != ''">
              <ul [attr.id]="'accordion-' + index" uk-accordion>
                <li class="uk-open">
                  <h3 class="uk-accordion-title">
                    <div (click)="checkGroup(cat.name)" class="checkbox">
                      <div
                        [ngClass]="isGroupChecked(cat.name, layers) ? 'checked' : 'unchecked'"
                      ></div>
                    </div>
                    {{ cat.name }}
                  </h3>
                  <div class="uk-accordion-content">
                    <ng-container *ngFor="let layer of layers">
                      <!--TODO: Fix heat2 hard coded filter-->
                      <span
                        class="layerslist"
                        *ngIf="
                          cat.name === layer.category &&
                          layer.category != '' &&
                          layer.layer_type != 'heat2'
                        "
                      >
                        <layers-interaction-cell
                          [dataInteraction]="layer"
                          [expanded]="expanded"
                          disabled="true"
                        ></layers-interaction-cell>
                      </span>
                    </ng-container>
                  </div>
                </li>
              </ul>
            </div>
          </ng-container>
        </span>
      </li>
    </ul>
  </div>
</div>
