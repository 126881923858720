/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 09.06.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/
import { Injectable, isDevMode } from '@angular/core'
@Injectable()
export class Logger {
  logs: string[] = [] // capture logs for testing
  datas: string[] = []
  str = ''
  log(message: string, data: any = '', error: '' | 'warn' | 'error' = '') {
    if (isDevMode() === true) {
      this.logs.push(message)
      this.datas.push(data)
      if (error == 'error') {
        console.error(message, data)
      } else if (error == 'warn') {
        console.warn(message, data)
      } else console.log(message, data)
    }
  }
  // Useless: print "obj" Gwenaëlle Gustin 03.04.2024
  /*
  logObj(obj: any) {
    let output = "";
    console.log(output);
    // Improvement of coding style : (with codelyzer)
    // for (... in ...) statements must be filtered with an if statement
    // Identifier 'property' is never reassigned; use 'const' instead of 'let'
    for (let property in obj) {
      output += property + ": " + obj[property] + "; ";
    }
    this.logs.push(output);
    console.log(output);
  }
  */
  /*
  logJson(obj: any) {
    if (isDevMode() === true) {
      this.logs.push("JSON representation: " + JSON.stringify(obj));
    }
  }*/

  describeProperties(object: any) {
    if (isDevMode() === true) {
      for (const prop in object) {
        // object[prop]
        this.logs.push(object[prop])
        break
      }
    }
  }
}
