/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 27.05.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/
import { LatLngBounds } from 'leaflet'
import { ILatLng } from './latLng.interface'

export class LocationClass implements ILatLng {
  latitude: number
  longitude: number
  address: string
  country: string
  viewBounds: LatLngBounds
}
