import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { MapComponent } from '@pages/map/map.component'
import { WelcomePageComponent } from '@pages/welcome-page/welcome-page.component'

export const routes: Routes = [
  {
    path: '',
    component: WelcomePageComponent,
  },
  {
    path: 'map',
    component: MapComponent,
  },
  {
    path: 'register',
    component: MapComponent,
  },
  {
    path: 'recover',
    component: MapComponent,
  },
  {
    // Wildcard route for a 404 page
    path: '**',
    redirectTo: '/',
  },
]

@NgModule({
  imports: [
    // Routing
    RouterModule.forRoot(routes, {}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
