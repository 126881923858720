import { NgIf } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '@core/services/auth.service'
import { InteractionService } from '@core/services/interaction.service'
import { apiUrl } from '@services/data.service'
import { ToasterService } from '@services/toaster.service'
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha'
import { of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { GdprComponent } from './gdpr/gdpr.component'
import { UserManagementStatusService } from './service/user-management-status.service'

@Component({
  standalone: true,
  selector: 'htm-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    // External Modules
    RecaptchaModule,
    RecaptchaFormsModule,
    // Components
    UserManagementComponent,
    GdprComponent,
  ],
})
export class UserManagementComponent implements OnInit {
  isLoggedIn = false
  username: string // Username
  email: string // Email

  constructor(
    private _userManagementStatusService: UserManagementStatusService,
    private _interactionService: InteractionService,
    private _route: ActivatedRoute,

    private authService: AuthService,
    private toasterService: ToasterService,
    private router: Router,
    private http: HttpClient,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.authService.isLoggedIn()
    // If logged in, get the username (keycloak version)
    if (this.isLoggedIn) {
      this.username = this.authService.getUserName()
      this.email = this.authService.getUserEmail() // Get the user's email
    }

    // Check if loggedOut or loggedIn query parameter is present and show toaster
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('loggedOut')) {
      this.toasterService.showToaster('You have successfully logged out.')
    } else if (urlParams.has('loggedIn')) {
      this.toasterService.showToaster(`User connected. Welcome ${this.username}`)
    }
    //Remove the query parameter from the URL without refreshing the page
    this.router.navigate([], {
      queryParams: { loggedOut: null, loggedIn: null },
      queryParamsHandling: 'merge',
    })
  }

  // Method to handle login button click
  login() {
    this.authService.login()
  }

  // Method to handle logout
  logout() {
    this.authService.logout()
  }

  editAccount() {
    const keycloakBaseUrl = this.authService.getKeycloakInstance().authServerUrl
    const realmName = this.authService.getKeycloakInstance().realm

    // Construct the URL for the Keycloak Account Management page
    const accountUrl = `${keycloakBaseUrl}/realms/${realmName}/account`

    // Redirect the user to the Keycloak Account Management page
    window.location.href = accountUrl
  }

  debug() {
    const baseApiUrl = `${apiUrl}/cm/list`

    this.http
      .post(baseApiUrl, {})
      .pipe(
        catchError((error) => {
          console.error('Error occurred:', error)
          alert('Failed to fetch user information.')
          return of(null) // Return a null observable in case of error
        }),
      )
      .subscribe((response) => {
        if (response) {
          console.log('API Response:', response)
          alert(JSON.stringify(response)) // Show the response as an alert
        }
      })
  }
}
