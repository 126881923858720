import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { ChartComponent } from '@components/chart/chart.component'
import { AuthService } from '@core/services/auth.service'
import {
  color_unusedspace,
  color_usedspace,
  diskspacechart_options,
  labels_diskspacechart,
} from '@services/data.service'
import { InteractionService } from '@services/interaction.service'
import { Logger } from '@services/logger.service'
import { ToasterService } from '@services/toaster.service'
import { environment } from 'environments/environment'
import { UserManagementStatusService } from '../user-management/service/user-management-status.service'
import { UserManagementService } from '../user-management/service/user-management.service'
import { UploadComponent } from './upload/upload.component'

@Component({
  standalone: true,
  selector: 'app-file-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.css'],
  imports: [
    CommonModule,

    // Components
    ChartComponent,
    UploadComponent,
  ],
})
export class FileManagementComponent implements OnInit {
  public isLoggedIn = false
  private _token = ''
  private _username = ''
  fileManagement = false
  firstname = ''
  lastname = ''
  isEditable = false
  diskspaceUsed = 0
  diskspaceMax = 0
  diskspaceLabels = labels_diskspacechart
  diskspaceDataset
  diskspaceOptions = diskspacechart_options
  public localhost: boolean = environment.apiUrl.includes('localhost')

  constructor(
    private _userManagementService: UserManagementService,
    private _userManagementStatusService: UserManagementStatusService,
    private _toasterService: ToasterService,
    private _interactionService: InteractionService,
    private authService: AuthService,
    protected logger: Logger,
  ) {
    this.logger = logger
  }

  ngOnInit(): void {
    this.fetchInformation()
  }

  async fetchInformation() {
    this.isLoggedIn = await this.authService.isLoggedIn()

    if (this.isLoggedIn) {
      this._username = this.authService.getUserName()
      this.getAcountInformation()
    }
  }

  openFiles() {
    this.fetchInformation()
    this.fileManagement = true
  }

  getAcountInformation() {
    this.getUserInformations()
    this._userManagementService.getDiskSpace().then((data) => {
      this.diskspaceMax = data.max_size
      this.diskspaceUsed = data.used_size
      this.diskspaceDataset = [
        {
          label: 'Account disk',
          backgroundColor: [color_usedspace, color_unusedspace],
          data: [this.diskspaceUsed, this.diskspaceMax - this.diskspaceUsed],
        },
      ]
    })
  }
  getUserInformations() {
    this.logger.log('file-management/getUserInformations()')
    this.firstname = this.authService.getFirstname()
    this.lastname = this.authService.getLastname()
  }

  updateDiskSpace() {
    this.getAcountInformation()
  }
}
