<div *ngIf="calculationModules?.length >= 1">
  <input
    type="text"
    class="uk-input search"
    placeholder="Search Calculation Modules..."
    *ngIf="!cmSelected"
    [ngModel]="searchCM"
    (ngModelChange)="searchCMs($event)"
  />
  <div [ngClass]="{ redisplay: !cmSelected, undisplay: cmSelected }" class="uk-margin">
    <span *ngIf="expanded">
      <ul uk-accordion *ngFor="let category of categories">
        <li class="uk-open">
          <h3 class="uk-accordion-title">
            {{ category }}
          </h3>
          <div class="uk-accordion-content">
            <ng-container *ngFor="let cm of displayedCalculationModules">
              <span *ngIf="category === cm.category && cm.isReadable">
                <div class="uk-container">
                  <div class="uk-offcanvas-content">
                    <button
                      class="uk-button uk-button-default uk-margin-small-right"
                      [class.button-disabled]="
                        selectionSurface >= maxSurfaceValueCM ||
                        selectionSurface <= 0 ||
                        !validateAuthorizedScale(cm)
                      "
                      [class.api-inprogress]="cm.isApiRequestInTreatment"
                      type="button"
                      (click)="selectCM(cm)"
                    >
                      <ng-container *ngIf="cm.isApiRequestInTreatment">Refresh</ng-container>
                      {{ cm.cm_name }}
                    </button>
                  </div>
                </div>
              </span>
            </ng-container>
          </div>
        </li>
      </ul>
    </span>
    <app-stand-alone-cm></app-stand-alone-cm>
  </div>
  <div *ngIf="waitingCM" class="waitingbox">
    <div class="spinner" uk-spinner></div>
  </div>

  <div [ngClass]="{ appear: cmSelected, disappear: !cmSelected }">
    <div *ngIf="cmSelected" style="display: flex; align-items: flex-start">
      <a class="button" type="button" (click)="toggleCMPanel(false)"
        ><span style="width: 25px" uk-icon="icon: arrow-left; ratio: 2"></span
      ></a>
      <div style="margin-right: 30px">
        <h4 class="cm_title">{{ cmSelected.cm_name }}</h4>

        <span class="uk-margin input-prefix">
          Session name
          <input
            class="uk-input"
            maxlength="50"
            type="text"
            placeholder="{{ this.nowStr }}"
            [(ngModel)]="prefix_cm"
          /> </span
        ><br />
        <span class="uk-margin description">{{ cmSelected.cm_description }}</span
        ><br />
        <span class="uk-margin description-link" *ngIf="cmSelected.wiki_url">
          <a class="uk-link-heading" href="{{ cmSelected.wiki_url }}" target="_blank">
            <div src="/assets/first-page/logo_wiki.svg" class="icon-wiki"></div
          ></a> </span
        ><br />
        <span class="uk-margin" *ngIf="cmSelected.status_id"
          >id of request: {{ cmSelected.status_id }}</span
        >
        <div *ngIf="waitingCM" class="waitingboxcomponents">
          <div class="spinner" uk-spinner></div>
        </div>

        <div style="margin-top: 10px">
          <htm-import-cm-inputs (dataImported)="handleImportedData($event)"></htm-import-cm-inputs>
        </div>

        <ng-container *ngIf="!waitingCM">
          <div
            class="component uk-margin"
            *ngFor="let input_cat of inputs_categories; let i = index"
          >
            <ul uk-accordion *ngIf="input_cat.contains_component">
              <li class="" [class.uk-open]="i === 0">
                <a class="uk-accordion-title input_title" href="#">{{ input_cat.name }} </a>
                <div class="uk-accordion-content">
                  <div
                    class="component uk-margin"
                    *ngFor="let component of getComponentFiltered(input_cat.id)"
                  >
                    <span
                      [matTooltip]="component.input_description"
                      matTooltipPosition="above"
                      matTooltipClass="multiline-tooltip"
                      >{{ component.input_name }} - (value: {{ component.input_value
                      }}{{ component.input_unit == '' ? '' : ' ' }}{{ component.input_unit }})
                      <div class="uk-form-controls">
                        @if (
                          component.input_type == type_input || component.input_type == type_range
                        ) {
                          <input
                            class="uk-{{ component.input_type }}"
                            id="{{ component.input_parameter_name }}"
                            value="{{ component.input_value }}"
                            type="{{ component.input_type }}"
                            min="{{ component.input_min }}"
                            max="{{ component.input_max }}"
                            placeholder="{{ component.input_name }}"
                            (change)="changeValueFromInput($event, component)"
                          />

                          <span class="values">
                            Min: {{ component.input_min }}{{ component.input_unit }}; Max:
                            {{ component.input_max }}{{ component.input_unit }};
                          </span>
                        } @else if (component.input_type == type_radio) {
                          <ng-container
                            *ngFor="let value of component.input_default_value; let i = index"
                          >
                            <label style="cursor: pointer"
                              ><input
                                class="uk-{{ component.input_type }}"
                                name="{{ component.input_name }}"
                                id="{{ component.input_name }}"
                                value="{{ value.input }}"
                                type="{{ component.input_type }}"
                                (change)="changeValueFromInputArray(this.value, component)"
                                [checked]="component.input_value == value"
                              />
                              {{ value }}</label
                            >
                          </ng-container>
                        } @else if (component.input_type == type_select) {
                          <select
                            class="uk-{{ component.input_type }}"
                            (change)="changeValueFromInputArray($event.target.value, component)"
                          >
                            <option
                              *ngFor="let value of component.input_default_value"
                              value="{{ value }}"
                              selected="{{ component.input_value == value ? 'selected' : '' }}"
                            >
                              {{ value }}
                            </option>
                          </select>
                        }
                      </div>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div *ngIf="layersFromType.length >= 1" class="uk-margin">
            <ul uk-accordion>
              <li class="uk-open">
                <h4 class="uk-accordion-title">LAYER INPUTS</h4>
                <div class="uk-accordion-content">
                  <div *ngFor="let layersTable of layersFromType">
                    <div
                      [matTooltip]="
                        'Type: ' +
                        layersTable.data_type +
                        ' - ' +
                        layersTable.layerType +
                        '\nDescription: ' +
                        layersTable.type_description
                      "
                      matTooltipClass="multiline-tooltip"
                      matTooltipPosition="above"
                      class="layer-input-container"
                    >
                      <h5>
                        @if (layersTable.input_name != null) {
                          {{ layersTable.input_name }}
                        } @else {
                          {{ layersTable.type_description }}
                        }
                      </h5>

                      <select
                        class="uk-select"
                        [(ngModel)]="layersTable.layerSelected"
                        (change)="setLayerNeeded()"
                      >
                        <ng-container *ngFor="let layer of layersTable.layers">
                          <option *ngIf="layer.category != 'Calculation module'" [ngValue]="layer">
                            <!-- TODO: to be modified when generated layers will be handled -->
                            {{ layer.name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <button
            class="uk-button run-cm-button"
            [class.uk-button-primary]="!cmRunning"
            [class.uk-button-danger]="cmRunning"
            (click)="!cmRunning ? runCM() : stopCM()"
          >
            <span *ngIf="!cmRunning">Run CM</span>
            <span *ngIf="cmRunning">
              <span class="spinner-stop" uk-spinner="ratio: 1"></span>
              <span class="stop-cm-text">STOP CM</span>
            </span>
          </button>
          <!-- <button *ngIf="cmRunning" class="uk-button uk-button-danger" (click)="stopCM()">
          STOP CM
        </button> -->
          <!-- USELESS PROGRESS BAR-->
          <!--div class="uk-margin"></div>
          <progress
            id="js-progressbar"
            class="uk-progress"
            value="{{ progress }}"
            max="100"
          ></progress-->
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="calculationModules?.length === 0">
  <span class="no-data"
    >No calculation module runs. <br />
    Please, try later!</span
  >
</div>
