import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Dictionary } from '@core/models/dictionary.class'
import { APIService } from '@services/api.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import {
  apiUrl,
  cm_layers_order,
  default_color_shpfile,
  default_fillColor_shpfile,
  default_fillOpacity_shpfile,
  raster_type_name,
  vector_type_name,
} from '@services/data.service'
import { LoaderService } from '@services/loader.service'
import { Logger } from '@services/logger.service'
import { ToasterService } from '@services/toaster.service'
import * as L from 'leaflet'
import * as shpjs from 'shpjs'

@Injectable()
export class CMLayersService extends APIService {
  private _layersCM = new L.FeatureGroup()
  private _cmLayersArray: Dictionary
  // private layerAdded;

  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService,
  ) {
    super(http, logger, loaderService, toasterService)
    this._cmLayersArray = new Dictionary([{ key: null, value: null }])
  }
  getLayersCM(): any {
    return this._layersCM
  }

  getLayerArray(): Dictionary {
    return this._cmLayersArray
  }
  addOrRemoveLayerWithAction(directory, type, map: any) {
    if (!this._cmLayersArray.containsKey(directory)) {
      this.addLayerWithAction(directory, type)
    } else {
      this.removelayer(directory, type)
    }
    map.fireEvent('didUpdateLayers', this._cmLayersArray)
  }
  addLayerWithActionRaster(directory: string) {
    const self = this
    // Make an API call using HttpClient to retrieve the max zoom level for the directory
    this.http.get<any>(`${apiUrl}/cm/maxzoom/${directory}`).subscribe({
      next: (data) => {
        const maxNativeZoom = data.maxZoom || 14 // Fallback to 14 if no maxZoom is provided

        // Now create the tile layer with the retrieved maxNativeZoom
        let layer = L.tileLayer(`${apiUrl}/cm/tiles/${directory}/{z}/{x}/{y}`, {
          zIndex: cm_layers_order,
          maxNativeZoom: maxNativeZoom, // Use the retrieved max zoom level
          tms: true,
        })

        // Add the layer to the map and store it in the layers array
        layer.addTo(self._layersCM)
        self._cmLayersArray.add(directory, layer)
      },
      error: (error) => {
        // Handle errors
        console.error('Error retrieving max zoom level:', error)
      },
      complete: () => {
        console.log('Request completed')
      },
    })
  }
  addLayerWithActionVector(directory) {
    const self = this
    shpjs(apiUrl + '/cm/files/' + directory).then((data) => {
      let layer
      layer = new L.GeoJSON(data as any, {
        onEachFeature: this.onEachFeature,
        style: (feature) => {
          let color = default_color_shpfile
          let fillColor = default_fillColor_shpfile
          let fillOpacity = default_fillOpacity_shpfile
          if (!isNullOrUndefinedString(feature.properties['color']))
            color = feature.properties['color']
          if (!isNullOrUndefinedString(feature.properties['fillColor']))
            fillColor = feature.properties['fillColor']
          if (!isNullOrUndefinedString(feature.properties['opacity']))
            color = feature.properties['opacity']

          return {
            color: color,
            fillColor: fillColor,
            fillOpacity: fillOpacity,
          }
        },
      })

      layer.addTo(self._layersCM)
      self._cmLayersArray.add(directory, layer)
    })
  }
  addLayerWithAction(directory, type) {
    // let layerAdded:any;
    if (type === raster_type_name) {
      this.addLayerWithActionRaster(directory)
    } else if (type === vector_type_name) {
      this.addLayerWithActionVector(directory)
    }
  }
  onEachFeature(feature, layer) {
    let html = ''
    Object.keys(feature.properties).map((prop) => {
      if (prop != 'color' && prop != 'fillColor' && prop != 'opacity') {
        html += '<bold>' + prop + ':</bold>'
        html += '<span>' + feature.properties[prop] + '</span><br />'
      }
    })
    layer.bindPopup(html)
  }
  removelayer(id, type) {
    // we get the layer we want to remove
    const layer = this._cmLayersArray.value(id)
    if (type === vector_type_name) {
      layer.clearLayers()
    } else if (type === raster_type_name) {
      this._layersCM.removeLayer(layer)
    }
    // we remove this layer from map
    // we destroy the layer
    this._cmLayersArray.remove(id)
  }

  clearAll() {
    this.logger.log('cm-layers.service/clearAll')
    this._layersCM.clearLayers()
  }
}
