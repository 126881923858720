import { NgClass, NgIf } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DataInteractionService } from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.service'
import { InteractionService } from '@core/services/interaction.service'
import { Logger } from '@core/services/logger.service'
import { MapComponent } from '@pages/map/map.component'
import { MapService } from '@pages/map/services/map.service'
import { CalculationModuleComponent } from './calculation-module/component/calculation-module.component'

@Component({
  standalone: true,
  selector: 'app-cmtab',
  templateUrl: './cmtab.component.html',
  styleUrls: ['./cmtab.component.css'],
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    ReactiveFormsModule,

    // Components
    CalculationModuleComponent,
  ],
})
export class CmtabComponent implements OnInit {
  @Input() selectionSurface
  @Input() scaleLevel
  @Input() cmsOpen: boolean
  open: boolean
  expandedState = 'expanded'
  expanded = true
  layersSelected = []

  constructor(
    public mapComponent: MapComponent,
    protected mapService: MapService,
    private _logger: Logger,
    private _dataInteractionService: DataInteractionService,
    public interactionService: InteractionService,
  ) {}

  ngOnInit(): void {}

  openTab(): void {
    this.cmsOpen = !this.cmsOpen
    if (this.cmsOpen) {
      this.mapComponent.setCMsOpened()
    } else {
      this.mapComponent.setTabsClosed()
    }
  }

  closeTab(): void {
    this.cmsOpen = false
    this.mapComponent.setTabsClosed()
  }
}
