import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable()
export class ScenarioService {
  constructor(private _http: HttpClient) {}

  public getModels() {
    const payload = {
      scenario_name: environment.scenarioExplorer,
    };
    const url = `${environment.apiUrl}/scenario_explorer/models`;

    return this._http.post<ModelsList>(url, payload);
  }

  public getDefinitions(model_name) {
    const payload = {
      scenario_name: environment.scenarioExplorer,
      model_name: model_name,
    };
    const url = `${environment.apiUrl}/scenario_explorer/models/definition`;

    return this._http.post<ModelDefinition>(url, payload);
  }

  public getData(model_name, variable, region_scale, year) {
    const payload = {
      scenario_name: environment.scenarioExplorer,
      model_name: model_name || "",
      region_scale: region_scale || "",
      variable: variable || "",
      year: year.toString() || "",
    };
    const url = `${environment.apiUrl}/scenario_explorer/models/data`;

    return this._http.post<ScenarioData>(url, payload);
  }

  public getColor;
}

export class ModelsList {
  models: string[];
}

export class ModelDefinition {
  regions: string[];
  variables: string[];
  years: number[];
}

export class ScenarioData {
  "features": any[];
  "numberMatched": number;
  "numberReturned": number;
  "timeStamp": string;
  "totalFeatures": number;
  "type": string;
}
