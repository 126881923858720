/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 07.07.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/

import { ChartOptions } from 'chart.js'
import { environment } from 'environments/environment'
import * as L from 'leaflet'
import 'leaflet-draw'

export const appName = environment.appName
export const apiUrl = environment.apiUrl
export const geoserverUrl = environment.geoserverUrl
export const keycloakUrl = environment.keycloakUrl
export const keycloakRealm = environment.keycloakRealm
export const keycloakClientId = environment.keycloakClientId
export const wikiUrl = environment.wikiUrl
export const feedback = environment.feedback
export const geocodeUrl = 'https://nominatim.openstreetmap.org/search?q=' // prefer
export const getIpUrl = 'https://ipv4.myexternalip.com/json' // prefer
export const getLocationFromIp = 'http://hotmaps.hevs.ch:9005/api/'
export const idDefaultLayer = 0
export const maxSurfaceValueCM = 64000000 //640000 changed for om4a
export const wwtpLayerName = 'wwtp'

export const urlLegend =
  geoserverUrl +
  '?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=50&HEIGHT=10&STRICT=false&style='
export const timeOutAjaxRequest = 10000
export const unit_heatload_profil = 'MW'

export const formatImage = 'image/png8'
// layer_name
export const geoserverGetFeatureInfoUrl =
  geoserverUrl +
  '?' +
  'SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image/png&TRANSPARENT=true&QUERY_LAYERS=' +
  environment.prefixWorkspaceName
export const nuts_level = '3'
export const populationLayerName = 'pop_tot_curr_density'
export const postStatsLayersHectares = '/stats/layers/hectares'
export const postStatsPersonalLayer = '/stats/personnal-layers'

export const postStatsLayersNutsLau = '/stats/layers/nuts-lau'
export const postHeatLoadProfileHectares = '/heat-load-profile/hectares'
export const postHeatLoadProfileNutsLau = '/heat-load-profile/nuts-lau'
export const postDurationCurveHectares = '/heat-load-profile/duration-curve/hectares'
export const postDurationCurveNutsLau = '/heat-load-profile/duration-curve/nuts-lau'
export const getElectricityMixFromNuts0 = '/stats/energy-mix/nuts-lau'

// User management endpoints
export const user_endpoint = '/users/'
export const upload_endpoint = '/upload/'
export const post_user_register = user_endpoint + 'register'
export const post_user_register_activate = user_endpoint + 'register/activate'
export const post_user_recovery_ask = user_endpoint + 'recovery/ask'
export const post_user_recovery = user_endpoint + 'recovery'
export const post_user_logout = user_endpoint + 'logout'
export const post_user_login = user_endpoint + 'login'
export const get_userinformation_endpoint = user_endpoint + 'information'
export const get_userupdateprofile_endpoint = user_endpoint + 'profile/update'
export const get_diskspace_endpoint = user_endpoint + 'space_used'

export const get_filelist_endpoint = upload_endpoint + 'list'

// title
export const heatloadprofile = 'Heatload profile'
export const Durationcurve = 'Duration curve'
export const energy_mix_title = 'Electricity generation mix'
export const set404url = 'set404url'

// Projection data string
export const proj3035 =
  '+proj=laea +lat_0=52 +lon_0=10 +x_0=43f21000 +y_0=3210000 +ellps=GRS80 +units=m +no_defs'
export const proj4326 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'

// Heat load profil
export const heat_load_api_day = 'day'
export const heat_load_api_year = 'year'
export const heat_load_api_month = 'month'
export const buttons_heat_load = [
  {
    name: 'Year',
    api_ref: heat_load_api_year,
    selected: true,
    date: 2010,
    min: 2010,
    max: 2010,
    options: [],
  },
  {
    name: 'Month',
    api_ref: heat_load_api_month,
    selected: false,
    date: 1,
    min: 1,
    max: 12,
    options: [],
  },
  {
    name: 'Day',
    api_ref: heat_load_api_day,
    selected: false,
    date: 1,
    min: 1,
    max: 31,
    options: [],
  },
]
export const default_drop_down_button = 'overall'
export const calculation_module_category = 'Calculation module'
export const raster_type_name = 'raster'
export const vector_type_name = 'vector'
export const summay_drop_down_buttons = [
  {
    name: 'Results overview',
    ref: default_drop_down_button,
    selected: true,
    display: false,
  },
  { name: 'Demand', ref: 'demand', selected: false, display: false },
  { name: 'Potentials', ref: 'potential', selected: false, display: false },
  { name: 'Climate', ref: 'climate', selected: false, display: false },
  { name: 'Buildings', ref: 'buildings', selected: false, display: false },
  { name: 'Heat Supply', ref: 'heat_supply', selected: false, display: false },
  { name: 'Industry', ref: 'industry', selected: false, display: false },
  { name: 'Electricity', ref: 'electricity', selected: false, display: false },
  { name: 'Population', ref: 'population', selected: false, display: false },
  { name: 'Mobility', ref: 'mobility', selected: false, display: false },
  {
    name: 'Calculation module',
    ref: calculation_module_category,
    selected: false,
    display: false,
  },
]
// Layer order number
/*
  More the number is big, more hight is the layer on the toolbox
*/
export const industry_layer_order = 6
export const scale_layer_order = 5
export const cm_layers_order = 4
export const layers_order = 3
export const maps_order = 1

// layers constant
export const timeOut = 200000
// layers constant
export const clickAccuracy = 100
export const zoomLevelDetectChange = 10
export const constant_year = 2012
export const constant_year_sp_wwtp = 2015
export const constant_year_duration_curve = 2010
export const business_name_wwtp = 'Waste Water treatment plants'
export const business_name_population = 'Population'
export const unit_capacity = 'population equivalent'
export const unit_heat_density = 'MWh/ha'
export const unit_shape_area = 'm2'
export const unit_population = 'person/ha'
export const round_value = '1.2-2'
export const defaultZoomLevel = 5
export const map_options = {
  zoomControl: false,
  center: L.latLng(environment.centerMap[0], environment.centerMap[1]),
  zoom: environment.zoom,
  minZoom: 4,
  maxZoom: 17,
  zoomAnimationThreshold: 3,
  layers: [],
}
export const lau2name = 'tbl_lau1_2'

// Scale Value
export const nuts0 = 'NUTS 0'
export const nuts1 = 'NUTS 1'
export const nuts2 = 'NUTS 2'
export const nuts3 = 'NUTS 3'
export const lau2 = 'LAU 2'
export const hectare = 'Hectare'
export const country = 'Country'
export const region = 'Region'
export const subdivision = 'Sub-division'
export const initial_scale_value = environment.scaleRequest[0].label

// Event variable
export const MAPDRAWEDITED = L.Draw.Event.EDITED
export const MAPDRAWSTART = L.Draw.Event.DRAWSTART
export const MAPDRAWDELETED = L.Draw.Event.DELETED
export const MAPDRAWEDITSTOP = L.Draw.Event.EDITSTOP
export const MAPDRAWEDITSTART = L.Draw.Event.EDITSTART
export const MAPDRAWCREATED = L.Draw.Event.CREATED
export const MAPCLICK = 'click'
export const MAPLAYERCHANCE = 'baselayerchange'
export const MAPZOOMSTART = 'zoomstart'
export const MAPZOOMEND = 'zoomend'
export const MAPLAYERSCONTROLEVENT = 'LayersControlEvent'
export const MAPLAYERADD = 'layeradd'
export const MAPDIDIUPDATELAYER = 'didUpdateLayers'
export const MAPOVERLAYADD = 'overlayadd'

export const rightPanelSize = 600
export const leftPanelSize = 400

// tab values
export const default_tab_datapanel = 'indicator'
export const tab1_datapanel = default_tab_datapanel
export const tab2_datapanel = 'charts'

export const eu_logo_height = 70

// Duration curve graph data
export const duration_curve_graph_title = 'Duration curve'
export const duration_curve_graph_category = 'duration_curve'

export const duration_curve_graph_options = {
  legend: {
    display: false,
  },
  /* elements:{
      point:{
        radius:0
      }
    }, */
  tooltips: { enabled: false },
  hover: { mode: null },
  scales: {
    y: {
      title: {
        display: true,
        text: 'Heat Power (MW)',
      },
    },
    x: {
      ticks: { display: false },
      title: {
        display: true,
        text: 'Yearly duration',
      },
      grid: {
        color: '#FFFFFF',
      },
    },
  },
}

// Heat Load graph data
export const heat_load_graph_options = {
  scales: {
    y: {
      title: {
        display: true,
        text: 'Heat Power (MW)',
      },
    },
  },
}
export const energy_mix_graph_category = 'energy_mix'
export const energy_mix_options = {
  position: 'right',
  responsive: true,
  legend: {
    display: true,
    position: 'right',
  },
}

export const default_color_shpfile = '#FFA500'
export const default_fillColor_shpfile = '#FFA500'
export const default_fillOpacity_shpfile = 0.5

export const color_usedspace = '#bf2630'
export const color_unusedspace = '#26bf3d'
export const labels_diskspacechart = ['']
export const diskspacechart_options: ChartOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  maintainAspectRatio: false,
  indexAxis: 'y',
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          return (
            context.dataset.label +
            ': ' +
            context.dataset.data[context.dataIndex].toString().substring(0, 6) +
            ' MB'
          )
        },
      },
    },
  },
}

export const inputs_categories = [
  { id: '0', name: 'Inputs', contains_component: false },
  { id: '1', name: 'Basic inputs', contains_component: false },
  { id: '2', name: 'Advanced inputs: (Level 1)', contains_component: false },
  { id: '3', name: 'Advanced inputs: (Level 2)', contains_component: false },
  { id: '4', name: 'Advanced inputs: (Level 3)', contains_component: false },
]
