import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { ChartComponent } from '@components/chart/chart.component'
import { InteractionService } from '@core/services/interaction.service'
import { Logger } from '@core/services/logger.service'
import { energy_mix_options } from '@services/data.service'

@Component({
  standalone: true,
  selector: 'htm-electricity-mix',
  templateUrl: './electricity-mix.component.html',
  styleUrls: ['./electricity-mix.component.css'],
  imports: [
    CommonModule,

    // Components
    ChartComponent,
  ],
})
export class ElectricityMixComponent implements OnInit, OnChanges {
  subtitle = 'Electricity generation mix'

  type = 'pie'
  @Input() labels
  @Input() datasets
  @Input() isLoading
  options: any

  constructor(
    private _logger: Logger,
    private _interactionService: InteractionService,
  ) {}

  ngOnInit() {
    this.options = energy_mix_options
    /* this.update(); */
  }

  ngOnChanges(changes: SimpleChanges) {}
}
