import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { APIService } from '@core/services/api.service'
import { LoaderService } from '@core/services/loader.service'
import { Logger } from '@core/services/logger.service'
import { ToasterService } from '@core/services/toaster.service'
import { apiUrl, user_endpoint } from '@services/data.service'

@Injectable()
export class FeedbackService extends APIService {
  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService,
  ) {
    super(http, logger, loaderService, toasterService)
  }
  sendFeedback(data) {
    return super.POSTunStringify(data, apiUrl + user_endpoint + 'feedback', {
      headers: new HttpHeaders(),
    })
    //return this.http.post(, data);
  }
}
