<div *ngIf="tabSelected === 'indicator'" style="padding: 10px">
  <button
    id="exportBtnIndicator"
    class="uk-button uk-button-secondary"
    [disabled]="!indicatorState"
    (click)="exportIndicators()"
  >
    Export indicator
  </button>
</div>

<div *ngIf="tabSelected === 'charts'" style="padding: 10px">
  <button
    id="exportBtnGraph"
    class="uk-button uk-button-secondary"
    [disabled]="!graphState"
    (click)="exportGraphic()"
  >
    Export charts
  </button>
</div>
