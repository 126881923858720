/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 20.12.2017     Dany Djedovic                       Creation
 * *
 ******************************************************************/
import { animate, state, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Helper } from '@core/services/helper'
import { Logger } from '@core/services/logger.service'
import { MapService } from '@pages/map/services/map.service'
import { NumberFormatPipe } from '@pipes/number-format.pipe'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { hectare, maxSurfaceValueCM } from '@services/data.service'
import { Subscription } from 'rxjs'
import { SelectionToolService } from '../service/selection-tool.service'
import { defaultElementSelected, stButtons } from './selection-button.data'

@Component({
  standalone: true,
  selector: 'htm-selection-tool',
  templateUrl: './selection-tool.component.html',
  styleUrls: ['./selection-tool.component.css'],
  animations: [
    trigger('containerTrigger', [
      transition(':enter', [style({ opacity: 0 }), animate(250, style({ opacity: 1 }))]),
      transition(':leave', [animate(250, style({ opacity: 0 }))]),
    ]),
    trigger('elementsTrigger', [
      state('in', style({ opacity: '1' })),
      transition('void => *', [style({ opacity: '0' }), animate('100ms 200ms')]),
      transition('* => void', [animate('50ms', style({ opacity: '0' }))]),
    ]),
  ],
  imports: [
    CommonModule,

    // Pipes
    NumberFormatPipe,
  ],
})
export class SelectionToolComponent implements OnInit, OnDestroy {
  nbElementsSelected = 0
  fileToUpload: File = null
  isHectarSelected = false
  scaleSelected: any
  private _subscription: Subscription
  private _subscriptionNbNutsSelected: Subscription
  isLoaBtnDisabled = true
  isClearBtnDisabled = true
  stButtons = stButtons
  private _layerSelected
  elementSelected = defaultElementSelected
  maxSurfaceValueCM = maxSurfaceValueCM
  @Input() selectionSurface
  constructor(
    private _mapService: MapService,
    private _logger: Logger,
    private _helper: Helper,
    private _slcToolsService: SelectionToolService,
  ) {}

  ngOnInit() {
    this.subscribeMapService()
    this.scaleSelected = this._mapService.getScaleValue()
    this.isHectarSelected = this.scaleSelected == hectare
  }
  ngOnDestroy() {
    this._logger.log('SelectionToolComponent/ngOnDestroy')
    this._subscription.unsubscribe()
    this._subscriptionNbNutsSelected.unsubscribe()
  }
  subscribeMapService() {
    if (!isNullOrUndefinedString(this._mapService.getNutsSelectedSubject())) {
      this._subscriptionNbNutsSelected = this._mapService
        .getNutsSelectedSubject()
        .subscribe((value) => {
          this.nbElementsSelected = value
        })
    }
    if (!isNullOrUndefinedString(this._mapService.getScaleValueSubject())) {
      this._subscription = this._mapService.getScaleValueSubject().subscribe((value) => {
        this.scaleSelected = value
        if (value === hectare) {
          this.isHectarSelected = true
          this.elementSelected = 'Zones selected'
        } else {
          this.isHectarSelected = false
          this.elementSelected = defaultElementSelected
        }
      })
    }
    if (!isNullOrUndefinedString(this._mapService.getNbOfLayersSelected())) {
      this._mapService.getNbOfLayersSelected().subscribe((value) => {
        this._logger.log('subscribeMapService layerSelected', value)
        this._layerSelected = value
      })
    }
    if (!isNullOrUndefinedString(this._mapService.getNutsSelectedSubject())) {
      this._subscriptionNbNutsSelected = this._mapService
        .getNutsSelectedSubject()
        .subscribe((value) => {
          this._logger.log('subscribeMapService nbElementsSelected', value)
          this.nbElementsSelected = value
        })
    }
    // subscribing to click event subject of MapService
    if (!isNullOrUndefinedString(this._mapService.clickEventSubjectObs)) {
      this._mapService.clickEventSubjectObs.subscribe(() => {
        this.cursorClick() // call cursor click method when we click anywhere in the map
      })
    }

    if (!isNullOrUndefinedString(this._mapService.drawCreatedSubjectObs)) {
      this._mapService.drawCreatedSubjectObs.subscribe(() => {
        this.cursorClick()
      })
    }
    if (!isNullOrUndefinedString(this._mapService.getClearAllButtonSubject())) {
      this._mapService.getClearAllButtonSubject().subscribe((value) => {
        this.isClearBtnDisabled = !value
      })
    }

    if (!isNullOrUndefinedString(this._mapService.getLoadResultbuttonState())) {
      this._mapService.getLoadResultbuttonState().subscribe((value) => {
        this.isLoaBtnDisabled = !value
      })
    }
  }

  cursorClick() {
    const activeDrawTool = this.getActiveDrawTool()
    const map = this._mapService.getMap()
    this._mapService.clickSelection(map)

    if (activeDrawTool.type !== 'click') {
      this._mapService.activateDrawTool(map, activeDrawTool.type)
    }
  }

  /**
   * Draw method of the activated selection tool
   */
  drawTool(button: any) {
    this.deactivateAllDrawTools()
    button.isChecked = true

    if (button.type === 'click') {
      const map = this._mapService.getMap()
      this._mapService.clickSelection(map)
    } else {
      const map = this._mapService.getMap()
      this._mapService.activateDrawTool(map, button.type)
      this.stButtons[0].isChecked = false
    }

    if (this.selectionSurface <= 0) {
      this.isClearBtnDisabled = true
    }
  }

  getActiveDrawTool() {
    for (const stButton of stButtons) {
      if (stButton.isChecked) return stButton
    }
    return undefined
  }

  deactivateAllDrawTools() {
    for (const stButton of stButtons) {
      stButton.isChecked = false
    }
  }

  /**
   *  Draw method when someone upload a Shapes file
   */
  uploadShapes(files: FileList) {
    this.fileToUpload = files.item(0)

    // Read file and insert data in geoJson variable.
    const fileReader = new FileReader()
    let geoJson: any
    let featuresPoly = []

    fileReader.readAsText(this.fileToUpload, 'UTF-8')
    fileReader.onload = () => {
      geoJson = JSON.parse(<string>fileReader.result)
      const map = this._mapService.getMap()
      const features = geoJson.features
      const geometries = this._helper.lineify(geoJson)
      this._slcToolsService.drawShapeFromFile(map, geoJson)
    }
    fileReader.onerror = (error) => {
      this._logger.log('uploadShapes', error, 'error')
    }
  }

  /**
   * Load the results of the selection
   */
  loadResultsButton() {
    const map = this._mapService.getMap()
    this._mapService.loadResultNuts(map)
  }

  /**
   * Clear all informations in the info box
   */
  clearAllButton() {
    const map = this._mapService.getMap()
    this._mapService.clearAll(map)
    this.cursorClick()
  }

  setClearButtonText() {
    let zoneString = ' zones'
    if (this._layerSelected === 1 || this.nbElementsSelected === 1) {
      zoneString = ' zone'
    }
    if (this._layerSelected >= 1) {
      return 'Clear ' + this._layerSelected + zoneString
    } else if (this._layerSelected === 0) {
      return 'Clear ' + this.nbElementsSelected + zoneString
    }
  }
  clearLayers() {
    if (this._layerSelected >= 1) {
      this._mapService.deleteSelectedAreas()
    } else {
      this._mapService.clearAll(this._mapService.getMap())
      this.cursorClick()
    }
    if (this.nbElementsSelected == 0) {
      this._mapService.clearAll(this._mapService.getMap())
      this.cursorClick()
    }
    this.isClearBtnDisabled = true
  }
}
