import { animate, state, style, transition, trigger } from '@angular/animations'
import { NgIf } from '@angular/common'
import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core'
import { DataInteractionService } from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.service'
import { SideComponent } from '@components/_panel-right/side-panel/side-panel.component'
import { Helper } from '@core/services/helper'
import { InteractionService } from '@core/services/interaction.service'
import { Logger } from '@core/services/logger.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { constant_year, constant_year_duration_curve, rightPanelSize } from '@services/data.service'
import { ScenarioResults } from './result-manager/result-manager'
import { ResultManagerComponent } from './result-manager/result-manager.component'

@Component({
  standalone: true,
  selector: 'htm-right-side-panel',
  templateUrl: 'right-side-panel.component.html',
  styleUrls: ['right-side-panel.component.css'],
  animations: [
    trigger('panelWidthTrigger', [
      state('expanded', style({ width: rightPanelSize + 'px', display: 'block' })),
      state('collapsed', style({ width: '0px', display: 'none' })),
      transition('collapsed => expanded', animate('200ms ease-in')),
      transition('expanded => collapsed', animate('200ms 200ms ease-out')),
    ]),
    trigger('toggleDrawer', [
      state('collapsed', style({ right: '-600px' })),
      state('expanded', style({ right: '0px' })),
      transition('collapsed <=> expanded', animate('200ms 200ms ease-in')),
    ]),
    trigger('titleTextTrigger', [
      state('in', style({ opacity: '1' })),
      transition('void => *', [style({ opacity: '0' }), animate('100ms 300ms')]),
      transition('* => void', [animate('50ms', style({ opacity: '0' }))]),
    ]),
    trigger('iconTrigger', [
      transition('collapsed => expanded', animate('200ms ease-in')),
      transition('expanded => collapsed', animate('200ms ease-out')),
    ]),
  ],
  imports: [ResultManagerComponent, NgIf],
})
export class RightSideComponent extends SideComponent implements OnInit, OnChanges {
  //add animation to host element
  @HostBinding('@toggleDrawer') get getToggleDrawer(): string {
    return this.expanded ? 'expanded' : 'collapsed'
  }

  @Input() nutsIds
  @Input() layers
  @Input() scaleLevel
  @Input() locationsSelection
  @Input() areas
  @Input() personnalLayers
  private _cmTimeout

  private _poiTitle

  // Components status
  private _heatloadStatus = false
  private _durationCurveStatus = false
  private _electricityMixStatus = false
  private _summaryResultStatus = false

  cmPayload
  summaryPayload
  energyMixPayload

  scenarioResultsSelected: ScenarioResults
  heatLoadPayload
  durationCurvePayload

  personnalLayerPayload

  private _loadingData = false

  private _splittedResults
  private _summaryResult

  private _aCMisCurrentlyRunning
  private _cmRunned

  constructor(
    protected interactionService: InteractionService,
    private _helper: Helper,
    private _logger: Logger,
    private _dataInteractionService: DataInteractionService,
  ) {
    super(interactionService)
  }

  ngOnInit() {
    this.interactionService.getCMRunned().subscribe((val) => {
      this._cmRunned = val
      if (!isNullOrUndefinedString(this._cmRunned)) {
        this.setSatusResults()
        this.updateAll()
        this.setCMPayload()
      } else {
        this.cmPayload = null
      }
    })
  }
  ngOnChanges() {
    this._logger.log('RightSidePanelComponent/ngOnChanges')
    if (this.expanded) {
      this.setSatusResults()
      this.updateAll()
    } else {
      this.resetPayloads()
    }
  }
  resetPayloads() {
    this.cmPayload = null
    this.summaryPayload = null
    this.energyMixPayload = null
    // this.heatLoadPayload = null;
    //this.durationCurvePayload = null;
    this.personnalLayerPayload = null
  }
  setSatusResults() {
    /* if ((this.scaleLevel === '4') || (this.scaleLevel === '3') || (this.scaleLevel === '2') || (this.scaleLevel === '-1')) {
      this.heatloadStatus = true;
      this.durationCurveStatus = true;
    } else {
      this.heatloadStatus = false;
      this.durationCurveStatus = false;
    } */

    if (this.scaleLevel === '0') {
      this._electricityMixStatus = true
    } else {
      this._electricityMixStatus = false
    }
    this._summaryResultStatus = true
  }
  updateAll() {
    if (this._summaryResultStatus && this.scaleLevel !== '-1') {
      this.setSummaryPayloadIds()
    } else if (this._summaryResultStatus && this.scaleLevel === '-1') {
      this.setSummaryPayloadArea()
    } else {
      this.summaryPayload = null
    }

    /* if (this.heatloadStatus && this.scaleLevel !== '-1') {
      this.setHeatloadPayloadIds()
    } else if (this.heatloadStatus && this.scaleLevel === '-1') {
      this.setHeatloadPayloadAreas()
    } else {
      this.heatLoadPayload = null
    } */
    /* if (this.durationCurveStatus && this.scaleLevel !== '-1') {
      this.setDurationCurveIds()
    } else if (this.durationCurveStatus && this.scaleLevel === '-1') {
      this.setDurationCurveAreas()
    } else {
      this.durationCurvePayload = null;
    } */

    if (this._electricityMixStatus) {
      this.setElectricityMixPayload()
    } else {
      this.energyMixPayload = null
    }
    if (Object.keys(this.personnalLayers).length >= 1) {
      this.setPersonnalLayerPayload()
    } else {
      this.personnalLayerPayload = null
    }

    // test if calulation are running
    // if(this.aCMisCurrentlyRunning) {
    //   this.interactionService.showToaster("A CM is currently running. Please change configuration when the CM is finished or stop CM")
    //   return
    // }
    // if (this.cmRunned) {
    //   this.setCMPayload()
    // } else {
    //   this.cmPayload = null
    // }
  }

  // Create payloads
  setCMPayload() {
    let payloadTmp
    let cm_name = ''
    let areas
    if (this.scaleLevel !== '-1') {
      areas = this.summaryPayload.nuts
      // this.logger.log('this.cmRunned.cm.type_layer_needed ' + this.cmRunned.cm.type_layer_needed)
    } else if (this.scaleLevel === '-1') {
      areas = this.summaryPayload.areas
      // this.logger.log('this.cmRunned.cm.type_layer_needed ' + this.cmRunned.cm.type_layer_needed)
    }
    // payloadTmp = {
    //   areas: areas,
    //   year: this.summaryPayload.year,
    //   layers_needed: this.cmRunned.cm.layers_needed,
    //   type_layer_needed: this.cmRunned.cm.type_layer_needed,
    //   vectors_needed: this.cmRunned.cm.vectors_needed
    // };
    if (
      !isNullOrUndefinedString(this._cmRunned.cm.cm_prefix) &&
      this._cmRunned.cm.cm_prefix != ''
    ) {
      cm_name += this._cmRunned.cm.cm_prefix + ' - '
    }
    cm_name += this._cmRunned.cm.cm_name
    this.cmPayload = Object.assign(
      {
        url_file: 0,
        scalevalue: this.interactionService.getScaleLevel().replace(' ', '').toLocaleLowerCase(),
        inputs: this._cmRunned.component,
        cm_id: '' + this._cmRunned.cm.cm_id,
        cm_name: cm_name,
        cm_prefix: this._cmRunned.cm.cm_prefix,
      },
      {
        payload: {
          areas: areas,
          year: this.summaryPayload.year,
          layers_needed: this._cmRunned.cm.layers_needed,
          type_layer_needed: this._cmRunned.cm.type_layer_needed,
          vectors_needed: this._cmRunned.cm.vectors_needed,
        },
      },
    )
  }
  setPersonnalLayerPayload() {
    this.personnalLayerPayload = {
      layers: [],
      scale_level: this._helper.getScaleLevelPay(this.scaleLevel),
      areas: this.scaleLevel === '-1' ? this._helper.getAreasForPayload(this.areas) : this.nutsIds,
    }
    for (let key in this.personnalLayers) {
      this.personnalLayerPayload['layers'].push(this.personnalLayers[key])
    }
  }

  setSummaryPayloadIds() {
    const payload = {
      layers: this.layers,
      year: constant_year,
      scale_level: this.interactionService.getScaleLevel(),
      nuts: this.nutsIds,
    }
    /* if (this.helper.isPayloadIncomplete(payload) && Object.keys(this.personnalLayers).length == 0) {
      this.interactionService.disableRightPanel();
      return;
    } */
    this.summaryPayload = payload
  }

  setSummaryPayloadArea() {
    const areas = this._helper.getAreasForPayload(this.areas)
    /*this.logger.log('RightSidePanelComponent/areas()' + JSON.stringify(areas))
     if (areas.length === 0) {
      this.logger.log('RightSidePanelComponent/areas().lenght === 0')
      this.setIsDataRunning(false);
      return
    }; */

    this.summaryPayload = {
      layers: this.layers,
      year: constant_year,
      scale_level: this.interactionService.getScaleLevel(),
      areas: areas,
    }
  }
  setElectricityMixPayload() {
    this.energyMixPayload = { nuts: this.nutsIds }
  }
  setHeatloadPayloadAreas() {
    this.heatLoadPayload = {
      areas: this._helper.getAreasForPayload(this.areas),
      scale_level: this.interactionService.getScaleLevel(),
    }
  }
  setHeatloadPayloadIds() {
    this.heatLoadPayload = {
      nuts: this.nutsIds,
      scale_level: this.interactionService.getScaleLevel(),
    }
  }
  setDurationCurveIds() {
    this.durationCurvePayload = {
      nuts: this.nutsIds,
      year: constant_year_duration_curve,
      scale_level: this.interactionService.getScaleLevel(),
    }
  }
  setDurationCurveAreas() {
    this.durationCurvePayload = {
      areas: this._helper.getAreasForPayload(this.areas),
      year: constant_year_duration_curve,
    }
  }
}
