import { DatePipe, NgForOf, NgIf } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  SnapshotConfig,
  SnapshotService,
} from '@components/_panel-left/toolstab/snapshot/snapshot.service'
import { AuthService } from '@core/services/auth.service'
import { InteractionService } from '@services/interaction.service'
import { LoaderService } from '@services/loader.service'

@Component({
  standalone: true,
  selector: 'htm-snapshot-panel',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.less'],
  imports: [
    NgIf,
    NgForOf,
    FormsModule,
    ReactiveFormsModule,

    // Pipes
    DatePipe,
  ],
})
export class SnapshotComponent implements OnInit {
  snapshots: SnapshotConfig[] = []
  newSnapshot: string
  newOpen = false
  isLogged: boolean

  constructor(
    private _snapshotService: SnapshotService,
    private _interactionService: InteractionService,
    private _loaderService: LoaderService,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.isLogged = await this.authService.isLoggedIn()
    if (this.isLogged) {
      this.refresh()
    }
  }

  refresh() {
    this._snapshotService.list().then((snapshots) => (this.snapshots = snapshots))
  }

  apply(snapshot: SnapshotConfig) {
    this._loaderService.display(true)
    this._snapshotService.apply(snapshot, () => {
      this._loaderService.display(false)
    })
  }

  delete(snapshot: SnapshotConfig) {
    this._snapshotService.delete(snapshot).then(() => this.refresh())
  }

  openSave() {
    this.newOpen = true
  }

  closeSave() {
    this.newOpen = false
  }

  save() {
    this._snapshotService.add(this.newSnapshot, '').then((success) => {
      if (success) {
        this.newSnapshot = ''
        this.refresh()
      }
    })
  }
}
