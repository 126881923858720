<ng-container *ngFor="let layer of results.layers | filterLayers: refSelected">
  <tr class="table-level-one">
    <th colspan="2">{{ layer.name | layername }}</th>
  </tr>
  <tr *ngFor="let value of layer.values" class="table-level-two">
    <td class="info-column">{{ value.name | businessName }}</td>
    <td
      class="uk-align-left uk-align-center value-column"
      *ngIf="value.value && value.value != 'nan'"
    >
      {{ value.value | number: '1.0-2' | numberformat }}
      {{ value.unit | businessName }}
    </td>
    <td *ngIf="!value.value || value.value == 'nan'">Not available</td>
  </tr>
</ng-container>
<ng-container *ngFor="let layer of results.no_data_layers">
  <tr class="table-level-one warning">
    <th colspan="2"><span uk-icon="warning"></span> No data for "{{ layer | layername }}"</th>
  </tr>
</ng-container>
