export const issue_levels = [
  { name: 'low', id: 1 },
  { name: 'normal', id: 2 },
  { name: 'high', id: 3 },
]
export const issue_type = [
  { name: 'Bug', id: 1 },
  { name: 'Question', id: 2 },
  { name: 'Enhancement', id: 3 },
]
