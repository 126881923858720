import { Injectable } from '@angular/core'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { NutsRenderClass } from './NutsRenderClass'
import { BusinessInterfaceRenderClass } from './business.class'
import { BusinessInterfaceRenderArray } from './business.data'
import { NutsRenderArray } from './nuts.data'

@Injectable()
export class BusinessInterfaceRenderService {
  getReadableName(name: string): string {
    const business = this.getBusiness().filter((x) => x.api_name === name)[0]
    if (isNullOrUndefinedString(business)) {
      return name
    }
    return business.business_name
  }
  // transform Nuts Value to readable name for the API
  convertNutsToApiName(name: string): string {
    const business = this.getNuts().filter((x) => x.business_name === name)[0]
    if (isNullOrUndefinedString(business)) {
      return name
    }
    return business.api_name
  }

  getNutsTosuffix(name: string): string {
    const business = this.getNuts().filter((x) => x.business_name === name)[0]
    if (isNullOrUndefinedString(business)) {
      return name
    }
    return business.suffix
  }
  getBusiness(): BusinessInterfaceRenderClass[] {
    return BusinessInterfaceRenderArray
  }
  getNuts(): NutsRenderClass[] {
    return NutsRenderArray
  }
}
