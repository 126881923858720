<div
  [ngClass]="
    mapComponent.openedTabs[3] ? 'sidetab sidetabSelected' : 'sidetab '
  "
  (click)="openTab()"
>
  Scenario explorer
</div>

<div
  *ngIf="scenarioOpen"
  id="layertab"
  [ngClass]="
    mapComponent.openedTabs[3] ? 'layertabWrapper tabopen' : 'layertabWrapper'
  "
>
  <div class="header">
    <h3>Scenario explorer</h3>
    <p (click)="closeTab()">x</p>
  </div>

  <div class="scenarioContainer">
    <div *ngIf="apiScenarioRespond$ | async">
      <h4 class="uk-heading-bullet main_title input_title">Model</h4>
      <select
        [disabled]="isDataLoading$ | async"
        [disabled]="isModelDetailsLoading$ | async"
        class="uk-select"
        (change)="selectModel()"
        id="model-select"
        [(ngModel)]="modelSelected"
      >
        <option
          *ngFor="let model of modelsList"
          [value]="model"
          selected="modelSelected"
        >
          {{ model }}
        </option>
      </select>

      <h4 class="uk-heading-bullet main_title input_title">Definition</h4>
      <div *ngIf="isModelDetailsLoading$ | async; else modelOptions">
        <div class="loader" uk-spinner class="ukSpinnerModel"></div>
      </div>

      <ng-template #modelOptions>
        <div *ngIf="definition">
          <div *ngIf="definition.variables.length > 0">
            <label for="variable-select">Variable</label>
            <select
              class="uk-select"
              id="variable-select"
              [(ngModel)]="variableSelected"
              [disabled]="isDataLoading$ | async"
              (change)="clearMap()"
            >
              <option
                *ngFor="let variable of definition.variables"
                [value]="variable"
              >
                {{ variable }}
              </option>
            </select>
          </div>

          <div *ngIf="definition.regions.length > 0">
            <label for="region-select">Region scale</label>
            <select
              class="uk-select"
              id="region-select"
              [(ngModel)]="regionSelected"
              [disabled]="isDataLoading$ | async"
              (change)="clearMap()"
            >
              <option
                *ngFor="let region of definition.regions"
                [value]="region"
              >
                {{ region }}
              </option>
            </select>
          </div>

          <div *ngIf="definition.years.length > 0">
            <label for="year-select">Year</label>
            <select
              class="uk-select"
              id="year-select"
              [(ngModel)]="yearSelected"
              [disabled]="isDataLoading$ | async"
              (change)="clearMap()"
            >
              <option *ngFor="let year of definition.years" [value]="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>

        <button class="uk-button uk-button-primary" (click)="getData()">
          <div *ngIf="isDataLoading$ | async" class="loader" uk-spinner></div>
          Visualize data
        </button>

        <div
          *ngIf="showLegend$ | async"
          class="uk-card uk-card-default uk-card-body"
        >
          <h4 class="uk-card-title">Legend</h4>
          <div *ngFor="let element of legendContent" class="card-content">
            <div
              [ngStyle]="{ 'background-color': element.color }"
              class="legend"
            ></div>
            {{ element.label }}
          </div>
        </div>
      </ng-template>
    </div>

    <p class="errorMessage">
      {{ errorMessage$.getValue() }}
    </p>
    <button
      *ngIf="!(apiScenarioRespond$ | async)"
      class="uk-button uk-button-primary"
      (click)="loadModels()"
    >
      Retry
    </button>
  </div>
</div>
