import { NgIf } from '@angular/common'
import { Component, Input, OnInit, signal } from '@angular/core'
import { MatTooltipModule } from '@angular/material/tooltip'
import { AuthService } from '@core/services/auth.service'
import { Logger } from '@core/services/logger.service'
import { ToasterService } from '@core/services/toaster.service'
import { UploadService } from '@core/services/upload.service'
import { MapService } from '@pages/map/services/map.service'
import {
  calculation_module_category,
  hectare,
  lau2,
  nuts0,
  nuts1,
  nuts2,
  nuts3,
  urlLegend,
} from '@services/data.service'
import { DataInteractionArray } from 'app/layers'
import { DataInteractionClass } from '../../layers-interaction.class'

@Component({
  standalone: true,
  selector: 'htm-layer-tool',
  templateUrl: './layer-tool.component.html',
  styleUrls: ['./layer-tool.component.css'],
  imports: [NgIf, MatTooltipModule],
})
export class LayerToolComponent implements OnInit {
  @Input() dataInteraction: DataInteractionClass
  private _imageUrl = urlLegend
  isLegendDisplayed = false
  isInfoDisplayed = false
  private _displayLegend = false
  isLoggedIn = false

  loading: boolean = false
  hasZoneSelected: boolean = false
  calculation_module_name = calculation_module_category

  layerType$ = signal<string>('')
  workspaceName$ = signal<string>('')

  constructor(
    private _mapService: MapService,
    private _uploadService: UploadService,
    private _logger: Logger,
    private authService: AuthService,
    protected toasterService: ToasterService,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.authService.isLoggedIn()
    if (this.dataInteraction) {
      this.layerType$.set(
        this.dataInteraction.layer_type != undefined ? this.dataInteraction.layer_type : '',
      )
      this.workspaceName$.set(
        this.dataInteraction.workspaceName != undefined ? this.dataInteraction.workspaceName : '',
      )
    }

    // display download button according to the following rules
    if (this._mapService.getLoadResultbuttonState()) {
      this._mapService.getLoadResultbuttonState().subscribe((value) => {
        let availableScales = []
        if (this.dataInteraction.category === calculation_module_category) {
          availableScales = [nuts0, nuts1, nuts2, nuts3, lau2, hectare]
        } else if (this.dataInteraction.dataType) {
          if (this.dataInteraction.dataType === 'raster') {
            availableScales = [nuts3, lau2, hectare]
          } else if (this.dataInteraction.dataType === 'shp')
            availableScales = [nuts2, nuts3, lau2, hectare]
          else if (this.dataInteraction.dataType === 'csv')
            availableScales = this.dataInteraction.scales
              ? this.dataInteraction.scales
              : [nuts2, nuts3, lau2, hectare]
          else throw Error('This dataType is not supported.')
        } else {
          this._logger.log("This layer can't be downloaded: ", this.dataInteraction.name, 'error')
        }

        this.hasZoneSelected =
          value && availableScales.indexOf(this._mapService.getScaleValue()) > -1
      })
    }
  }

  toggleLegend() {
    this.isLegendDisplayed = !this.isLegendDisplayed
  }
  toggleInformations() {
    this.isInfoDisplayed = !this.isInfoDisplayed
  }

  endLoadLegend() {
    this._displayLegend = true
  }

  export() {
    this.loading = true
    this._uploadService.export(this.dataInteraction).then((data) => {
      if (data.url != '') {
        //window.open(data.url); //POPUP blocker
        const a = document.createElement('a')
        a.href = data.url
        a.download = data.filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(data.url)
      }
      this.loading = false
    })
  }

  async saveInPersonalLayer() {
    // No security for bad layer uploaded. If a bad layer is uploaded, you must go by hand in database to delete it in order to fix errors in frontend. This is a second security after hiding the button in frontend.
    if (
      this.dataInteraction.workspaceName == undefined ||
      this.dataInteraction.layer_type == undefined
    ) {
      this.toasterService.showDangerToaster('This layer type is not known')
      return
    }

    // Check that workspaceName define by CM developers exist in layer list
    let workspaceNameExists = DataInteractionArray.some(
      (layer) => this.dataInteraction.workspaceName === layer.workspaceName,
    )
    let layerTypeExists = DataInteractionArray.some(
      (layer) => this.dataInteraction.layer_type === layer.layer_type,
    )
    if (!workspaceNameExists || !layerTypeExists) {
      this._logger.log('This CM layer cannot be saved: ', this.dataInteraction, 'error')
      this.toasterService.showDangerToaster('This layer type is not known')
      return
    }

    this._uploadService.export(this.dataInteraction).then(async (data) => {
      // Transform the generate layer into a file
      let response = await fetch(data.url)
      let blob = await response.blob()

      // In order to use the CM session name as file name, must check type
      // the extension can be also found with data.filename
      // TODO: metadata seems not useful
      let metadata = {}
      let extension = ''
      if (this.dataInteraction.type_of_layer == 'raster') {
        metadata = {
          type: 'image/tiff',
        }
        extension = '.tif'
      } else if (this.dataInteraction.type_of_layer == 'vector') {
        metadata = {
          type: 'text/csv',
        }
        extension = '.csv'
      } else {
        this._logger.log('This CM layer type is not known: ', this.dataInteraction, 'error')
        this.toasterService.showDangerToaster('This layer type is not known')
        return
      }

      const file = new File([blob], this.dataInteraction.name + extension)

      const layerInfo = {
        workspaceName: this.dataInteraction.workspaceName,
        layer_type: this.dataInteraction.layer_type,
      }

      this._uploadService.add(file, 'false', layerInfo)
    })
  }
}
