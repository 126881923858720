// Values valid for dev/prod/local
const appName = 'citiwatts'
const continent = 'Europe'
const wikiUrl = 'https://wiki.hotmaps.eu'
const feedback = 'azure'
const enableLogin = true
const logoUrl = '/assets/first-page/citiwatts/citiwatts_logo_2024.png'
const partnersLogosUrl = '/assets/first-page/citiwatts/partners.png'
const favicon = '/assets/first-page/citiwatts/citiwatts_favicon.ico'
const websiteUrl = '' // ! "https://www.hotmaps-project.eu/" for test
const dataSetURl = 'https://www.gitlab.com/hotmaps'
const repositoryUrl = 'https://vlhtuleap.hevs.ch/plugins/git/git-eranet'
const keycloakRealm = 'citiwatts-dev'
const keycloakClientId = 'frontend'
const introText =
  '<b>Citiwatts</b> is the platform developed as part of the project <b>OpenGIS4ET</b> project. It aims to extend the H2020 Hotmaps platform, an open-source heating and cooling mapping and planning toolkit and to  provide default data for EU28 at national and local levels. These tools and data allow public authorities and energy planners to  identify, analyse, model and map resources and solutions to meet energy needs in their area of responsibility in an efficient and cost-effective manner. This project comes as an extension to add open  APIs to the H2020 Hotmaps platform, which started to develop a base toolbox and database. The platform reached TRL-7 and we would like to  add new plug-ins called calculation modules (CM). It helps authorities  to develop local, regional, and national heating, cooling, mobility,  and sector coupling flexibility strategies, that are in line with RES  and CO2 emission targets at national and European level. New developed  plug-ins and functionalities, will be demonstrated by need-owners to  reach TRL-8.'
const feedbackText =
  'We are happy to receive feedback on the tool. Please use the feedback-button in the toolbox to let us know your opinions and suggestions for the tool. Citiwatts also invites other developers to contribute with additional calculation modules or datasets that can be integrated in the toolbox. Please also get in contact with us at citiwatts@hevs.ch if you want to take part in the future development of the platform.'
const fundingText =
  'This project has received funding in the framework of the joint programming initiative ERA-Net Smart Energy Systems’ focus initiative Digital Transformation for the Energy Transition, with support from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 883973.'
const fundingImg = '/assets/first-page/citiwatts/ERA-Net.png'
const centerMap = [50, 11]
const zoom = 5
const prefixWorkspaceName = 'hotmaps:'
const nuts_CQL_FILTER = '(WITHIN(geom,polygon(('
const scaleRequest = [
  {
    label: 'NUTS 0',
    tooltip: 'Countries',
    option: {
      layers: 'hotmaps:' + 'nuts',
      format: 'image/png8',
      transparent: true,
      version: '1.3.0',
      cql_filter: 'stat_levl_ = ' + 0 + " AND year='2013-01-01'",
      srs: 'EPSG:4326',
      zIndex: 5,
    },
  },
  {
    label: 'NUTS 1',
    tooltip: 'Major socio-economic regions',
    option: {
      layers: 'hotmaps:' + 'nuts',
      format: 'image/png8',
      transparent: true,
      version: '1.3.0',
      cql_filter: 'stat_levl_ = ' + 1 + " AND year='2013-01-01'",
      srs: 'EPSG:4326',
      zIndex: 5,
    },
  },
  {
    label: 'NUTS 2',
    tooltip: 'Provincies or regions',
    option: {
      layers: 'hotmaps:' + 'nuts',
      format: 'image/png8',
      transparent: true,
      version: '1.3.0',
      cql_filter: 'stat_levl_ = ' + 2 + " AND year='2013-01-01'",
      srs: 'EPSG:4326',
      zIndex: 5,
    },
  },
  {
    label: 'NUTS 3',
    tooltip: 'Small regions or departments',
    option: {
      layers: 'hotmaps:' + 'nuts',
      format: 'image/png8',
      transparent: true,
      version: '1.3.0',
      cql_filter: 'stat_levl_ = ' + 3 + " AND year='2013-01-01'",
      srs: 'EPSG:4326',
      zIndex: 5,
    },
  },
  {
    label: 'LAU 2',
    tooltip: 'Municipalities or communes',
    option: {
      layers: 'hotmaps:' + 'tbl_lau1_2',
      format: 'image/png8',
      transparent: true,
      version: '1.3.0',
      zIndex: 5,
    },
  },
  {
    label: 'Hectare',
    option: {
      layers: 'hotmaps:' + 'nuts' + 0,
      format: 'image/png8',
      transparent: true,
      version: '1.3.0',
      cql_filter: 'stat_levl_ = ' + 1 + " AND year='2013-01-01'",
      srs: 'EPSG:4326',
      zIndex: 5,
    },
  },
]
const cmsId = [1, 2, 4, 5, 7, 8, 9, 11, 12, 13, 14, 16, 17, 18, 19, 20, 27]
const scenarioExplorer = ''

export const environmentCitiwattsBase = {
  production: false,
  debug: true,
  appName: appName,
  continent: continent,
  apiUrl: '', // will be completed during ng build or ng serve based on configuration
  geoserverUrl: '', // will be completed during ng build or ng serve based on configuration
  wikiUrl: wikiUrl,
  feedback: feedback,
  enableLogin: enableLogin,
  logoUrl: logoUrl,
  partnersLogosUrl: partnersLogosUrl,
  favicon: favicon,
  websiteUrl: websiteUrl,
  dataSetURl: dataSetURl,
  repositoryUrl: repositoryUrl,
  keycloakRealm: keycloakRealm,
  keycloakClientId: keycloakClientId,
  introText: introText,
  feedbackText: feedbackText,
  fundingText: fundingText,
  fundingImg: fundingImg,
  centerMap: centerMap,
  zoom: zoom,
  prefixWorkspaceName: prefixWorkspaceName,
  nuts_CQL_FILTER: nuts_CQL_FILTER,
  scaleRequest: scaleRequest,
  cmsId: cmsId,
  scenarioExplorer: scenarioExplorer,
}

if (!window['env']) {
  window['env'] = window['env'] || {}
  // Default environment variables (for tests)
  window['env']['debug'] = true
  window['env']['appName'] = appName
  window['env']['continent'] = continent
  window['env']['apiUrl'] = 'http://localhost:5000/api'
  window['env']['geoserverUrl'] = 'https://geoserver.citiwattsdev.hevs.ch/geoserver/hotmaps/wms'
  window['env']['wikiUrl'] = wikiUrl
  window['env']['feedback'] = feedback
  window['env']['enableLogin'] = enableLogin
  window['env']['logoUrl'] = logoUrl
  window['env']['partnersLogosUrl'] = partnersLogosUrl
  window['env']['favicon'] = favicon
  window['env']['websiteUrl'] = 'https://www.hotmaps-project.eu/'
  window['env']['dataSetURl'] = dataSetURl
  window['env']['repositoryUrl'] = repositoryUrl
  window['env']['introText'] = introText
  window['env']['fundingText'] = fundingText
  window['env']['fundingImg'] = fundingImg
  window['env']['centerMap'] = centerMap
  window['env']['zoom'] = zoom
  window['env']['prefixWorkspaceName'] = prefixWorkspaceName
  window['env']['nuts_CQL_FILTER'] = nuts_CQL_FILTER
  window['env']['scaleRequest'] = scaleRequest
  window['env']['cmsId'] || cmsId
  window['env']['scenarioExplorer'] || scenarioExplorer
}

export const environmentCitiwattsTest = {
  production: false,
  debug: window['env']['debug'] || true,
  appName: window['env']['appName'] || appName,
  continent: window['env']['continent'] || continent,
  apiUrl: window['env']['apiUrl'] || 'http://localhost:5000/api',
  geoserverUrl: window['env']['geoserverUrl'] || 'https://geoserver.localhost',
  wikiUrl: window['env']['wikiUrl'] || wikiUrl,
  enableFeedback: window['env']['feedback'] || feedback,
  enableLogin: window['env']['enableLogin'] || enableLogin,
  logoUrl: window['env']['logoUrl'] || logoUrl,
  partnersLogosUrl: window['env']['partnersLogosUrl'] || partnersLogosUrl,
  favicon: window['env']['favicon'] || favicon,
  websiteUrl: window['env']['websiteUrl'] || 'https://www.hotmaps-project.eu/',
  dataSetURl: window['env']['dataSetURl'] || dataSetURl,
  repositoryUrl: window['env']['repositoryUrl'] || repositoryUrl,
  introText: window['env']['introText'] || introText,
  fundingText: (window['env']['fundingText'] = fundingText),
  fundingImg: (window['env']['fundingImg'] = fundingImg),
  centerMap: window['env']['centerMap'] || centerMap,
  zoom: window['env']['zoom'] || zoom,
  prefixWorkspaceName: window['env']['prefixWorkspaceName'] || prefixWorkspaceName,
  nuts_CQL_FILTER: window['env']['prefixWorkspaceName'] || nuts_CQL_FILTER,
  scaleRequest: scaleRequest,
  cmsId: window['env']['cmsId'] || cmsId,
  scenarioExplorer: window['env']['scenarioExplorer'] || scenarioExplorer,
}
