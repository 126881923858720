import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { InteractionService } from '@core/services/interaction.service'
import { Logger } from '@core/services/logger.service'
import { SelectionScaleService } from '@core/services/selection-scale/selection-scale.service'
import { SummaryResultContainerComponent } from './summary-result-container/summary-result-container.component'
import { SummaryResultService } from './summary-result.service'

@Component({
  standalone: true,
  selector: 'htm-summary-result',
  templateUrl: 'summary-result.component.html',
  styleUrls: ['summary-result.component.css'],
  providers: [SummaryResultService],
  imports: [
    CommonModule,

    // Components
    SummaryResultContainerComponent,
  ],
})
export class SummaryResultComponent implements OnInit, OnChanges {
  // @Input() expanded: boolean;
  @Input() summaryResult
  @Input() refSelected
  // @Input() loadingData;
  // private buttonRef = default_drop_down_button;
  public scale

  constructor(
    private _logger: Logger,
    private _interactionService: InteractionService,
    private _selectionScaleService: SelectionScaleService,
  ) {}

  ngOnInit() {
    this._logger.log('SummaryResultComponent/ngOnInit : SummaryResult ', this.summaryResult)
  }
  ngOnChanges(changes: SimpleChanges) {
    this._logger.log('SummaryResultComponent/ngOnChanges : SummaryResult ', this.summaryResult)
    // scale is used to check if it's correspond to value of scenario explorer
    this.scale = this._selectionScaleService.getScaleValue().toLocaleLowerCase()
  }
}
