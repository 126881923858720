/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 19.07.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { timeOut } from '@services/data.service'
import { timeout } from 'rxjs/operators'
import { LoaderService } from './loader.service'
import { Logger } from './logger.service'
import { ToasterService } from './toaster.service'

export class APIService {
  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })

  constructor(
    protected http: HttpClient,
    protected logger: Logger,
    protected loaderService: LoaderService,
    protected toasterService: ToasterService,
  ) {
    this.http = http
    this.logger = logger
    this.loaderService = loaderService
    this.toasterService = toasterService
  }
  handleError(error: any) {
    this.loaderService.display(false)
    let message
    if (isNullOrUndefinedString(error.message)) {
      message = 'An internal error occured'
    } else {
      this.logger.log('APIService/handleError', error.message, 'error')
      message = error.message
      message = ', ' + message
      const status = error.status
      const statusText = error.statusText
      message = statusText + ' ' + message
    }

    this.toasterService.showDefaultErrorToaster(error.status)

    this.logger.log('api.service error', message, 'error')
    return Promise.reject(error.message || error)
  }

  POST(payload, url): Promise<any> {
    let options = {
      headers: this.headers,
    }
    return this.http
      .post(url, JSON.stringify(payload), options)
      .pipe(timeout(timeOut))
      .toPromise()
      .then((response) => response)
      .catch(this.handleError.bind(this))
  }

  POSTunStringify(payload, url, options?): Promise<any> {
    if (!options) {
      options = {
        headers: this.headers,
      }
    }
    return this.http
      .post(url, payload, options)
      .pipe(timeout(timeOut))
      .toPromise()
      .then((response) => response)
      .catch(this.handleError.bind(this))
  }

  GET(url): any {
    return this.http.get(url)
  }

  DELETE(url, options?) {
    if (!options) {
      options = {}
    }
    return this.http.delete(url, options)
  }

  async pGET(url): Promise<any> {
    return await this.http
      .get(url)
      .toPromise()
      .then((response) => response)

      .catch(this.handleError.bind(this))
  }

  public async getJSONFromFille(url: string): Promise<any> {
    return this.http
      .get(url)
      .toPromise()
      .then((response) => response)
  }
}
