import { NgFor, NgIf } from '@angular/common'
import { Component } from '@angular/core'
import { MatTooltipModule } from '@angular/material/tooltip'
import { appName } from '@services/data.service'
import { standaloneCMs } from './stand-alone-cm.data'

@Component({
  standalone: true,
  selector: 'app-stand-alone-cm',
  templateUrl: './stand-alone-cm.component.html',
  styleUrls: ['./stand-alone-cm.component.css', '../component/calculation-module.component.css'],
  imports: [NgIf, NgFor, MatTooltipModule],
})
export class StandAloneCmComponent {
  // temp fix for coollife cm
  standaloneCMs = standaloneCMs.filter((cms) => cms.project.includes(appName))
}
