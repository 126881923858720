<div class="container-panel-right" [@panelWidthTrigger]="expandedState" [hidden]="!expanded">
  <div class="title-panel-right">
    <a [@iconTrigger]="expandedState" class="link" (click)="toggleExpandedState()">
      <i class="flaticon-cross" aria-hidden="true"></i>
    </a>
    <span *ngIf="expanded" [@titleTextTrigger]="'in'">{{ title }}</span>
  </div>

  <div class="results-container">
    <htm-result-manager
      [personnalLayerPayload]="personnalLayerPayload"
      [summaryPayload]="summaryPayload"
      [cmPayload]="cmPayload"
      [energyMixPayload]="energyMixPayload"
      [heatLoadPayload]="heatLoadPayload"
      [durationCurvePayload]="durationCurvePayload"
      [scaleLevel]="scaleLevel"
      [scenarioExplorerData]="scenarioResultsSelected"
    ></htm-result-manager>
  </div>
</div>
