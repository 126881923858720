<div [class.isLoggedIn]="isLoggedIn" [class.isLoggedOut]="!isLoggedIn" class="userWrapper">
  <!-- Show login button if not logged in -->
  <a *ngIf="!isLoggedIn" (click)="login()" class="uk-icon-link">
    <button
      uk-icon="user"
      onclick="_paq.push(['trackEvent', 'Login', 'Login Button Click', 'user try to login']);"
    ></button>
  </a>

  <!-- Show user initials if logged in -->
  <a *ngIf="isLoggedIn" href="#modal-account" class="uk-icon-link" uk-toggle>
    <span class="link-right">{{ username.substring(0, 2) }}</span>
  </a>
  <!-- TODELETE debug Subdiv -->
  <!-- <hr/>
  <div>
    <a (click)="debug()">DEBUGADMINLOL</a>
  </div> -->
</div>

<!-- Modal for user info and account options -->
<div id="modal-account" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <!-- Information Subdiv -->
    <div>
      <h3>Information</h3>
      <p><strong>Username:</strong> {{ username }}</p>
      <p><strong>Email:</strong> {{ email }}</p>
      <a href="#modal-gdpr" uk-toggle (click)="$event.preventDefault()">Privacy Policy</a>
    </div>

    <!-- Edit Account Subdiv -->
    <div>
      <a (click)="editAccount()">Edit account</a>
    </div>

    <!-- Logout Subdiv -->
    <div>
      <a (click)="logout()">Logout</a>
    </div>

    <!-- GDPR Modal -->
    <div id="modal-gdpr" uk-modal>
      <div class="uk-modal-dialog uk-modal-body privacy-policy-modal">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <htm-gdpr></htm-gdpr>
      </div>
    </div>
  </div>
</div>
