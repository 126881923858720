import { animate, state, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { InteractionService } from '@core/services/interaction.service'
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha'
import { issue_levels, issue_type } from '../data-issue'
import { FeedbackService } from '../feedback.service'

@Component({
  standalone: true,
  selector: 'htm-feedback',
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.css'],
  animations: [
    trigger('feedbackTrigger', [
      state('expanded', style({ opacity: 1 })),
      state('collapsed', style({ opacity: 0 })),
      transition('collapsed => expanded', animate('200ms 150ms linear')),
      transition('expanded => collapsed', animate('100ms linear')),
    ]),
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule],
})
export class FeedbackComponent implements OnInit {
  @Input() expandedState: 'expanded' | 'collapsed'
  issue_levels = issue_levels
  issue_type = issue_type
  feedbackForm: FormGroup
  fileName: string = ''

  @Output() closeForm = new EventEmitter<void>()

  @ViewChild('file_input') file_input: ElementRef
  submitted = false
  submitMessage = {
    msg: '',
    success: false,
  }
  feedbackLoader = false
  captcha: string | null = null
  captchaError = false

  constructor(
    private fb: FormBuilder,
    private _interactionService: InteractionService,
    private _feedbackService: FeedbackService,
  ) {}

  ngOnInit() {
    this.createForm()
  }

  createForm() {
    this.feedbackForm = this.fb.group({
      name: [''],
      mail: ['', [Validators.required, Validators.email]],
      company: [''],
      type: [null, Validators.required],
      level: [null, Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      isAccepted: [false, Validators.requiredTrue],
      file: [null],
    })
  }

  onUploadFile(event: Event) {
    const input = event.target as HTMLInputElement
    if (input.files && input.files[0]) {
      const file = input.files[0]
      if (this.isImage(file)) {
        this.fileName = file.name
        this.feedbackForm.patchValue({ file })
      } else {
        this._interactionService.showDangerToaster('Uploaded file is not a valid image.')
      }
    }
  }

  showError() {
    this._interactionService.showDangerToaster(
      'Unable to send the issue! Please, try later or send a mail to administrator',
    )
  }

  sendFeedback() {
    if (this.feedbackForm.valid && this.captcha) {
      const fd = new FormData()
      const formValue = this.feedbackForm.value

      if (formValue.file) {
        fd.append('file', formValue.file, this.removeSpecialCharacters(formValue.file.name))
      }

      fd.append('firstname', formValue.name)
      fd.append('email', formValue.mail)
      fd.append('company', formValue.company)
      fd.append('feedback_type', formValue.type.name)
      fd.append('feedback_priority', formValue.level.name)
      fd.append('title', formValue.title)
      fd.append('description', formValue.description)
      fd.append('captcha', this.captcha)

      this.feedbackLoader = true

      this._feedbackService
        .sendFeedback(fd)
        .then((val) => {
          this.feedbackLoader = false
          this._interactionService.showToaster(val.message)
          this.submitMessage.msg =
            'Your request has been successfully sent to administrators.<br /> Thanks Citiwattser!'
          this.submitMessage.success = true
          this.resetForm()
        })
        .catch(() => {
          this.showError()
          this.feedbackLoader = false
          this.submitMessage.msg =
            'Something went wrong.<br/>Please try to send a new request or contact an administrator'
          this.submitMessage.success = false
        })
    } else {
      this.captchaError = true
    }
  }

  resetForm() {
    this.feedbackForm.reset({
      name: '',
      mail: '',
      company: '',
      type: null,
      level: null,
      title: '',
      description: '',
      isAccepted: false,
      file: null,
    })
    this.fileName = ''
    this.captcha = null
    this.captchaError = false
  }

  removeSpecialCharacters(str: string) {
    return str.replace(/[^a-zA-Z0-9\s._-]/g, '')
  }

  isImage(file) {
    const mimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp']
    return mimeTypes.includes(file.type)
  }

  onCaptchaResolved(captchaResponse: string) {
    this.captcha = captchaResponse
    this.captchaError = false
  }

  onCaptchaExpired() {
    this.captcha = null
    this.captchaError = true
  }

  close(event: Event) {
    event.preventDefault()
    this.closeForm.emit()
  }
}
