import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { LoaderService } from '@core/services/loader.service'
import { Logger } from '@core/services/logger.service'
import { environment } from 'environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
  /**
   * management of initial status of general loading
   * AppComponent own general loading
   */
  showLoader: boolean
  isMap: boolean = true
  private _isIE: boolean = false
  favIcon: HTMLLinkElement = document.querySelector('#favicon')

  constructor(
    private _loaderService: LoaderService,
    private _logger: Logger,
    private _router: Router,
    private titleService: Title,
  ) {
    //Title and favicon add dynamically
    titleService.setTitle(environment.appName + ' - Toolbox')
    this.favIcon.href = environment.favicon
  }

  ngAfterContentInit(): void {
    this.notifyLoaderService()
  }
  /**
   * subcribe the status of the loader service
   * AppComponent own general loading
   */
  notifyLoaderService() {
    this._loaderService.getStatus().subscribe((val: boolean) => {
      this.showLoader = val
    })
  }

  ngOnDestroy() {
    this._logger.log('AppComponent/ngOnDestroy')
  }

  ngOnInit() {
    this._isIE = /msie\s|trident\//i.test(window.navigator.userAgent)
    // This is not working :(
    if (this._isIE) {
      this._router.navigate(['disclaimer_browser'])
    }

    this._router.events.subscribe(
      (val) => (this.isMap = (val as any).url === '/' || (val as any).url === '/map'),
    )

    if (this._router.url === '/register') {
      // console.log('isRegisterRoute')
    }
  }
}
