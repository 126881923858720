import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Helper } from '@core/services/helper'
import { APIService } from '@services/api.service'
import {
  apiUrl,
  postHeatLoadProfileHectares,
  postHeatLoadProfileNutsLau,
} from '@services/data.service'
import { LoaderService } from '@services/loader.service'
import { Logger } from '@services/logger.service'
import { ToasterService } from '@services/toaster.service'
import { BehaviorSubject } from 'rxjs'
import { DatasetChart } from '../chart/chart'

@Injectable()
export class HeatLoadAggregateService extends APIService {
  private _multiDatasets: DatasetChart[] = [
    { label: 'Min', data: [], borderColor: '#2889DF', fill: false },
    { label: 'Max', data: [], borderColor: '#2889DF', fill: false },
    { label: 'Average', data: [], borderColor: '#d94f5c', fill: false },
  ]
  private _singleDataset: DatasetChart[] = [
    { label: 'Value', data: [], borderColor: '#2889DF', fill: false },
  ]
  private _formattedValues = []
  private heatLoadData = new BehaviorSubject<any>(null)

  private _labels = []
  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService,
    private _helper: Helper,
  ) {
    super(http, logger, loaderService, toasterService)
  }
  setHeatLoadData(data) {
    this.heatLoadData.next(data)
  }
  getHeatLoadData() {
    return this.heatLoadData
  }

  getHeatLoad(payload, type_api_ref, isHectare) {
    if (isHectare === false) {
      this.logger.log(
        'heat-load.service/getHeatLoad',
        'postHeatLoadProfileNutsLau ' +
          apiUrl +
          postHeatLoadProfileNutsLau +
          '/' +
          type_api_ref +
          ' ; payload:' +
          JSON.stringify(payload),
      )
      return super.POST(payload, apiUrl + postHeatLoadProfileNutsLau)
    } else {
      this.logger.log(
        'heat-load.service/getHeatLoad',
        'postHeatLoadProfileHectares ' +
          apiUrl +
          postHeatLoadProfileHectares +
          type_api_ref +
          ' ; payload:' +
          JSON.stringify(payload),
      )
      this.logger.log('heat-load.service/getHeatLoad', apiUrl + ' ' + postHeatLoadProfileHectares)
      return super.POST(payload, apiUrl + postHeatLoadProfileHectares)
    }
  }

  heatLoadMultiDataset(data) {
    data.values.map((value) => {
      this._multiDatasets[0].data.push(value.min)
      this._multiDatasets[1].data.push(value.max)
      this._multiDatasets[2].data.push(value.average)
    })
    this._formattedValues.push(this._multiDatasets)
  }
  heatLoadSingleDataset(data) {
    data.values.map((value) => {
      this._singleDataset[0].data.push(value.value)
    })
    this._formattedValues.push(this._singleDataset)
  }
  heatLoadLabelsYear(data) {
    data.values.map((value) => {
      this._labels.push(this._helper.getMonthString(value.month, 0))
    })
  }
  heatLoadLabelsMonth(data) {
    data.values.map((value) => {
      this._labels.push(value.granularity + ' ' + value.day)
    })
  }
  heatLoadLabelsDay(data) {
    data.values.map((value) => {
      this._labels.push(value.granularity + ' ' + value.hour_of_day)
    })
  }
  formatDataset() {
    this._multiDatasets[0].data = []
    this._multiDatasets[1].data = []
    this._multiDatasets[2].data = []
    this._singleDataset[0].data = []
    this._labels = []
    this._formattedValues = []
  }
  formatHeatLoadForChartjs(data, api_ref) {
    this.formatDataset()
    if (api_ref === 'day') {
      this.heatLoadLabelsDay(data)
      this.heatLoadSingleDataset(data)
    } else if (api_ref === 'month') {
      this.heatLoadLabelsMonth(data)
      this.heatLoadMultiDataset(data)
    } else if (api_ref === 'year') {
      this.heatLoadLabelsYear(data)
      this.heatLoadMultiDataset(data)
    }
    this._formattedValues.push(this._labels)
    return this._formattedValues
  }
}
