<div class="title-map" [ngClass]="{ 'title-map-dev-mode': isDevOrLocalhost }">
  <div class="menuLeft">
    <div class="toolbox-logo">
      <a href="{{ websiteUrl }}" target="_blank" *ngIf="websiteUrl !== ''">
        <img [src]="this.logoUrl" />
      </a>
      <img [src]="this.logoUrl" *ngIf="websiteUrl == ''" />
      <button
        class="info-icon"
        uk-icon="icon: info;"
        style="position: relative; color: #222"
        (click)="setProjectInfoOpen()"
        matTooltip="Project information"
        matTooltipPosition="below"
      ></button>
      <a
        class="dev-mode-title"
        *ngIf="isDevOrLocalhost !== ''"
        matTooltip="You are on the development version of the platform. Your data may be deleted at any time."
        matTooltipPosition="below"
        >{{ isDevOrLocalhost }} platform
        <span style="position: relative; top: -2px" uk-icon="icon: info;"></span
      ></a>
    </div>
    <app-file-management></app-file-management>
  </div>
  <hmt-search-bar></hmt-search-bar>
  <div class="menuRight">
    <div class="title-header" *ngIf="wikiUrl !== ''">
      <a href="{{ wikiUrl }}" (click)="(gaWiki)" target="_blank">
        <span>Wiki</span>
      </a>
    </div>

    <div class="title-header">
      @if (feedback == 'azure') {
        <a (click)="openFeedback()">
          <span>Feedback</span>
        </a>
      } @else if (feedback == '') {
        <a matTooltip="In development" matTooltipPosition="below" class="link-disabled">
          <span>Feedback</span>
        </a>
      } @else {
        <a href="https://ec.europa.eu/eusurvey/runner/CoolLIFEToolbox" target="_blank">Survey</a>
      }
    </div>
    <htm-user-management *ngIf="enableLogin; else disableLogin"></htm-user-management>
    <ng-template #disableLogin>
      <div class="title-header">
        <a matTooltip="In development" matTooltipPosition="below" class="link-disabled">
          <span>Login</span>
        </a>
      </div>
    </ng-template>
  </div>
</div>

<htm-feedback
  [expandedState]="feedBackExpanded ? 'expanded' : 'collapsed'"
  (closeForm)="closeFeedback()"
></htm-feedback>

<div class="sideMenu">
  <app-toolstab [toolsOpened]="openedTabs[0]" [selectionSurface]="selectionSurface"></app-toolstab>
  <app-layerstab *ngIf="isLayerTabVisible" [layersOpened]="openedTabs[1]"></app-layerstab>
  <app-cmtab
    *ngIf="isCMTabVisible"
    [selectionSurface]="selectionSurface"
    [scaleLevel]="scaleLevel"
    [cmsOpen]="openedTabs[2]"
  ></app-cmtab>
  <app-scenariotab *ngIf="isScenarioTabVisible" [scenarioOpen]="openedTabs[3]"></app-scenariotab>
</div>

<div class="container-map">
  <div id="map" [class.click-disable]="isCMRunning"></div>

  <htm-right-side-panel
    [hidden]="!openRightSidebar"
    [nutsIds]="nutsIds"
    [layers]="layers"
    [personnalLayers]="personnalLayers"
    [scaleLevel]="scaleLevel"
    [locationsSelection]="locationsSelection"
    [areas]="areas"
  >
  </htm-right-side-panel>
</div>
