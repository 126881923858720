<div>
  <h4>Import CM inputs</h4>
  <p>
    If you used the "export indicator" function after running the CM, you may now upload the
    obtained .csv file to reuse the same inputs.
  </p>
  <input
    #csvFileInput
    type="file"
    id="csvFileInput"
    (change)="handleFileInput($event)"
    accept=".csv"
  />
  <button class="uk-button uk-button-secondary" (click)="importInputs()" [disabled]="!csvFile">
    Import inputs (CSV)
  </button>
</div>
