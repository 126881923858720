<span>
  <div class="uk-card uk-card-body htm-card">
    <table class="uk-table uk-table-divider uk-table-small">
      <thead>
        <tr class="header-summary">
          <th class="uk-table-shrink">Information</th>
          <th class="uk-table-shrink">Value</th>
        </tr>
      </thead>
      <ng-container *ngIf="summaryResult.summaryResult">
        <tbody
          htm-summary-result-container
          [results]="summaryResult.summaryResult"
          [refSelected]="refSelected"
        ></tbody>
      </ng-container>
      <ng-container *ngIf="summaryResult.cmResult">
        <tbody
          htm-summary-result-container
          [results]="summaryResult.cmResult"
          [refSelected]="refSelected"
        ></tbody>
      </ng-container>
      <ng-container *ngIf="summaryResult.personnalLayerResult">
        <tbody
          htm-summary-result-container
          [results]="summaryResult.personnalLayerResult"
          [refSelected]="refSelected"
        ></tbody>
      </ng-container>
      <ng-container *ngIf="summaryResult.scenarioResult">
        <tbody
          *ngIf="
            summaryResult.scenarioResult.scenarioParameters.region == scale;
            else noScenarioResults
          "
          htm-summary-result-container
          [results]="summaryResult.scenarioResult"
          [refSelected]="refSelected"
        ></tbody>
        <ng-template #noScenarioResults>
          <tbody>
            There is no data for the selected region. Please ensure that the
            region scale is the same as the one in the scenario panel.
          </tbody>
        </ng-template>
      </ng-container>
    </table>
  </div>
</span>
