import { NgClass, NgIf } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { MapComponent } from '@pages/map/map.component'
import { SelectionToolComponent } from './selection-tools/component/selection-tool.component'
import { SnapshotComponent } from './snapshot/snapshot.component'

@Component({
  standalone: true,
  selector: 'app-toolstab',
  templateUrl: './toolstab.component.html',
  styleUrls: ['./toolstab.component.css'],
  imports: [
    NgIf,
    NgClass,

    // Components
    SnapshotComponent,
    SelectionToolComponent,
  ],
})
export class ToolstabComponent implements OnInit {
  @Input() selectionSurface
  @Input() toolsOpened: boolean

  constructor(public mapComponent: MapComponent) {}

  ngOnInit(): void {}

  openTab(): void {
    this.toolsOpened = !this.toolsOpened
    if (this.toolsOpened) {
      this.mapComponent.setToolsOpened()
    } else {
      this.mapComponent.setTabsClosed()
    }
  }

  closeTab(): void {
    this.toolsOpened = false
    this.mapComponent.setTabsClosed()
  }
}
