// Values valid for dev/prod/local
export const environmentOM4ABase = {
  production: false,
  debug: true,
  appName: 'openMod4Africa',
  continent: 'Africa',
  apiUrl: '', // will be completed during ng build or ng serve based on configuration
  geoserverUrl: '', // will be completed during ng build or ng serve based on configuration
  wikiUrl: '',
  feedback: '',
  enableLogin: true,
  logoUrl: '/assets/projects/om4a/openmod4africa_logo.png',
  partnersLogosUrl: '',
  favicon: '/assets/projects/om4a/OM4A-favicon.ico',
  websiteUrl: 'https://openmod4africa.eu/',
  dataSetURl: '',
  repositoryUrl: 'https://vlhtuleap.hevs.ch/plugins/git/git-eranet',
  keycloakRealm: 'openmod4africa-dev',
  keycloakClientId: 'frontend',
  introText:
    'OpenMod4Africa aims to develop an open Toolbox populated with state-of-the-art models for analysing long-term pathways to sustainable, secure and competitive energy systems in Africa. The Toolbox will build on EU projects like Open ENTRANCE, Plan4RES and FocusAfrica, and will adapt and further develop open models in accordance with the African context and needs. The models are scalable, and can be applied to cities, industries and countries. Furthermore, a main objective for OpenMod4Africa is capacity building among energy models in academia. Four African universities will be actively involved in adapting models and conducting two regional case studies. The additional capacity and the open Toolbox will enable the universities to train new generations of energy modelling experts for the energy industries in Africa. Three decision-makers will also be engaged, together with a network of energy industries and universities in 25 African countries. These players will be invited to use the Toolbox, and to be involved in training activities. They will also be invited to a permanent network of expertise, which will be developed for further capacity building and collaboration beyond the project. Two case studies will develop energy pathways for rural areas, cities, countries and large regions of countries in Western and Eastern Africa. The replication strategies will pave the way for further analyses beyond the project. Finally, OpenMod4Africa aims to collaborate with other ongoing initiatives to maximize the impacts of the project and create synergies. Th consortium consists of 17 partners, eight of which are African. Important long-term impacts from the three-year project include enabling academia and decision-makers in Africa to conduct their own analyses for the optimal development of their energy system, supplying energy to a much larger share of the population, and establishing a system based upon the abundant share of renewables on the continent.',
  feedbackText:
    'We are happy to receive feedback on the tool. Please use the feedback-button in the toolbox to let us know your opinions and suggestions for the tool. OpenMod4Africa also invites other developers to contribute with additional calculation modules or datasets that can be integrated in the toolbox. Please also get in contact with us at dev.easilab@hevs.ch if you want to take part in the future development of the platform.',
  fundingText: '',
  fundingImg: '/assets/projects/om4a/OM4A-funding.png',
  centerMap: [2, 20],
  zoom: 4,
  prefixWorkspaceName: 'openmod:',
  nuts_CQL_FILTER: '(WITHIN(wkb_geometry,polygon((',
  scaleRequest: [
    {
      label: 'Region',
      option: {
        layers: 'openmod:regions',
        format: 'image/png8',
        transparent: true,
        version: '1.3.0',
        cql_filter: '',
        srs: 'EPSG:4326',
        zIndex: 5,
      },
    },
    {
      label: 'Country',
      option: {
        layers: 'openmod:boundaries0',
        format: 'image/png8',
        transparent: true,
        version: '1.3.0',
        cql_filter: '',
        srs: 'EPSG:4326',
        zIndex: 5,
      },
    },
    {
      label: 'Sub-division',
      option: {
        layers: 'openmod:boundaries1',
        format: 'image/png8',
        transparent: true,
        version: '1.3.0',
        cql_filter: '',
        srs: 'EPSG:4326',
        zIndex: 5,
      },
    },
    {
      label: 'Hectare',
      option: {
        layers: 'hotmaps:' + 'nuts' + 0,
        format: 'image/png8',
        transparent: true,
        version: '1.3.0',
        cql_filter: '',
        srs: 'EPSG:4326',
        zIndex: 5,
      },
    },
  ],
  cmsId: [],
  scenarioExplorer: 'openmod4africa_internal',
}
