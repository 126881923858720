import { hectare, lau2, nuts0, nuts1, nuts2, nuts3 } from '@services/data.service'
import { NutsRenderClass } from './NutsRenderClass'
export const NutsRenderArray: NutsRenderClass[] = [
  { id: 0, api_name: '0', business_name: nuts0, suffix: '' },
  { id: 1, api_name: '1', business_name: nuts1, suffix: '' },
  { id: 2, api_name: '2', business_name: nuts2, suffix: '' },
  { id: 3, api_name: '3', business_name: nuts3, suffix: '' },
  { id: 4, api_name: '4', business_name: lau2, suffix: '' },
  { id: 5, api_name: '-1', business_name: hectare, suffix: '' },
]
