import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  standalone: true,
  name: 'filterLayers',
})
export class FilterLayersPipe implements PipeTransform {
  transform(items: any[], refSelected: any) {
    if (items) return items.filter((item) => item.category.indexOf(refSelected) > -1)
  }
}
