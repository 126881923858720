<ul uk-accordion>
  <li class="uk-open">
    <h3 class="uk-accordion-title">Share layer</h3>
    <div class="uk-accordion-content" style="position: relative; width: 100%">
      <ng-container *ngFor="let layer of layers">
        <div class="layers" (click)="actionLayer(layer)">
          <button
            type="button"
            class="uk-button uk-button-default uk-width-1-1"
            [class.button-secondary]="layer.checked"
          >
            <input class="uk-checkbox" type="checkbox" [checked]="layer.checked" /><span>{{
              layer.name
            }}</span>
          </button>
        </div>
      </ng-container>
    </div>
  </li>
</ul>
