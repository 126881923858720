import { CommonModule, DecimalPipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { FeedbackService } from '@components/_header-bar/feedback/feedback.service'
import { MailService } from '@components/_header-bar/feedback/mail.service'
import { GeocodingService } from '@components/_header-bar/searchbar/geocoding.service'
import { UserManagementStatusService } from '@components/_header-bar/user-management/service/user-management-status.service'
import { UserManagementService } from '@components/_header-bar/user-management/service/user-management.service'
import { CMLayersService } from '@components/_panel-left/cmtab/calculation-module/cm-layers.service'
import { ImportCmInputsService } from '@components/_panel-left/cmtab/calculation-module/import-cm-inputs/service/import-cm-inputs.service'
import { CalculationModuleStatusService } from '@components/_panel-left/cmtab/calculation-module/service/calculation-module-status.service'
import { CalculationModuleService } from '@components/_panel-left/cmtab/calculation-module/service/calculation-module.service'
import { CalculationHeatLoadDividedService } from '@components/_panel-left/cmtab/calculation-module/service/calculation-test.service'
import { DataInteractionService } from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.service'
import { SelectionToolButtonStateService } from '@components/_panel-left/toolstab/selection-tools/service/selection-tool-button-state.service'
import { SelectionToolUtils } from '@components/_panel-left/toolstab/selection-tools/service/selection-tool-utils.service'
import { SelectionToolService } from '@components/_panel-left/toolstab/selection-tools/service/selection-tool.service'
import { SnapshotService } from '@components/_panel-left/toolstab/snapshot/snapshot.service'
import { ElectricityMixService } from '@components/_panel-right/resultstab/result-manager/electricity-mix/service/electricity-mix.service'
import { ExportDataService } from '@components/_panel-right/resultstab/result-manager/export-data/export-data.service'
import { SummaryResultService } from '@components/_panel-right/resultstab/result-manager/summary-result/summary-result.service'
import { SideComponent } from '@components/_panel-right/side-panel/side-panel.component'
import { SidePanelService } from '@components/_panel-right/side-panel/side-panel.service'
import { DurationCurveService } from '@components/duration-curve.notUsed/duration-curve.service'
import { HeatLoadAggregateService } from '@components/heat-load.notUsed/heat-load.service'
import { BusinessInterfaceRenderService } from '@core/services/business/business.service'
import { Helper } from '@core/services/helper'
import { InteractionService } from '@core/services/interaction.service'
import { PopulationService } from '@core/services/population/services/population.service'
import { SelectionScaleService } from '@core/services/selection-scale/selection-scale.service'
import { MapService } from '@pages/map/services/map.service'
import { UppercaseFirstLetterPipe } from '@pipes/uppercase-first-letter.pipe'
import { keycloakClientId, keycloakRealm, keycloakUrl } from '@services/data.service'
import { GlobalErrorHandler } from '@services/error-handler'
import { LayersService } from '@services/layers/services/layers.service'
import { LoaderService } from '@services/loader.service'
import { Logger } from '@services/logger.service'
import { ToasterService } from '@services/toaster.service'
import { UploadService } from '@services/upload.service'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
import { RecaptchaLoaderService } from 'ng-recaptcha'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app.routes'

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: keycloakUrl,
        realm: keycloakRealm,
        clientId: keycloakClientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      shouldAddToken: (request) => {
        const { method, url } = request

        const isGetRequest = 'GET' === method.toUpperCase()
        const acceptablePaths = ['/geoserver']
        const isAcceptablePathMatch = acceptablePaths.some((path) => url.includes(path))

        return !(isGetRequest && isAcceptablePathMatch)
      },
    })
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, UppercaseFirstLetterPipe, SideComponent],
  providers: [
    DataInteractionService,
    PopulationService,
    GeocodingService,
    MapService,
    Logger,
    LoaderService,
    ToasterService,
    GlobalErrorHandler,
    Helper,
    SidePanelService,
    LayersService,
    SelectionToolService,
    SelectionToolButtonStateService,
    BusinessInterfaceRenderService,
    SummaryResultService,
    DecimalPipe,
    MailService,
    SelectionScaleService,
    InteractionService,
    HeatLoadAggregateService,
    SelectionToolUtils,
    ExportDataService,
    ImportCmInputsService,
    DurationCurveService,
    ElectricityMixService,
    CalculationModuleService,
    CalculationModuleStatusService,
    CalculationHeatLoadDividedService,
    CMLayersService,
    UserManagementService,
    UserManagementStatusService,
    SnapshotService,
    FeedbackService,
    UploadService,
    RecaptchaLoaderService,

    //Keycloak
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  imports: [
    // Angular Modules
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // HTTP
    HttpClientModule,

    // Routing
    RouterModule,
    AppRoutingModule,

    //Keycloak
    KeycloakAngularModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
