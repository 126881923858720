<div class="uk-padding">
  <span class="logo-container">
    <div class="logo">
      <img [src]="this.logoUrl" alt="Logo" />
    </div>

    <div class="partners">
      @if (partnersLogosUrl != '') {
        <img [src]="partnersLogosUrl" alt="Partner's logos" />
      }
    </div>
  </span>

  <p class="intro-text" [innerHTML]="introText"></p>

  @if (websiteUrl) {
    <p class="website-text">
      More information on the project can be found here:
      <a [href]="websiteUrl" target="_blank">{{ websiteUrl }}</a>
    </p>
  }

  @if (feedback) {
    <p class="feedback-text" [class.border-feedback-text]="appName == 'CoolLIFE'">
      <span [innerHTML]="feedbackText"></span>
    </p>
  }

  @if (fundingText) {
    <strong>Funding</strong>
    <span class="funding">
      <span class="main-funding">
        <img [src]="fundingImg" alt="Logo" />
        <span class="funding-text">
          {{ fundingText }}
        </span>
      </span>

      @if (appName == 'citiwatts') {
        <span class="other-funding">
          <span class="funding-with-legend">
            <img [src]="'/assets/first-page/citiwatts/funding/eudp.png'" alt="EUDP" />
            <span> Project Nr. 64021-6026</span>
          </span>
          <span class="funding-with-legend">
            <img [src]="'/assets/first-page/citiwatts/funding/FFG.png'" alt="FFG" />
            Project Nr. 889031
          </span>
          <span class="funding-with-legend">
            <img [src]="'/assets/first-page/citiwatts/funding/conf swiss.png'" alt="Swiss" />
            Project Nr. 502364
          </span>
          <span class="funding-with-legend">
            <img [src]="'/assets/first-page/citiwatts/funding/PtJ.png'" alt="" />
            Project Nr. 03EI4050B
          </span>
        </span>
      }
    </span>
  }

  @if (wikiUrl) {
    <strong>Wiki:</strong> For details about tool functionalities as well as the manual for using
    the calculation modules, please refer to the Wiki:
    <a [href]="wikiUrl" target="_blank">{{ wikiUrl }}</a>
  }

  <p>
    <strong>Open source:</strong> The developed tool and all related modules run without requiring
    any other commercial tool or software. Use of and access to Source Code is subject to Open
    Source Licenses.
    <span *ngIf="dataSetURl != ''">
      The datasets that are used in the tool can be found at
      <a [href]="dataSetURl" target="_blank">{{ dataSetURl }}</a
      >.
    </span>
    <span *ngIf="repositoryUrl != ''">
      The source code of the tool can be accessed via
      <a [href]="repositoryUrl" target="_blank">{{ repositoryUrl }}</a
      >.
    </span>
  </p>

  <p>
    <strong>Disclaimer:</strong> The data and tools provided on this website are indicative and for
    research purposes only. No responsibility is taken for the accuracy of included data, figures
    and results or for using them for unintended purposes.
    <span *ngIf="isDevOrLocalhost !== ''">
      <p class="dev-mode">
        <span class="dev-mode-title">{{ isDevOrLocalhost }} platform :</span>
        You are on the development version of the platform. Your data may be deleted at any time.
      </p>
    </span>
  </p>

  <p>
    <strong>Supported browser:</strong>
    <img [src]="'/assets/first-page/chrome.png'" alt="Chrome" class="browser" /> Google Chrome,
    <img [src]="'/assets/first-page/firefox.png'" alt="Firefox" class="browser" /> Firefox,
    <img [src]="'/assets/first-page/Safari.png'" alt="Safari" class="browser" />
    Safari
  </p>

  <span class="go-to-app-button">
    <button class="uk-button uk-button-primary" type="button" (click)="setProjectInfoClose()">
      Go to app
    </button>
  </span>
</div>
