import { animate, state, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { InteractionService } from '@core/services/interaction.service'
import { MapService } from '@pages/map/services/map.service'
import { calculation_module_category, layers_order } from '@services/data.service'
import { DataInteractionClass } from '../layers-interaction.class'
import { LayerToolComponent } from './layer-tool/layer-tool.component'
@Component({
  standalone: true,
  selector: 'layers-interaction-cell',
  templateUrl: 'layers-interaction-cell.component.html',
  styleUrls: ['layers-interaction-cell.component.css'],
  animations: [
    // Define an animation that adjusts the opactiy when a new item is created
    //  in the DOM. We use the 'visible' string as the hard-coded value in the
    //  trigger.
    //
    // When an item is added we wait for 300ms, and then increase the opacity to 1
    //  over a 200ms time interval. When the item is removed we don't delay anything
    //  and use a 200ms interval.
    //
    trigger('visibleTrigger', [
      state('visible', style({ opacity: '1' })),
      transition('void => *', [style({ opacity: '0' }), animate('200ms 300ms')]),
      transition('* => void', [animate('200ms', style({ opacity: '0' }))]),
    ]),
  ],
  imports: [
    CommonModule,

    // Components
    LayerToolComponent,
  ],
})
export class DataInteractionCellComponent {
  @Input() dataInteraction: DataInteractionClass
  @Input() expanded: boolean
  private _loading = true

  private _cm_cat = calculation_module_category
  constructor(
    private _mapService: MapService,
    public interactionService: InteractionService,
  ) {}
  endLoad() {
    this._loading = false
  }
  showRemoveLayer(e) {
    if (this.interactionService.getSummaryResultState() === false) {
      this.dataInteraction.isSelected = !this.dataInteraction.isSelected
      if (this.dataInteraction.category === calculation_module_category) {
        this._mapService.displayCustomLayerFromCM(
          this.dataInteraction.cm_id,
          this.dataInteraction.type_of_layer,
        )
      } else {
        let order = layers_order
        if (this.dataInteraction.order) {
          order = this.dataInteraction.order
        }
        this._mapService.showOrRemoveLayer(this.dataInteraction.workspaceName, order)
        this._mapService.setLayersSubject()
      }
    }
  }
}
