import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { APIService } from '@core/services/api.service'
import { LoaderService } from '@core/services/loader.service'
import { Logger } from '@core/services/logger.service'
import { ToasterService } from '@core/services/toaster.service'
import {
  apiUrl,
  postStatsLayersHectares,
  postStatsLayersNutsLau,
  postStatsPersonalLayer,
} from '@services/data.service'

@Injectable()
export class SummaryResultService extends APIService {
  constructor(
    http: HttpClient,
    logger: Logger,
    loaderService: LoaderService,
    toasterService: ToasterService,
  ) {
    super(http, logger, loaderService, toasterService)
  }

  getSummaryResultWithIds(payload: any): Promise<any> {
    this.logger.log('SummaryResultService/getSummaryResultWithIds', payload)
    return super.POST(payload, apiUrl + postStatsLayersNutsLau)
  }

  getSummaryResultWithMultiAreas(payload: any): Promise<any> {
    this.logger.log('SummaryResultService/getSummaryResultWithMultiAreas', payload)
    return super.POST(payload, apiUrl + postStatsLayersHectares)
  }
  getSummaryResultPersonnalLayers(payload) {
    this.logger.log('SummaryResultService/getSummaryResultPersonnalLayers', payload)
    return super.POST(payload, apiUrl + postStatsPersonalLayer)
  }
}
