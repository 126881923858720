<h3>Privacy terms</h3>

<div class="section">
  <h4 class="title">What data is being collected?</h4>
  <div class="text">
    <p>
      The data collected consists of email, first name and last name. You can view or modify this
      data any time by visiting your user profile. The personal layers uploaded on the platform, as
      well as the snapshots you save are also collected.
    </p>
  </div>
</div>

<div class="privacy-content">
  <div class="section">
    <h4 class="title">Who is collecting the data?</h4>
    <div class="text">
      <p>
        Data is stored by
        <a href="https://www.hevs.ch/">HES-SO Valais-Wallis</a> and is not given to third parties.
      </p>
    </div>
  </div>

  <div class="section">
    <h4 class="title">How long will the data be stored for?</h4>
    <div class="text">
      <p>
        We store this data as long as your account is not removed. You can remove your account and
        thereby all your personal data at any time in your user profile.
      </p>
      <p>
        If you do not activate your account within 2 days, all data associated with your
        registration will be deleted from our servers.
      </p>
    </div>
  </div>

  <div class="section">
    <h4 class="title">How can I delete my personal data?</h4>
    <div class="text">
      <p>
        Just head to your account details by pressing on your profile on the top right of your
        screen. Then, simply press the bin and validate that you wish your account to be deleted.
        All personal layers and snapshot will be also deleted.
      </p>
      <p>
        Upon receiving a request for data deletion, we will delete your personal data from our
        active databases. However, please note that your data may remain in our backup systems for a
        limited period due to our backup and disaster recovery processes. During this time, the data
        will not be accessible for regular use or processing, and it will be securely deleted from
        backups in accordance with our data retention policy. Your data will not be restored into
        active systems unless explicitly required by legal obligations or unless you request it.
      </p>
    </div>
  </div>

  <div class="section">
    <h4 class="title">How can I raise a complaint?</h4>
    <div class="text">
      <p>
        You can complain about privacy violations at
        <a href="https://www.dsb.gv.at" target="_blank">https://www.dsb.gv.at</a>
      </p>
    </div>
  </div>
</div>
