import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class CalculationModuleStatusService {
  private _waitingCM: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private _cmRunned: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  private _panelIsOpen: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  private _cmAnimationStatus: BehaviorSubject<any> = new BehaviorSubject<any>(0)
  private _cmRunning: BehaviorSubject<any> = new BehaviorSubject<any>(false)

  getRunningState() {
    return this._cmRunning
  }
  setRunningState(val) {
    this._cmRunning.next(val)
  }
  getWaitingStatus() {
    return this._waitingCM
  }
  _setWaitingStatus(value: boolean) {
    this._waitingCM.next(value)
  }
  setCmRunned(cm, components) {
    const calculationmodule = { cm: cm, component: components }
    this._cmRunned.next(calculationmodule)
  }
  getCmRunned() {
    return this._cmRunned
  }
  undefinedCmRunned() {
    this._cmRunned.next(null)
  }
  getStatusCMPanel() {
    return this._panelIsOpen
  }
  setStatusCMPanel(value) {
    this._panelIsOpen.next(value)
  }
  setCmAnimationStatus(value) {
    this._cmAnimationStatus.next(value)
  }
  getCmAnimationStatus() {
    return this._cmAnimationStatus
  }
}
