-
<div *ngIf="expanded" [@visibleTrigger]="'visible'" class="details">
  <div style="position: relative">
    <div class="custom-tooltip" *ngIf="dataInteraction.isLoading">
      {{ dataInteraction.name }} layer is loading
    </div>
    <button
      type="button"
      [class.wait]="interactionService.getSummaryResultState()"
      name="{{ dataInteraction.workspaceName }}"
      class="uk-button uk-button-default uk-width-1-1 button-layer"
      (click)="showRemoveLayer($event)"
      onclick="_paq.push(['trackEvent', 'Layer', 'Layer Button Click', this.getAttribute('name')]);"
      [class.uk-button-secondary]="dataInteraction.isSelected"
    >
      <input class="uk-checkbox" type="checkbox" [checked]="dataInteraction.isSelected" />
      {{ dataInteraction.name }}
      <div *ngIf="dataInteraction.isLoading" class="spinner" uk-spinner="ratio:0.5"></div>
    </button>
  </div>
  <htm-layer-tool
    *ngIf="dataInteraction.isSelected"
    [dataInteraction]="dataInteraction"
  ></htm-layer-tool>
</div>
