import { animate, state, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatTooltipModule } from '@angular/material/tooltip'
import { DataInteractionService } from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.service'
import { InteractionService } from '@core/services/interaction.service'
import { Logger } from '@core/services/logger.service'
import { ToasterService } from '@core/services/toaster.service'
import { MapService } from '@pages/map/services/map.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { inputs_categories, maxSurfaceValueCM } from '@services/data.service'
import { ImportCmInputsComponent } from '../import-cm-inputs/component/import-cm-inputs.component'
import { CalculationModuleStatusService } from '../service/calculation-module-status.service'
import { CalculationModuleClass } from '../service/calculation-module.class'
import { CalculationModuleService } from '../service/calculation-module.service'
import { StandAloneCmComponent } from '../stand-alone-cm/stand-alone-cm.component'

@Component({
  standalone: true,
  selector: 'htm-cms',
  templateUrl: 'calculation-module.component.html',
  styleUrls: ['calculation-module.component.css'],
  animations: [
    // Formulare trigger
    trigger('cmTrigger', [
      state('expanded', style({ opacity: 1 })),
      state('collapsed', style({ opacity: 0 })),
      transition('collapsed => expanded', animate('200ms 150ms linear')),
      transition('expanded => collapsed', animate('100ms linear')),
    ]),
  ],
  imports: [
    CommonModule,

    //Forms
    FormsModule,
    ReactiveFormsModule,

    //Component
    StandAloneCmComponent,
    ImportCmInputsComponent,

    //Tooltips
    MatTooltipModule,
  ],
})
export class CalculationModuleComponent implements OnInit, OnDestroy, OnChanges, OnDestroy {
  @Input() layersSelected
  @Input() expanded
  @Input() expandedState
  @Input() selectionSurface
  public inputs_categories = inputs_categories
  public maxSurfaceValueCM = maxSurfaceValueCM
  @Input() scaleLevel
  public type_select = 'select'
  public type_input = 'input'
  public type_radio = 'radio'
  public type_range = 'range'
  public type_checkbox = 'checkbox'
  public progress = 0
  public calculationModules
  public displayedCalculationModules // Filtered Calculation modules (using search)
  public categories
  private _components
  public waitingCM = false
  public open = false
  public cmSelected
  public cmRunning
  public layersFromType = []
  private nowDate = new Date()
  private nowStr = this.nowDate.toLocaleString()
  public prefix_cm = ''
  public searchCM = '' // Track search input

  constructor(
    private _calculationModuleService: CalculationModuleService,
    private _calculationModuleStatusService: CalculationModuleStatusService,
    private _interactionService: InteractionService,
    private _dataInteractionService: DataInteractionService,

    private _logger: Logger,
    private _toasterService: ToasterService,
    private _mapService: MapService,
  ) {}

  ngOnInit() {
    this.subscribeEvents()
    this.updateCMs()
    this._logger.log('calculation-module.component/ngOnInit')
  }
  ngOnChanges(changes: SimpleChanges): void {
    this._logger.log('calculation-module.component/ngOnChanges')
    if (this.cmSelected && this.selectionSurface <= 0) {
      this.cmSelected = undefined
      this.stopCM()
    }
  }
  ngOnDestroy() {
    this._logger.log('calculation-module.component/ngOnDestroy')
    this.stopCM()
  }
  subscribeEvents() {
    const self = this
    this._calculationModuleStatusService.getWaitingStatus().subscribe((value) => {
      self.waitingCM = value
    })
    this._calculationModuleStatusService.getCmAnimationStatus().subscribe((data) => {
      this.progress = data
      if (this.progress !== 0) {
        this.cmRunning = true
        this._interactionService.setCmRunning(this.cmRunning)
      } else {
        /* if (!this.helper.isNullOrUndefinedString(this.cmSelected)) {
          this.calculationModuleStatusService.undefinedCmRunned();
        } */
        this.cmRunning = false
        this._interactionService.setCmRunning(this.cmRunning)
      }
      this._logger.log('CM progress', this.progress)
      this._logger.log('CM getCurrentIdCM:', this._interactionService.getCurrentIdCM())
    })
    this._calculationModuleStatusService.getStatusCMPanel().subscribe((value) => {
      if (value === true) {
        // uikit.offcanvas('#box-components').show()
        this._logger.log('cm box is shown')
      } else if (value === false) {
        try {
          // uikit.offcanvas('#box-components').hide();
          this._logger.log('cm box is hidden')
        } catch (error) {}
        this.cmHidePanel()
      }
    })
  }
  isCmsReadable() {
    if (!isNullOrUndefinedString(this.calculationModules)) {
      this.calculationModules.map((cm) => {
        cm['isReadable'] = true
      })
    }
  }
  resetCM() {
    this.cmSelected.status_id = ''
    this.cmSelected.isApiRequestInTreatment = false
    this._calculationModuleStatusService.undefinedCmRunned()
  }
  updateCMs() {
    this._interactionService.deleteCMTask()
    this._calculationModuleService
      .getcalculationModuleServicesPromise()
      .then((cms) => {
        this.calculationModules = cms
        this.displayedCalculationModules = this.calculationModules
        this.setWaiting(false)
      })
      .then(() => {
        this.isCmsReadable()
        this._calculationModuleService
          .getCalculationModuleCategories(this.calculationModules)
          .then((categories) => {
            this.categories = []
            this.categories = categories
          })
      })
  }

  changeValueFromInputArray(value, component) {
    component.input_value = value
  }
  changeValueFromInput(event, component) {
    const newValue = event.target.value
    if (newValue >= +component.input_min && newValue <= +component.input_max) {
      component.input_value = event.target.value
    } else {
      event.target.value = component.input_value
    }
  }
  runCM() {
    if (this.prefix_cm == '') {
      // default value is date time at local format
      this.nowDate = new Date()
      this.nowStr = this.nowDate.toLocaleString()
      this.prefix_cm = this.nowStr
    }
    this.cmSelected['cm_prefix'] = this.prefix_cm
    this.prefix_cm = ''
    this._components.forEach((comp) => {
      if (!isNullOrUndefinedString(comp.selected_value)) {
        comp.input_value = comp.selected_value
      }
    })

    // For export input int csv
    this._dataInteractionService.inputsCM.push({
      cm_name: this.cmSelected.cm_name,
      cm_prefix: this.cmSelected.cm_prefix,
      inputs: this._components,
      layersInputs: this.cmSelected.layers_needed.concat(this.cmSelected.vectorsNeeded),
      layersInputsDescription: this.cmSelected.type_layer_needed.concat(
        this.cmSelected.type_vectors_needed,
      ),
    })

    this._calculationModuleStatusService.setCmRunned(this.cmSelected, this._components)
    this.cmRunning = true
    this._interactionService.setCmRunning(this.cmRunning)
  }
  setWaiting(val) {
    this._calculationModuleStatusService._setWaitingStatus(val)
  }
  setComponentCategory() {
    this.inputs_categories.map((input) => {
      input.contains_component = false
      if (this._components.filter((x) => x.input_priority === input.id).length >= 1) {
        input.contains_component = true
      }
    })
  }
  getComponentFiltered(id) {
    return this._components.filter((x) => x.input_priority === id)
  }
  validateAuthorizedScale(cm) {
    if (!isNullOrUndefinedString(cm.authorized_scale) && cm.authorized_scale.length >= 1) {
      if (cm.authorized_scale.filter((x) => x === this._mapService.getScaleValue()).length >= 1) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }
  stopCM() {
    this._interactionService.deleteCMTask()
    this.cmRunning = false
  }

  selectCM(cm) {
    if (this.selectionSurface <= 0) {
      this._toasterService.showDangerToaster(
        'Surface area must be greater than 0 to initiate a calculation module. <br/>Choose a valid surface area value and try again.',
      )
      return
    }
    if (this.selectionSurface > this.maxSurfaceValueCM) {
      this._toasterService.showToasterSurfaceCalculDisabled()
    } else {
      if (this.validateAuthorizedScale(cm)) {
        this.toggleCMPanel(true)
        this.setWaiting(true)
        this.cmSelected = cm
        this.layersFromType = []
        if (!isNullOrUndefinedString(cm.type_layer_needed)) {
          cm.type_layer_needed.map((layer) => {
            this.setLayerFromType(layer, 'raster')
          })
        }
        if (!isNullOrUndefinedString(cm.type_vectors_needed)) {
          cm.type_vectors_needed.map((layer) => {
            this.setLayerFromType(layer, 'vector')
          })
        }

        this._calculationModuleService
          .getCalculationModuleComponents(cm.cm_id)
          .then((values) => {
            this._components = values
            this._components.forEach((comp) => {
              comp['input_default_value'] = comp.input_value
              if (typeof comp.input_value == 'object') {
                comp.input_value = comp.input_value[0]
              }
            })
          })
          .then(() => {
            this.setComponentCategory()
          })
          .then(() => {
            this.setWaiting(false)
          })
      } else {
        const scale_authorized = cm.authorized_scale.toString().replace(/,/g, ', ')
        this._toasterService.showDangerToaster(
          'Invalid scale level selected. <br/> Only <strong>' +
            scale_authorized +
            '</strong> can be choosen',
        )
      }
    }
  }
  setLayerFromType(layer, data_type) {
    this._dataInteractionService
      .getLayersFromType(layer.type)
      .then((data) => {
        if (data.length >= 1) {
          this.layersFromType.push({
            layerType: layer.type,
            layers: data,
            layerSelected: data[0],
            type_description: layer.description,
            input_name: layer.name,
            data_type: data_type,
          })
        } else {
          this._dataInteractionService
            .getHiddenLayersFromType(layer.type)
            .then((data) => {
              this.layersFromType.push({
                layerType: layer.type,
                layers: data,
                layerSelected: data[0],
                type_description: layer.description,
                data_type: data_type,
              })
            })
            .then(() => {
              this.setLayerNeeded()
            })
        }
      })
      .then(() => {
        this.setLayerNeeded()
      })
  }
  cmHidePanel() {
    this.setWaiting(true)
    this._calculationModuleStatusService.undefinedCmRunned()

    this.cmRunning = false
    this._interactionService.setCmRunning(this.cmRunning)
    this.cmSelected = undefined
    this._components = undefined
    this._logger.log('cm box is hidden')
    this.setWaiting(false)
  }

  toggleCMPanel(value: boolean) {
    if (!value) {
      this.searchCM = ''
      this.displayedCalculationModules = this.calculationModules
    }
    this.open = value
    this._calculationModuleStatusService.setStatusCMPanel(value)
  }

  getLayersFromType(layer) {
    this._dataInteractionService.getLayersFromType(layer)
  }

  assignExtractedData(extractedData) {
    this._logger.log('assignExtractedData', extractedData)
  }

  setLayerNeeded() {
    this.cmSelected.layers_needed = []
    this.layersFromType.map((layer) => {
      this.cmSelected.layers_needed.push({
        id: layer.layerSelected.id,
        name: layer.layerSelected.name,
        workspaceName: layer.layerSelected.workspaceName,
        download_url: layer.layerSelected.download_url,
        layer_type: layer.layerSelected.layer_type,
        data_type: layer.data_type,
      })
    })
  }

  handleImportedData(data: any) {
    this.updateFields(data)
  }

  updateFields(data: any): void {
    if (!data || !this.cmSelected) return

    // Update components
    let inputsErrorCounter = 0
    let inputCounter = 0
    this._components.some((component) => {
      inputCounter++
      const indicator = component.input_name

      // Find the matching item
      const item = data.find((item) => item?.indicator === indicator)

      if (!isNullOrUndefinedString(item) && item.hasOwnProperty('value')) {
        const value = item.value
        component.input_value = value
      } else {
        inputsErrorCounter++
        this._logger.log('Input not found in imported data', indicator, 'error')
      }
    })

    // If not 1 input found a match, stops the import
    if (inputsErrorCounter != 0 && inputsErrorCounter == inputCounter) {
      this.showError(
        'Unable to import the inputs, make sure to select the csv file with the parameters of the active CM.',
      )
      return
    }

    // Update layers
    let layersErrorCounter = 0
    let layerCounter = 0
    this.layersFromType.some((layersTable) => {
      layerCounter++
      let foundMatch = false

      layersTable.layers.some((layer) => {
        if (!foundMatch) {
          const importedLayer = data.find((item) => item?.value === layer.name)

          if (importedLayer) {
            layersTable.layerSelected = layer
            foundMatch = true
          }
        }
      })

      if (!foundMatch) {
        layersErrorCounter++
        this._logger.log(`Layer not found in imported data`, this.layersFromType, 'error')
      }
    })

    // If not 1 input found a match, stops the import
    if (layersErrorCounter != 0 && layersErrorCounter == layerCounter) {
      this.showError(
        'Unable to import the inputs, make sure to select the csv file with the parameters of the active CM.',
      )
      return
    } else if (layersErrorCounter > 0 || inputsErrorCounter > 0) {
      this.showError('Input imported ! But least, 1 input cannot be imported')
      return
    }

    this._interactionService.showToaster('Input imported !')
  }

  showError(message: string) {
    this._interactionService.showDangerToaster(message)
  }

  searchCMs(query: string) {
    this.searchCM = query
    if (query.trim() === '') {
      this.displayedCalculationModules = this.calculationModules
    } else {
      this.displayedCalculationModules = this.calculationModules.filter(
        (cm: CalculationModuleClass) => cm.cm_name.toLowerCase().includes(query.toLowerCase()),
      )
    }
  }
}
