import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DataInteractionClass } from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.class'
import { DataInteractionService } from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.service'
import { SelectionToolService } from '@components/_panel-left/toolstab/selection-tools/service/selection-tool.service'
import { AuthService } from '@core/services/auth.service'
import { MapService } from '@pages/map/services/map.service'
import {
  apiUrl,
  constant_year,
  hectare,
  lau2,
  nuts0,
  nuts1,
  nuts2,
  nuts3,
} from '@services/data.service'
import { DataInteractionArray } from 'app/layers'
import { environment } from 'environments/environment'
import * as Leaflet from 'leaflet'
import { TileLayer } from 'leaflet'
import { BehaviorSubject } from 'rxjs'
import { APIService } from './api.service'
import { Helper } from './helper'
import { LoaderService } from './loader.service'
import { Logger } from './logger.service'
import { ToasterService } from './toaster.service'

declare const L: any

interface BlobUrl {
  url: string
  filename: string
}

export const uploadUrl: string = apiUrl + '/upload/'

export interface UploadedFile {
  id: number
  shared: string
  name: string
  layer: string
  size: number
}

@Injectable()
export class UploadService extends APIService {
  private _userToken: string

  // For Show and Remove
  private _activeLayers: Object = {}
  private _uploadedFiles: BehaviorSubject<UploadedFile[]> = new BehaviorSubject<UploadedFile[]>([])
  private _shareFiles: BehaviorSubject<UploadedFile[]> = new BehaviorSubject<UploadedFile[]>([])
  private _activePersonalLayers: BehaviorSubject<Object> = new BehaviorSubject<Object>({})

  /**
   * To refresh the list automatically
   * @author HESSO Daniel Hunacek
   */
  getUploadedFiles(): BehaviorSubject<UploadedFile[]> {
    return this._uploadedFiles
  }
  getShareFiles(): BehaviorSubject<UploadedFile[]> {
    return this._shareFiles
  }
  getActivePersonalLayers(): BehaviorSubject<Object> {
    return this._activePersonalLayers
  }

  filterPersonalOrShareLayerWithTypeInProject(layerToFilter) {
    return DataInteractionArray.find(
      (layer) => layerToFilter.layer === layer.workspaceName,
    ).projects.includes(environment.appName)
  }

  constructor(
    private authService: AuthService,
    private _slcToolsService: SelectionToolService,
    private _helper: Helper,
    private _mapService: MapService,
    private _dataInsteractionService: DataInteractionService,
    protected http: HttpClient,
    protected logger: Logger,
    protected loaderService: LoaderService,
    protected toasterService: ToasterService,
  ) {
    super(http, logger, loaderService, toasterService)
    this._userToken = authService.getUserId()
  }

  /**
   * Use toaster to show message of success and error
   * @param res Response of the api
   * @param success true from then, false from catch
   */
  private showMsg(res: any, success: boolean) {
    this.list()
    this.listShare()
    if (success) this.toasterService.showToaster(res['message'])
    if (!success) this.toasterService.showDefaultErrorToaster(res['status'])
    return success
  }

  /**
   * Add a file to the uploaded files
   * @param file file to add
   * @param layer layer of the file
   * @returns Promise with success of the procedure
   */
  add(file: File, shared: string, layer?): Promise<boolean> {
    let form = new FormData()
    form.append('name', file.name)
    form.append('file', file, file.name)
    form.append('shared', shared)
    form.append('layer', layer.workspaceName)
    form.append('layer_type', layer.layer_type)
    return super
      .POSTunStringify(form, uploadUrl + 'add', { headers: new HttpHeaders() })
      .then((response) => this.showMsg(response, true))
      .catch((response) => this.showMsg(response, false))
  }

  /**
   * Delete an uploaded file
   * @param id id of the file to delete
   * @returns Promise with success of the procedure
   */
  delete(id: number | UploadedFile): Promise<boolean> {
    this.remove(id) // remove first
    this._dataInsteractionService.removeLayer(id)
    if (typeof id !== 'number') id = (id as UploadedFile).id

    return super
      .DELETE(uploadUrl + 'delete', {
        body: { id: id },
      })
      .toPromise()
      .then((response) => this.showMsg(response, true))
      .catch((response) => this.showMsg(response, false))
  }

  /**
   * Create an url to download a uploaded file
   * @param id
   * @param filename name of the file to download
   * @returns Promise with the url to download
   */
  download(id: number | UploadedFile): Promise<string> {
    if (typeof id !== 'number') id = (id as UploadedFile).id

    return super
      .POSTunStringify(
        {
          id: id,
        },
        uploadUrl + 'download',
        { responseType: 'blob', headers: new HttpHeaders() },
      )
      .then((data) => URL.createObjectURL(data) as string)
      .catch((err) => {
        return '' // If file dont exist
      })
  }

  /**
   * Get the list of the uploaded files
   * @returns Promise with the files
   */
  list(): Promise<UploadedFile[]> {
    return super.POSTunStringify({}, uploadUrl + 'list').then((response) => {
      this.addLayersToDatainteraction(response['uploads'])
      this._uploadedFiles.next(response['uploads'])
      return this.getUploadedFiles().getValue()
    })
  }

  /**
   * Get the list of the uploaded files
   * @returns Promise with the files
   */
  listShare(): Promise<UploadedFile[]> {
    return super.POSTunStringify({}, uploadUrl + 'listshare').then((response) => {
      this.addLayersToDatainteraction(response['uploads'])
      this._shareFiles.next(response['uploads'])
      return this.getShareFiles().getValue()
    })
  }

  addLayersToDatainteraction(uploads) {
    uploads.map((upload) => {
      if (!this._dataInsteractionService.layerExists(upload)) {
        this._dataInsteractionService.addNewLayer(upload.name, upload.id, upload.layer_type)
      }
    })
  }

  /**
   * Show the layer on the map
   * @param id
   */
  async show(id: number | UploadedFile) {
    const upFile: UploadedFile =
      typeof id === 'number'
        ? this.getUploadedFiles()
            .getValue()
            .filter((upload) => upload.id == id)[0]
        : (id as UploadedFile)

    if (upFile.id in this._activeLayers) {
      this.toasterService.showToaster('Layer already active')
      return
    }

    const payload = {
      id: upFile.id,
      user_token: this._userToken,
      layer_id: upFile.layer,
      layer_name: upFile.name,
    }
    this._activePersonalLayers.value[upFile.id as number] = payload
    this._activePersonalLayers.next(this._activePersonalLayers.value)
    if (upFile.name.endsWith('.tif')) {
      // Here, we manually set the token to the request as it is a Get and does not allow custom header variables
      const token = await this.authService.getKeycloakInstance().token // Get the Keycloak token

      //Retrieve max zoom level
      this.http.post<any>(`${apiUrl}/upload/maxzoom/${upFile.id}`, {}).subscribe({
        next: (data) => {
          const maxNativeZoom = data.maxZoom || 11 // Fallback to 11 if no maxZoom is provided
          this._activeLayers[upFile.id] = Leaflet.tileLayer(
            `${uploadUrl}tiles/{id}/{z}/{x}/{y}?token=${token}`, // Attach token as URL parameter
            {
              id: upFile.id.toString(),
              tms: true,
              maxNativeZoom: maxNativeZoom,
              zIndex: 5, // Prevents layer from being hidden by map
            },
          ).addTo(this._mapService.getMap())
        },
        error: (error) => {
          // Handle errors
          console.error('Error retrieving max zoom level:', error)
        },
        complete: () => {
          console.log('Request completed')
        },
      })
    } else if (upFile.name.endsWith('.csv')) {
      this.http.get(uploadUrl + 'csv/' + upFile.id).subscribe((geoData) => {
        for (const feature of (geoData as any).features)
          if (feature.geometry.type === 'MultiPolygon') feature.style.color = feature.style.fill
        // feature.style.fillOpacity = feature.style.size;

        this._activeLayers[upFile.id] = new L.geoJson(geoData, {
          style: (feature) => feature.style, // Seems it need to force style for polygons
          pointToLayer: (feature: any, latlng: Leaflet.LatLng) => {
            if (feature.geometry.type == 'Point' && feature.style.name) {
              // filter out elements without any style.name

              if (feature.style.name == 'circle') {
                // circle marker
                let circleMaker = new L.CircleMarker(latlng, {
                  fillColor: feature.style.fill,
                  color: feature.style.stroke,
                  fillOpacity: 1,
                  weight: 1,
                  // https://github.com/Leaflet/Leaflet/issues/2824
                  radius: +feature.style.size / 2,
                })

                //@todo return company name with the same style as the one precompiled in the geoserver
                if (feature.style.stroke == '#000000' && feature.properties.companyname) {
                  // to differentiate between emission sites and company name
                  circleMaker.bindTooltip(feature.properties.companyname, {
                    permanent: true,
                    direction: 'top',
                  })
                }

                return circleMaker

                //@todo return piechart with value
              } else if (feature.style.name == 'chart') {
                let svgHtmlPieChart: string = this._constructPieChartSVG(feature.style)

                const svgIcon = Leaflet.divIcon({
                  html: svgHtmlPieChart,
                  className: 'svg-icon',
                  iconSize: [feature.style.size, feature.style.size],
                })
                return Leaflet.marker(latlng, {
                  icon: svgIcon,
                })
              } else {
                // define shape from style name
                let svgHtmlTriangle: string = this._constructTriangleSVG(feature.style)

                let svgIcon: Leaflet.DivIcon = Leaflet.divIcon({
                  // html: svgHtmlType,
                  className: 'svg-icon',
                })

                switch (feature.style.name) {
                  case 'triangle':
                    svgIcon = Leaflet.divIcon({
                      html: svgHtmlTriangle,
                      className: 'svg-icon',
                      iconSize: [feature.style.size, feature.style.size],
                    })

                  case 'square':
                    // @todo create square svg (take triangle and svgIcon as example)
                    break
                  case 'pentagon':
                    // @todo create pentagon svg (take triangle and svgIcon as example)
                    break
                  case 'hexagon':
                    // @todo create hexagon svg (take triangle and svgIcon as example)
                    break
                  case 'octogon':
                    // @todo create octogon svg (take triangle and svgIcon as example)
                    break
                }

                return Leaflet.marker(latlng, {
                  icon: svgIcon,
                })
              }
            }
          },
        }).addTo(this._mapService.getMap())
      })
    }
  }

  private _constructPieChartSVG(style: {
    data: { [key: string]: number }
    size: number
    chartOptions: { [key: string]: { fillColor: string } }
  }): string {
    const data = style.data
    const chartOptions = style.chartOptions as {
      [key: string]: { fillColor: string }
    }
    const colors = Object.values(chartOptions).map((option) => option.fillColor)
    const size = style.size

    // calculating total sum of all values
    const total = Object.values(data).reduce((sum, value) => sum + value, 0)

    // calculating percentages for each category
    const percentages = Object.keys(data).map((key) => Math.round((data[key] / total) * 100))

    // generating base SVG element
    let svg = `<svg height="${size * 3}" width="${size * 3}" viewBox="0 0 ${size * 2.5} ${
      size * 2.5
    }">`

    // add offset grey circle, simulation of shadow
    svg += `<circle r="${size / 2}" cx="${size * 1.45}" cy="${size * 1.45}" fill="grey"
                stroke="grey"
                stroke-width="${size}"
    />`

    // add white background circle, simulation of border
    svg += `<circle r="${size / 2}" cx="${size * 1.25}" cy="${size * 1.25}" fill="white"
                stroke="white"
                stroke-width="${size + 8}"
    />`

    let startAngle = 0
    let endAngle = 0

    // constructing pie slices
    Object.keys(data).forEach((key, index) => {
      // Calculate the end angle of the current slice:
      // - adding the percentage of the current category to the previous end angle
      // - scale it to the range of 0 to 360 degrees
      endAngle += (percentages[index] / 100) * 360

      // Constructing a circle for each slice
      // - the *1.25 in cx & cy allows for the pie chart to be centered to its view box
      // each circle is a slice of pie that is additioned to the previous one
      // recuperates the end angle of the previous slice
      svg += `<circle
                r="${size / 2}"
                cx="${size * 1.25}"
                cy="${size * 1.25}"
                fill="transparent"
                stroke="${colors[index]}"
                stroke-width="${size}"
                stroke-dasharray="${(percentages[index] / 100) * Math.PI * size},${Math.PI * size}"
                stroke-dashoffset="${(-startAngle / 360) * Math.PI * size}"
            />`

      // Update the starting angle for the next slice
      startAngle = endAngle
    })

    // add the end "balise" of the svg to close it once it iterated on each slice
    svg += '</svg>'

    return svg
  }

  private _constructTriangleSVG(style: any): string {
    const size = style.size
    const fill = style.fill
    const stroke = style.stroke

    const halfWidth = size / 2
    const height = (Math.sqrt(3) / 2) * size
    const width = size + halfWidth

    return `<svg height="${height * 2}" width="${size * 2}" xmlns="http://www.w3.org/2000/svg">
        <polygon points="0,${height} ${size},${height} ${halfWidth},0" style="fill:${fill};stroke:${stroke}" />
      </svg>`
  }

  clearLayerSelection() {
    for (let activeLayer in this._activeLayers) {
      this.remove(activeLayer as unknown as number)
    }
  }

  /**
   * Remove the layer from the map
   * @param id
   */
  remove(id: number | UploadedFile): void {
    if (typeof id !== 'number') id = (id as UploadedFile).id
    if (!((id as number) in this._activeLayers)) return // if the layer wasn't active
    ;(this._activeLayers[id as number] as TileLayer).removeFrom(this._mapService.getMap())
    delete this._activeLayers[id as number]
    delete this._activePersonalLayers.value[id as number]
    this._activePersonalLayers.next(this._activePersonalLayers.value)
  }

  /**
   * Remove all active layers
   */
  removeAll(): void {
    for (let up in this._uploadedFiles.value) {
      this._dataInsteractionService.removeLayer(this._uploadedFiles.value[up].id)
    }

    this._activePersonalLayers.next({})
    this._uploadedFiles.next([])
  }

  /**
   * Export a file from selected area of the map
   * @param layer chosen layer
   * @param schema the schema to export (for later)
   * @param year the year to export
   * @returns Promise with the url to download and a filename
   */
  export(
    dataInteraction: DataInteractionClass,
    // layer: string,
    // uuid?: string,
    // schema?: string,
    // layerName?: string,
    // year?: number
  ): Promise<BlobUrl> {
    const scale = this._slcToolsService.getScaleValue()
    let nutsOrAreas: Array<string | any>
    let isNuts: boolean = true
    let layerName = dataInteraction.workspaceName // override layer with layerName if a value is set in layers-interaction.data.ts
    let uuid = dataInteraction.cm_id
    let year = dataInteraction.year
    let schema = dataInteraction.schema
    let dataType = dataInteraction.dataType

    if ([lau2, nuts3, nuts2, nuts1, nuts0].indexOf(scale) > -1) {
      layerName += '_' + scale.toLowerCase().replace(' ', '') // To change in API ?
      nutsOrAreas = this._slcToolsService.nutsIdsSubject.getValue()
    } else if (scale === hectare) {
      layerName += '_ha'
      nutsOrAreas = this._helper.getAreasForPayload(this._slcToolsService.areasSubject.getValue())
      isNuts = false
    } else this.logger.log('Unsupported scale', scale, 'error')
    if (uuid == null) {
      // if the layer is not a CM layer
      if (year == null) year = constant_year
      if (schema == null) schema = 'raster'

      return super
        .POSTunStringify(
          {
            layers: layerName,
            [isNuts ? 'nuts' : 'areas']: nutsOrAreas,
            schema: schema,
            year: year.toString(),
          },
          uploadUrl + `export/${dataType}/${isNuts ? 'nuts' : 'hectare'}`,
          {
            responseType: 'blob',
          },
        )
        .then((data) => {
          return {
            url: URL.createObjectURL(data) as string,
            filename: layerName + `.${dataType != 'csv' ? 'tif' : 'csv'}`,
          } as BlobUrl
        })
        .catch((err) => {
          let errMsg = ''
          if (
            ['UNKNOWN ERROR', 'INTERNAL SERVER ERROR', 'UNKNOWN'].indexOf(
              err.toString().toUpperCase(),
            ) > -1
          ) {
            errMsg = 'An internal error occured.'
          } else if (
            ['Failed retrieving year in database'.toUpperCase()].indexOf(
              err.toString().toUpperCase(),
            ) > -1
          ) {
            errMsg = err.statusText
          } else {
            errMsg = 'This layer cannot be exported.'
          }
          this.toasterService.showToaster(errMsg)
          // this.toasterService.showToaster("Sorry, We can't export this layer");
          return { url: '', filename: '' } as BlobUrl
        })
    } else {
      // if the layer is a cm layer
      let type: string = 'raster'
      if (layerName.includes('shapefile')) type = 'vector'
      return super
        .POSTunStringify(
          {
            uuid: uuid,
            type: type,
          },
          uploadUrl + 'export/cmLayer',
          { responseType: 'blob' },
        )
        .then((data) => {
          return {
            url: URL.createObjectURL(data) as string,
            filename: layerName + `${type == 'raster' ? '.tif' : '.zip'}`,
          } as BlobUrl //TODO: correct
        })
        .catch(() => {
          this.toasterService.showToaster("Sorry, We can't export this layer")
          return { url: '', filename: '' } as BlobUrl
        })
    }
  }
}
