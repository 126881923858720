import { Injectable } from '@angular/core'
import { DataInteractionService } from '@components/_panel-left/layerstab/layers-interaction/layers-interaction.service'
import { Helper } from '@core/services/helper'
import { Logger } from '@core/services/logger.service'
import { default_drop_down_button } from '@services/data.service'
import { AngularCsv as Angular2Csv } from 'angular-csv-ext/dist/Angular-csv'

@Injectable()
export class ExportDataService {
  constructor(
    private _helper: Helper,
    private _logger: Logger,
    public dataInteractionService: DataInteractionService,
  ) {}
  exportData(data, tabSelectedName: string, cmSessionName, refSelected) {
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
    }
    this._logger.log('ExportDataService/exportData', data)
    // append cm session name to file name of exported CSV if CM is in the indicator results
    // otherwise keep random name
    let file_name
    // check if no CM in results
    // second condition checks if the dropdown (refSelected), in results summary, is NOT overall or calculation module
    if (
      cmSessionName == undefined ||
      ![default_drop_down_button, 'calculation module'].includes(refSelected.toLowerCase())
    ) {
      // if no CM
      file_name = 'report_' + tabSelectedName + '_' + this._helper.generateRandomName()
    } else {
      // CM in results
      file_name = 'report_' + tabSelectedName + '_' + cmSessionName
    }
    // TODO take into account the dropdown!

    // David Braz Jorge (22.02.2024) : This code adds an undefined column in the end of the CSV export.
    // In every case I could test, it didn't remove any relevant information in the final export.
    /*
    data.map((d) => {
      try {
        d["indicator"] = this._dataInteractionService.getReadableName(
          d["indicator"]
        );
      } catch (e) {
        d = d;
      }
    });
    */
    new Angular2Csv(data, file_name, options)
  }
}
