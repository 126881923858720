import { CommonModule } from '@angular/common'
import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core'
import { Logger } from '@core/services/logger.service'
import { ImportCmInputsService } from '../service/import-cm-inputs.service'

@Component({
  selector: 'htm-import-cm-inputs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './import-cm-inputs.component.html',
  styleUrls: ['./import-cm-inputs.component.css'],
})
export class ImportCmInputsComponent implements OnDestroy {
  private _displayButton = false
  importedData: Array<any> = []
  csvFile: File | undefined

  extractedIndicator: string = ''
  extractedValue: string = ''
  extractedUnit: string = ''
  extractedEntryData: any[] = []

  @Output() dataImported = new EventEmitter<any>()
  @ViewChild('csvFileInput') csvFileInput: ElementRef

  constructor(
    private _importCmInputsService: ImportCmInputsService,
    private _logger: Logger,
  ) {}

  ngOnDestroy() {
    this._logger.log('ImportCmInputsComponent/ngOnDestroy')
  }

  handleFileInput(event: any) {
    const files = event.target.files
    if (files.length > 0) {
      this.csvFile = files[0]
    }
  }

  importInputs() {
    if (!this.csvFile) {
      this._logger.log('No CSV file selected.')
      return
    }

    const csvUrl = URL.createObjectURL(this.csvFile)
    this._importCmInputsService
      .importData(csvUrl)
      .then((dataArray) => {
        this.importedData = dataArray
        this.dataImported.emit(this.importedData)
        this.csvFileInput.nativeElement.value = null
      })
      .catch((error) => {
        this._logger.log('ImportDataComponent/importIndicators/error', error, 'error')
      })
  }
}
