import { environmentOM4ABase } from '@env/environment.om4a'

export const environmentOM4ADev = {
  ...environmentOM4ABase,
  apiUrl: 'https://api.openmod4africadev.hevs.ch/api',
  geoserverUrl: 'https://geoserver.openmod4africadev.hevs.ch/geoserver/wms',
  keycloakUrl: 'https://auth.openmod4africadev.hevs.ch',
}

export const environment = environmentOM4ADev
